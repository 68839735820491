import React, { useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import Axios from 'axios'
import useSWR from 'swr'
import { between } from '../../lib/helperFunctions'
import { throttle } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBars, faPercent, faPrint, faReceipt, faBan, faExternalLinkAlt, faHamburger, faInfoCircle, faEdit } from '@fortawesome/free-solid-svg-icons'

//BOOTSTRAP COMPONENTS
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
//END BOOTSTRAP COMPONENTS

import Loader from '../../components/Loader'
import CobrarButton from '../../components/CobrarModal'
import ItemList from '../../components/ItemList'
import MemoButton from '../../components/MemoModal'
import Carta from '../../components/Carta'
import CustomToggle from '../../components/CustomToggle'
import AnularButton from '../../components/AnularButton'

import TransferModal from './TransferModal'

import { usePrinter } from '../../Hook/PrinterContext'
import { useDialog } from '../../Hook/DialogContext'
import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    let history = useHistory()

    //CONTEXT
    const { id } = useParams()
    const { print } = usePrinter()
    const { getDialogResponse } = useDialog()
    const { hasPermission, config, localDef, authorize } = useConfig()
    //END CONTEXT

    //STATE
    const [items, setItems] = useState([])
    const [showCarta, setShowCarta] = useState(false)
    //END STATE

    const { data, mutate } = useSWR(`/api/orden/salon/get?local=${sessionStorage.local_id}&id=${id}`, {
        onErrorRetry: (err) => getDialogResponse({
            title: 'Error!',
            message: err.message,
            buttons: [
                {
                    label: 'Aceptar'
                }
            ]
        })
    })

    //LOCAL MUTATIONS
    const addItem = item => setItems([...items, {
        ...item,
        orderId: data?.orden
    }])

    const removeItem = index => {
        let array = [...items]
        array.splice(index, 1)
        setItems(array)
    }

    const editItem = (item, index) => {
        let newItems = [...items]
        newItems[index] = item
        setItems([...newItems])
    }
    //END LOCAL MUTATIONS

    const pedirButton = throttle(async () => {
        const data = await mutate()

        const updated = updateOrden({
            items: data.items ? [...data.items, ...items] : [...items]
        })
        setItems([])

        await updated

        if (hasPermission('salon.canPreventComanda')) {
            const response = await getDialogResponse({
                title: 'Comandar',
                message: 'Deseas comandar los items agregados a la mesa?',
                buttons: [
                    { variant: 'secondary', label: 'Cancelar' },
                    { variant: 'primary', label: 'Aceptar' }
                ]
            })

            if (!response) return
        }

        print('comandas', {
            items,
            id: data.id,
            memo: data.memo,
            origen: 'salon',
            orden: data.orden,
            metaData: data.metaData
        })
    }, 5000, { trailing: false })

    const deleteItem = async itemIndex => {
        const auth = await authorize('eliminar-item-mesa')
        if (auth.error) return

        let array = [...data?.items]
        const removed = array.splice(itemIndex, 1)

        const newMetaData = {
            ...data.metaData,
            deletedItems: data.metaData?.deletedItems ? [...data.metaData?.deletedItems, removed[0]] : [removed[0]]
        }

        updateOrden({ items: array, metaData: newMetaData })
    }

    const updateOrden = async (obj, noMutate) => {
        !noMutate && mutate({
            ...data,
            ...obj
        }, false)

        await Axios.post(`/api/orden/salon/updateOrden?local=${sessionStorage.local_id}&id=${data.orden}`, obj)

        !noMutate && mutate()

        return true
    }

    const DropdownComponent = () => (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} size='lg' variant='success'>
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <AnularButton actionToAuth='anulacion-mesa' onAnular={async motivo => {
                    await updateOrden(
                        {
                            status: 'anulado',
                            status_nota: motivo
                        }
                    )
                    close()
                    history.push('/salon')
                }} as={Dropdown.Item}>
                    <FontAwesomeIcon icon={faBan} className='mr-1' />Anular
                </AnularButton>

                <Dropdown.Item hidden={!hasPermission('salon.reimprimirComanda')} onClick={() => print('comandas', {
                    items: data?.items,
                    id: data?.id,
                    memo: data?.memo,
                    origen: 'salon',
                    orden: data.orden,
                    metaData: data.metaData,
                })}>
                    <FontAwesomeIcon icon={faPrint} className='mr-1' />Imprimir Comanda
                </Dropdown.Item>

                <Dropdown.Item onClick={() => print('precuenta', {
                    items: data.items,
                    id: data.id,
                    descuento: data.descuento,
                    orden: data.orden,
                    metaData: data.metaData,
                })}>
                    <FontAwesomeIcon icon={faReceipt} className='mr-1' />Imprimir Precuenta
                </Dropdown.Item>

                <Dropdown.Item hidden={!hasPermission('salon.descuento')} onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Modificar Descuento',
                        message: 'Ingresa el % de descuento ( 1-100 )',
                        input: true,
                        buttons: [
                            {
                                label: 'Cancelar',
                                variant: 'secondary'
                            },
                            {
                                label: 'Quitar Descuento',
                                variant: 'secondary'
                            },
                            {
                                label: 'Confirmar',
                                resolveInput: true,
                                disableNoInput: true
                            }
                        ]
                    })

                    if (!response) return

                    if (response === 1) {
                        updateOrden(
                            {
                                descuento: 0
                            }
                        )
                        return
                    }

                    if (!between(parseInt(response), 1, 100)) {
                        return getDialogResponse({
                            title: 'Error!',
                            message: 'El monto ingresado no es valido.',
                            buttons: [
                                {
                                    label: 'Aceptar'
                                }
                            ]
                        })
                    }
                    updateOrden(
                        {
                            descuento: (response / 100).toFixed(2)
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faPercent} className='mr-1' />Aplicar Descuento
                </Dropdown.Item>

                <Dropdown.Item hidden={!hasPermission('salon.mover')} onClick={async () => {
                    const mesasResponse = await Axios.get(`/api/orden/salon?local=${sessionStorage.local_id}`)
                    const response = await getDialogResponse({
                        title: 'Mover Mesa',
                        message: 'Selecciona la mesa de destino.',
                        input: {
                            type: 'select',
                            options: [
                                {
                                    value: null,
                                    label: 'Seleccionar...',
                                    hidden: true
                                },
                                ...mesasResponse.data.filter(mesa => !mesa.status).map(mesa => (
                                    {
                                        value: mesa.id,
                                        label: mesa.id,
                                    }
                                ))
                            ]
                        },
                        textAreaCancelButton: true,
                        buttons: [{ variant: 'primary', label: 'Mover', disableNoInput: true, resolveInput: true }]
                    })
                    if (!response) return

                    const res = await Axios.get(`/api/orden/salon/move?local=${sessionStorage.local_id}&id=${id}&to=${response}`)

                    if (res.data.error) {
                        getDialogResponse({
                            title: 'Error',
                            message: res.data.error,
                            buttons: [{ variant: 'primary', label: 'Aceptar' }]
                        })
                    } else {
                        history.push(`/salon/${response}`)
                    }
                }}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} className='mr-1' />Mover Mesa
                </Dropdown.Item>

                <TransferModal hidden={!hasPermission('salon.transferir')} items={data?.items} id={id} updateOrden={updateOrden} />

                <Dropdown.Item onClick={async () => {
                    const res = await getDialogResponse({
                        title: 'Agregar Nota',
                        textArea: true,
                        textAreaCancelButton: true,
                        textAreaDefaultValue: data?.metaData?.nota || ''
                    })
                    if (!res) return
                    updateOrden(
                        {
                            metadata: data.metaData ? { ...data.metaData, nota: res } : { nota: res }
                        }
                    )
                }}><FontAwesomeIcon icon={faEdit} className='mr-1' />Agregar Nota</Dropdown.Item>

                <Dropdown.Item onClick={() => getDialogResponse({
                    message: <pre className='mb-0'>{JSON.stringify(data.metaData, undefined, 4)}</pre>,
                    textNotCentered: true,
                    dismissible: true
                })}>
                    <FontAwesomeIcon icon={faInfoCircle} className='mr-1' />Ver Metadatos
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

    const close = () => Axios.get(`/api/orden/salon/close?local=${sessionStorage.local_id}&id=${data.id}`)

    if (!data) return <Loader loading opacity={0} />

    return (
        <>
            {/* MOBILE TOP NAV */}
            <div className="mb-1 d-flex d-lg-none justify-content-between">
                <Link to="/salon"><Button variant='success' size='lg'><FontAwesomeIcon icon={faArrowLeft} /></Button></Link>

                <Button size='lg' variant={showCarta ? 'light' : 'outline-light'} onClick={() => setShowCarta(!showCarta)}><FontAwesomeIcon icon={faHamburger} /></Button>

                <DropdownComponent />
            </div>
            {/* END MOBILE TOPNAV */}

            <Row className="h-100 overflow-hidden">
                <Col className={`p-0 h-100 ${showCarta ? 'd-none' : 'd-flex'} d-lg-flex flex-column`}>

                    {/* ORDEN NAV */}
                    <div className="mb-1 d-none d-lg-flex justify-content-between">
                        <Link to="/salon"><Button variant='success' size='lg'><FontAwesomeIcon icon={faArrowLeft} /></Button></Link>

                        <DropdownComponent />
                    </div>

                    {/* ORDEN HEADER */}
                    <div className="d-flex mb-1">
                        <Card className="p-1 mr-1"><span className="my-auto mx-2 font-weight-bold">{data?.id}</span></Card>
                        <Card
                            className='flex-grow-1 d-flex justify-content-around text-center'
                        >
                            <div className='mbt-1'>
                                <span className='h5'><b>Orden: </b>{data?.orden}</span>
                                <span className='h5'><b>Apertura: </b>{new Date(data?.apertura).toLocaleString()}</span>
                            </div>
                            {data?.memo && <span className="text-danger font-weight-bold h5 m-0 mx-1">{data?.memo}</span>}
                        </Card>
                    </div>

                    <ItemList
                        items={items}
                        comandados={data.items}
                        descuento={data.descuento}
                        cartaId={localDef.carta}
                        onRemoveItem={removeItem}
                        onDeleteItem={deleteItem}
                        onEditItem={editItem}
                        disabled={data?.metaData?.tipoCobro === 'total'}
                    />

                    {/* BOTTOM BUTTONS */}
                    <div className="mt-1 mbt-1 d-flex justify-content-center flex-wrap">
                        <MemoButton memo={data?.memo} onUpdate={newMemo => updateOrden({ memo: newMemo })} />

                        <Button onClick={pedirButton} size='lg' variant='warning' hidden={!items.length}>Pedir</Button>

                        <CobrarButton
                            mesa={data.id}
                            orden={data.orden}
                            items={data.items}
                            descuento={data.descuento}
                            hidden={!data.items.length || !hasPermission('salon.cobrar')}
                            updateOrden={updateOrden}
                            metaData={data.metaData}
                            apiKey={localDef.apiKey}
                            apiUrl={localDef.apiUrl}
                        />

                        <Button variant='danger' size='lg' hidden={!data?.metaData?.tipoCobro} onClick={async () => {
                            const response = await getDialogResponse({
                                title: 'Confirmacion',
                                message: 'Estas seguro que quieres terminar la orden? Esta accion es irreversible',
                                buttons: [
                                    { variant: 'secondary', label: 'Cancelar' },
                                    { variant: 'primary', label: 'Aceptar' }
                                ]
                            })
                            if (response) {
                                updateOrden({
                                    status: 'terminado',
                                    cierre: '[timestamp]'
                                }, true).then(() => close()).then(() => history.push('/salon'))
                            }
                        }}>Terminar</Button>
                    </div>
                </Col>

                <Col className={`p-0 h-100 ${showCarta ? 'd-flex' : 'd-none'} d-lg-flex ml-lg-1`} xs={12} lg={6} xl={8}>
                    <Carta onAdd={addItem} disabled={data?.metaData?.tipoCobro === 'total'} cartaId={localDef.carta} unico={true} />
                </Col>
            </Row>
        </>
    )
}
