import React, { useState, useEffect } from 'react'
import { map, uniq, compact } from 'lodash'
import firebase from 'firebase'

import Card from 'react-bootstrap/Card'

import { Switch, Route, Redirect, useRouteMatch, useParams, Link } from 'react-router-dom'

import Row from 'react-bootstrap/Row'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'

import Carta from './Carta'
import Grupos from './Grupos'
import { useCollection, useDocument } from "@nandorojo/swr-firestore"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default () => {
    return (
        <Switch>
            <Route exact path='/carta' component={Menus} />
            <Route path='/carta/:cartaId' component={Template} />
            <Redirect to='/' />
        </Switch>
    )
}

const Menus = () => {
    const [data, setData] = useState([])

    const locals = JSON.parse(sessionStorage.locales)
    const ids = compact(uniq(map(locals, 'carta')))

    useEffect(() => {
        (async function () {
            const snapshot = await firebase.firestore().collection('cartas').where(firebase.firestore.FieldPath.documentId(), 'in', ids).get()
            let array = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }))
            const resolved = await Promise.all(array)
            setData(resolved)
            console.log(resolved)
        })()
    }, [])

    if (!data.length) {
        return (
            <Card className="flex-grow-1">
                <div>Cargando...</div>
            </Card>
        )
    }

    return (
        <Card className="flex-grow-1">
            <Row xs={1} md={3} lg={4} xl={6}>
                {
                    data?.map((menu, index) => (
                        <Col key={index} className='my-1 px-1'>
                            <Button
                                className="d-flex flex-column justify-content-center"
                                block
                                style={{ height: '110px' }}
                                as={Link}
                                to={`carta/${menu.id}`}
                            >
                                <div className='font-weight-semibold text-truncate'>
                                    {menu.name}<br />
                                    {menu.id}
                                </div>
                            </Button>
                        </Col>
                    ))
                }
            </Row>
        </Card>
    )
}

const Template = () => {
    const { cartaId } = useParams()

    const { data: items, add } = useCollection(`cartas/${cartaId}/items`, {
        orderBy: ['itemId', 'asc']
    })

    const { data: cartaObj, update } = useDocument(`cartas/${cartaId}`)

    return (
        <>
            <div className='d-flex mbt-1 mb-1'>
                <div className='btn btn-light btn-sm text-truncate'>
                    {cartaObj?.name}
                </div>
                <Button as={Link} size='sm' className='text-nowrap' to='/carta' variant='outline-dark'><FontAwesomeIcon icon={faArrowLeft} className='mr-1' />Regresar</Button>
                <Button as={Link} size='sm' to={`/carta/${cartaId}`} variant={useRouteMatch(`/carta/${cartaId}`).isExact ? 'dark' : 'outline-dark'}>Carta</Button>
                <Button as={Link} size='sm' to={`/carta/${cartaId}/grupos`} variant={useRouteMatch(`/carta/${cartaId}/grupos`) ? 'dark' : 'outline-dark'}>Grupos</Button>
            </div>
            <Switch>
                <Route exact path='/carta/:cartaId' render={props => <Carta items={items} add={add} cartaObj={cartaObj} update={update} />} />
                <Route exact path='/carta/:cartaId/grupos' render={props => <Grupos cartaObj={cartaObj} update={update} />} />
            </Switch>
        </>
    )
}