import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Axios from 'axios'
import { useDocument } from '@nandorojo/swr-firestore'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEquals, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

import { Card, Button, Table } from 'react-bootstrap'

import { useConfig } from '../../Hook/ConfigContext'
import { usePrinter } from '../../Hook/PrinterContext'
import { useDialog } from '../../Hook/DialogContext'

moment.locale('es')

export default () => {
    const { config, update } = useConfig()
    const { print } = usePrinter()
    const { getDialogResponse } = useDialog()

    const [ordenes, setOrdenes] = useState()

    const [metodosDePago, setMetodosDePago] = useState({})
    const [ingresoTotal, setIngresoTotal] = useState(0)

    const [hourlyGroupKeys, setHourlyGroupKeys] = useState({})

    const [numeroFacturas, setNumeroFacturas] = useState(0)
    const [numeroBoletas, setNumeroBoletas] = useState(0)

    const doc = useDocument(`config/${sessionStorage.local_id}/turnos/${config.caja.active}`, {
        parseDates: ['created']
    })

    useEffect(() => {
        doc.data && !ordenes && fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc])

    const fetchData = async () => {
        const startString = moment(doc.data.created).add(5, 'h').format('YYYY-MM-DD HH:mm:ss')
        const endString = moment().add(5, 'h').format('YYYY-MM-DD HH:mm:ss')

        const response = await Axios.get(`/api/reportes/salon/?start=${startString}&end=${endString}`)

        let _total = 0
        let metodosDePago = {}
        let hourlyGroupKeys = {}
        let numeroBoletas = 0
        let numeroFacturas = 0

        //CREATE HOURLY GROUP KEY FOR EVERY HOUR IN TIMESPAN
        let currentHourParsed = moment(doc.data.created)
        while (currentHourParsed.isBefore(moment())) {
            const startHourTime = currentHourParsed.startOf('hour')
            hourlyGroupKeys[startHourTime.format('DD/MM/YYYY, h:00 a - ') + startHourTime.add(1, 'hour').format('h:00 a')] = []
        }

        //PARSE EVERY ORDER
        response.data.forEach(orden => {
            //ADD ORDER TO HOURLY GROUP
            const time = moment(orden.apertura).local()
            const startHourTime = time.startOf('hour')
            const groupKey = startHourTime.format('DD/MM/YYYY, h:00 a - ') + startHourTime.add(1, 'hour').format('h:00 a')
            hourlyGroupKeys[groupKey].push(orden)

            //ORDER TOTAL
            const totalAmount = orden.items.reduce((acc, item) => acc + (item.precio * item.cant), 0) * (1 - (orden.descuento || 0))

            //SUM TOTAL
            _total += totalAmount

            //ADD TO VOUCHER TYPE
            orden.metaData?.comprobantes && orden.metaData.comprobantes.forEach(comprobante => {
                if (comprobante.startsWith('F')) {
                    numeroFacturas++
                } else if (comprobante.startsWith('B')) {
                    numeroBoletas++
                }
            })

            //ADD AMOUNT TO PAYMENT METHOD'S TOTAL
            if (orden?.metaData?.formaDePago === 'combinado') {
                orden.metaData.combinado.forEach(pago => {
                    if (metodosDePago[pago.metodo]) {
                        metodosDePago[pago.metodo] += Number(pago.amount)
                    } else {
                        metodosDePago[pago.metodo] = Number(pago.amount)
                    }
                })
            } else {
                console.log(orden?.metaData?.formaDePago, totalAmount)
                if (metodosDePago[orden?.metaData?.formaDePago]) {
                    metodosDePago[orden?.metaData?.formaDePago] += totalAmount
                } else {
                    metodosDePago[orden?.metaData?.formaDePago] = totalAmount
                }
            }

        })

        setOrdenes(response.data)

        setIngresoTotal(_total)
        setMetodosDePago(metodosDePago)
        setHourlyGroupKeys(hourlyGroupKeys)
        setNumeroFacturas(numeroFacturas)
        setNumeroBoletas(numeroBoletas)
    }

    return (
        <Card className='flex-grow-1'>
            <Card.Body>

                <Button size='sm' variant='danger' className='mb-2 mr-2' onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Confirmacion',
                        message: 'Estas seguro que quieres terminar el turno?',
                        buttons: [
                            {
                                label: "Cancelar",
                                variant: 'secondary'
                            },
                            {
                                label: "Aceptar"
                            }
                        ]
                    })
                    if (!response) return
                    update({
                        caja: {
                            ...config.caja,
                            active: null,
                            activeShiftName: null
                        }
                    })
                }}>Cerrar turno</Button>
                <Button size='sm' className='mb-2' onClick={() => {
                    print('detalleDeTurno', {
                        shiftName: doc?.data?.name,
                        id: doc.data.id,
                        apertura: doc?.data?.created,
                        username: doc?.data?.username,
                        cierre: moment().toDate(),
                        numeroBoletas,
                        numeroFacturas,
                        metodosDePago,
                        ingresoTotal,
                        hourlyGroupKeys
                    })
                }}>Imprimir Resumen (ticket)</Button>

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Resumen del turno
                </Card.Title>
                <div className='d-flex w-100'>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Nombre del turno:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5 className='small font-weight-bold'>{doc?.data?.name || '-'}</h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Id:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5 className='small font-weight-bold'>{doc?.data?.id}</h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Hora de apertura:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5 className='small font-weight-bold'>{moment(doc?.data?.created).format('DD/MM/YYYY, h:mm:ss a')}</h5>
                        </div>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Ordenes Registradas
                </Card.Title>
                <div className='d-flex w-100'>
                    <div className='w-25'>
                        <h5 className='small text-muted'># de Ordenes:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>{ordenes?.length || 0}</h5>
                        </div>
                    </div>
                    <div>
                        <h5 className='small text-muted'>Detalle de Ordenes:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>{ordenes?.map(order => order.id).join(', ')}</h5>
                        </div>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Comprobantes
                </Card.Title>
                <div className='d-flex w-100'>
                    <div className='w-25'>
                        <h5 className='small text-muted'># Boletas:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>{numeroBoletas}</h5>
                            <h5 className='mr-3'><FontAwesomeIcon icon={faPlus} /></h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'># Facturas:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>{numeroFacturas}</h5>
                            <h5 className='mr-3'><FontAwesomeIcon icon={faEquals} /></h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Total:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>{numeroFacturas + numeroBoletas}</h5>
                        </div>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Balance de Efectivo
                </Card.Title>
                <div className='d-flex w-100'>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Monto  de apertura:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>S/. {doc?.data?.startAmount.toFixed(2)}</h5>
                            <h5 className='mr-3'><FontAwesomeIcon icon={faPlus} /></h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Ingreso de efectivo calculado:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5 className='text-success'>S/. {(metodosDePago.efectivo || 0).toFixed(2)}</h5>
                            <h5 className='mr-3'><FontAwesomeIcon icon={faMinus} /></h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Gastos:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5 className='text-danger'>S/. 0.00</h5>
                            <h5 className='mr-3'><FontAwesomeIcon icon={faEquals} /></h5>
                        </div>
                    </div>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Monto en caja calculado:</h5>
                        <div className='d-flex justify-content-between'>
                            <h5>S/. {((metodosDePago.efectivo || 0) + doc?.data?.startAmount).toFixed(2)}</h5>
                        </div>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Ingresos por Método de Pago
                </Card.Title>
                <div className='d-flex w-100'>
                    {
                        Object.keys(metodosDePago).map((key, index) => (
                            <div className='w-20'>
                                <h5 className='small text-muted'>{key}:</h5>
                                <div className='d-flex justify-content-between'>
                                    <h5 className='text-success'>S/. {metodosDePago[key].toFixed(2)}</h5>
                                    <h5 className='mr-3'><FontAwesomeIcon icon={index = metodosDePago.length - 1 ? faEquals : faPlus} /></h5>
                                </div>
                            </div>
                        ))
                    }
                    <div className='w-20'>
                        <h5 className='small text-muted'>Total:</h5>
                        <h5>S/. {ingresoTotal?.toFixed(2)}</h5>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Propinas
                </Card.Title>
                <div className='d-flex w-100'>
                    <div className='w-25'>
                        <h5 className='small text-muted'>Propinas en efectivo:</h5>
                        <h5>S/. {ordenes?.reduce((acc, orden) => acc + (orden.metaData?.propina || 0), 0).toFixed(2)}</h5>
                    </div>
                </div>
                <hr />

                {/* SECTION */}
                <Card.Title className='text-muted'>
                    Ventas por hora
                </Card.Title>
                <Table size='sm' striped>
                    <thead className='text-muted'>
                        <tr>
                            <td>
                                Hora
                            </td>
                            <td>
                                # Ordenes
                            </td>
                            <td>
                                # Ordenes delivery
                            </td>
                            <td>
                                Total ingreso
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(hourlyGroupKeys)?.map(element => (
                                <tr>
                                    <td>
                                        {element}
                                    </td>
                                    <td>
                                        {hourlyGroupKeys[element].length}
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        S/.
                                        {
                                            hourlyGroupKeys[element].reduce((acc, orden) => {
                                                const itemTotal = orden.items.reduce((acc, item) => acc + (item.precio * item.cant), 0)
                                                return acc + (itemTotal * (1 - (orden.descuento || 0)))
                                            }, 0).toFixed(2)
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}