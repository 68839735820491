import firebase from 'firebase/app'
import 'firebase/firestore'

import firebaseConfig from '../firebaseConfig'

firebase.initializeApp(firebaseConfig)

let db = firebase.firestore()

type Config = Parameters<typeof firebase.initializeApp>[0]

class Fuego {
  public db: ReturnType<firebase.app.App['firestore']>
  public auth: typeof firebase.auth
  public functions: typeof firebase.functions
  public storage: typeof firebase.storage

  constructor(config: Config) {
    firebase.firestore().settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
    
    firebase.firestore().enablePersistence()
    
    this.db = !firebase.apps.length
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore()
    this.auth = firebase.auth
    this.functions = firebase.functions
    this.storage = firebase.storage
  }
}

export { db, firebase, Fuego }