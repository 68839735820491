import React, { createContext } from 'react'
import { useDocument } from '@nandorojo/swr-firestore'
import { useDialog } from './DialogContext'
import { db } from '../lib/fuego'
import firebase from 'firebase'

const ConfigContext = createContext()

const authorizeActions = {
    'anulacion-mesa': {
        query: 'Anular una mesa',
        permission: 'salon.anular'
    },
    'anulacion-orden': {
        query: 'Anular una orden',
        permission: 'delivery.anular'
    },
    'eliminar-item-mesa': {
        query: 'Eliminar un Item',
        permission: 'salon.deleteItem'
    },
    'documento-interno': {
        query: 'Emitir un documento interno',
        permission: 'salon.documentoInterno'
    },
    'documento-interno-0': {
        query: 'Emitir un documento interno con valor 0',
        permission: 'salon.documentoInterno.0'
    },
}

export default ({ children }) => {
    const { data: config, update } = useDocument(`config/${sessionStorage.local_id}`, { listen: true })
    const { data: userConfig } = useDocument(`userConfig/${sessionStorage.userId}`, { listen: true })

    const { getDialogResponse } = useDialog()

    const locales = JSON.parse(sessionStorage.locales)
    const localDef = locales.find(localDef => localDef.id === sessionStorage.local_id)

    const hasPermission = (perm, options) => {
        const permisionsArray = options?.customPermsArray || userConfig?.permisos || []
        if (typeof perm === 'string') {
            //FORCE EXACT PERMISSION
            if (options?.forcePerm) return permisionsArray.some(userPerm => userPerm === perm)

            //REGULAR CEHCK
            const parts = perm.split('.')
            for (let i = parts.length; i >= 0; i--) {
                if (permisionsArray.some(perm => perm === `${parts.slice(0, i).join('.')}${(i !== parts.length && i !== 0) ? '.' : ''}${i !== parts.length ? '*' : ''}`)) return true
            }
        } else {
            for (let i = 0; i < perm.length; i++) {
                let element = perm[i]

                const parts = element.split('.')
                for (let i = parts.length; i >= 0; i--) {
                    if (permisionsArray.some(perm => perm === `${parts.slice(0, i).join('.')}${(i !== parts.length && i !== 0) ? '.' : ''}${i !== parts.length ? '*' : ''}`)) return true
                }
            }
        }
        return false
    }

    const authorize = async (action) => {

        const actionObj = authorizeActions[action]

        if (hasPermission(actionObj?.permission)) return {
            user: config.alias || config.id
        }

        const response = await getDialogResponse({
            title: 'Se Requieres Autorizacion',
            message: `Autorizacion necesaria ${actionObj.query}.`,
            input: true,
            buttons: [
                {
                    label: 'Cancelar',
                    variant: 'secondary'
                },
                {
                    label: 'Confirmar',
                    resolveInput: true,
                    disableNoInput: true
                }
            ]
        })
        if (!response) return undefined

        const collectionRef = db.collection('userConfig').orderBy(firebase.firestore.FieldPath.documentId()).startAt('U' + sessionStorage.local_id.slice(1, 9)).endAt('U' + sessionStorage.local_id.slice(1, 9) + "\uf8ff").where('authCode', '==', response)
        const data = await collectionRef.get()
        const users = data.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
        })
        if (!users?.length) return {
            error: 'No se encontro el codigo de autorizacion.'
        }

        let authObj = {
            error: 'Codigo no tiene permisos.'
        }
        users.forEach(user => {
            if (!user.permisos) return
            if (hasPermission(actionObj.permission, { customPermsArray: user.permisos })) {
                authObj = {
                    user: user.alias || user.id
                }
            }
        })
        return authObj
    }

    return (
        <ConfigContext.Provider value={{
            config,
            update,
            userConfig,
            hasPermission,
            authorize,
            localDef
        }}>
            {children}
        </ConfigContext.Provider>
    )
}

export const useConfig = () => React.useContext(ConfigContext)





