import React, { createContext, useState } from 'react'
import useSWR from 'swr'
import equal from 'fast-deep-equal/es6/react'
import { find } from 'lodash'

import { useDialog } from './DialogContext'

const OrdersContext = createContext()

export default ({children}) => {
    const { showNotification } = useDialog()
    const [ isPaused, setIsPaused ] = useState()

    const pause = () => setIsPaused(true)
    const resume = () => setIsPaused(false)

    //WILL CALL ONLY IF USER HAS ACCESS TO DELIVERY MODULE
    const { data, mutate } = useSWR(sessionStorage.permisos.includes('MODULO-DELIVERY') ? `/api/orden/delivery` : null, {
        refreshInterval: 30000,
        initialData: [],
        revalidateOnMount: true,
        refreshWhenHidden: true,
        isPaused: () => isPaused,
        //FOR NOTIFICATIONS
        compare: (a, b) => {
            a?.length && Promise.all(b.map(element => {
                !find( a, o => o.id === element.id ) && element.usuario !== sessionStorage.username && showNotification( {variant: 'success', message: `Nueva orden: #${element.id}`, duration: 60000, sound: true} )
                return null
            }))
            return equal(a, b)
        }
    })

    //WILL CALL ONLY IF USER HAS ACCESS TO CALLCENTER MODULE
    const { data: callcenterData, mutate: mutateCallcenter } = useSWR(sessionStorage.permisos.includes('MODULO-CALLCENTER') ? `/api/orden/delivery?showEnviar=true&useCallcenter=true` : null, {
        refreshInterval: 30000,
        initialData: [],
        revalidateOnMount: true,
        refreshWhenHidden: true,
        isPaused: () => isPaused
    })

    //WILL CALL ONLY IF USER HAS ACCESS TO SALON MODULE
    const { data: salonData, mutate: mutateSalon } = useSWR(sessionStorage.permisos.includes('MODULO-SALON') ? `/api/orden/salon` : null, {
        refreshInterval: 30000,
        initialData: [],
        revalidateOnMount: true,
        refreshWhenHidden: true,
        isPaused: () => isPaused
    })

    return (
		<OrdersContext.Provider value={{
            data, mutate,
            callcenterData, mutateCallcenter,
            salonData, mutateSalon,
            pause, resume
        }}>
			{children}
		</OrdersContext.Provider>
	)
}

export function useOrders () {
    return React.useContext(OrdersContext)
}