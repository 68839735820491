import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal, Card } from 'react-bootstrap'

import StatusBadge from './StatusBadge'
import { useConfig } from '../Hook/ConfigContext'

export default ({ update, hidden, orden }) => {
    const [show, setShow] = useState()

    const { hasPermission } = useConfig()

    const updateOrder = async (obj) => {
        await update(obj)
        setShow(false)
    }

    return (
        <>
            <Modal show={show} centered onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Despacho</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center mb-2'>
                        <StatusBadge id={orden?.id} status={orden?.status} />
                    </div>

                    {
                        orden?.metadata.EntregappOrderId ?
                            <Card bg='dark' className='p-2 w-auto text-light d-flex'>
                                <div>
                                    <img src={require('../assets/img/entregappLogo.svg')} alt="" width={100} />
                                    <span className='ml-2 small'>{orden.origin}</span>
                                </div>
                                Orden: {orden.EntregappOrderId}
                            </Card>
                            :
                            <Card className='mb-2'>
                                <Card.Body>
                                    <h6 className='d-flex'>
                                        <span><FontAwesomeIcon icon={faUser} className='mr-2' />{orden?.nombre || orden?.metadata.comandappOnlineUserName}</span>
                                        <span className='ml-auto'><FontAwesomeIcon icon={faPhone} className='mr-2' />{orden?.telefono || orden?.metadata.comandappOnlineUserPhone}</span>
                                    </h6>
                                    <h6>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />{orden?.direccion || orden?.metadata.comandappOnlineUserAddress}<br />
                                    </h6>
                                </Card.Body>

                            </Card>
                    }
                    <Card>
                        <Card.Body className='text-center'>
                            <h5>Cambiar Estado</h5>

                            <div className='d-flex justify-content-around'>
                                <Button size='sm' variant='info' disabled={!hasPermission('delivery.setDespacho') || orden?.status !== 'activo'} onClick={() => updateOrder({
                                    status: 'despacho',
                                    despacho: '[timestamp]'
                                })}>Despacho</Button>

                                <Button size='sm' variant='success' disabled={!hasPermission('delivery.setDespachado') || (orden?.status !== 'activo' && orden?.status !== 'despacho')} onClick={() => updateOrder({
                                    status: 'despachado',
                                    despachado: '[timestamp]'
                                })}>Despachado</Button>

                                <Button size='sm' variant='warning' disabled={!hasPermission('delivery.setEntregado') || (orden?.status !== 'activo' && orden?.status !== 'despacho' && orden?.status !== 'despachado')} onClick={() => updateOrder({
                                    status: 'entregado',
                                    entregado: '[timestamp]'
                                })}>Entregado</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>

            <Button hidden={hidden} variant="success" size="lg" onClick={() => setShow(true)}>Despachar</Button>
        </>
    )
}