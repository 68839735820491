import React, { createContext, useState, useEffect } from 'react'

import { Modal, Button, Card, Alert, FormControl, FormGroup, FormLabel } from 'react-bootstrap'

const DialogContext = createContext()

export default ({ children }) => {
    const [, updateState] = React.useState()
    const forceUpdate = React.useCallback(() => updateState({}), [])

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogConfig, setDialogConfig] = useState({})

    const [notifications] = useState([])

    const [inputValue, setInputValue] = useState()

    const [audio] = useState(new Audio(require('../assets/mp3/alarm.mp3')))

    useEffect(() => {
        if (notifications.filter(notification => notification.sound && notification.show).length) {
            audio.loop = true
            audio.paused && audio.play()
        } else { audio.pause() }
    })

    const openDialog = ({ ...opts }) => {
        setDialogOpen(true)
        setDialogConfig({ ...opts })
    }

    const resetDialog = () => {
        setDialogOpen(false)
        setDialogConfig({})
        setInputValue(undefined)
    }

    const resolveDialog = (i) => {
        resetDialog()
        dialogConfig.actionCallback(i)
    }

    const addNotification = ({ ...opts }) => {
        notifications.unshift(new PushNotification({ ...opts, forceUpdate }))
        forceUpdate()
    }

    return (
        <DialogContext.Provider value={{ openDialog, addNotification }}>
            <Modal show={dialogOpen} backdrop={dialogConfig.dismissible ? true : 'static'} className={dialogConfig.textNotCentered ? '' : 'text-center'} onHide={() => { resolveDialog(-1) }}>
                <Modal.Body>
                    {
                        dialogConfig.title && <Card.Title> <h5 className='mb-0'>{dialogConfig.title}</h5> </Card.Title>
                    }
                    <Card.Text>{dialogConfig.message}</Card.Text>
                    {
                        dialogConfig.textArea && <FormControl as="textarea" rows={3} id='textArea' defaultValue={dialogConfig.textAreaDefaultValue || ''} />
                    }
                    {
                        dialogConfig.input &&
                        <FormGroup controlId="formBasicPassword">
                            <FormLabel>{dialogConfig.input.label}</FormLabel>
                            <FormControl as={dialogConfig.input.type} onChange={e => setInputValue(e.target.value)}>
                                {dialogConfig.input.options && dialogConfig.input.options.map(option => (
                                    <option value={option.value} hidden={option.hidden}>{option.label}</option>
                                ))}
                            </FormControl>
                        </FormGroup>

                    }
                </Modal.Body>
                {
                    dialogConfig.buttons || dialogConfig.textArea ?
                        <Card.Footer>
                            {
                                dialogConfig.textAreaCancelButton && <Button variant='secondary' className='mx-1' onClick={() => resolveDialog(null)}>Cancelar</Button>
                            }
                            {
                                dialogConfig.buttons?.map((button, i) => (
                                    <Button key={i} className='mx-1' variant={button.variant} size={button.size} disabled={button.disableNoInput ? !inputValue : false} onClick={() => {
                                        if (button.resolveInput) {
                                            resolveDialog(inputValue || i) 
                                            return
                                        }
                                        resolveDialog(i)
                                    }}>{button.label}</Button>
                                ))
                            }
                            {
                                (dialogConfig.textArea) && <Button className='mx-1' onClick={() => resolveDialog(document.getElementById('textArea').value)}>Confirmar</Button>
                            }
                        </Card.Footer> : null
                }
            </Modal>

            {
                <div className='position-fixed d-flex flex-column z-index-1060 my-3' style={{ width: '800px', maxWidth: '90%', left: '50%', transform: 'translate(-50%, 0)' }}>
                    {
                        notifications.map(notification => (
                            <Alert variant={notification.variant} hidden={!notification.show} dismissible onClose={() => notification.remove()}>
                                {notification.message}
                            </Alert>
                        ))
                    }
                </div>
            }

            {children}
        </DialogContext.Provider>
    )
}

export const useDialog = () => {
    const { openDialog, addNotification } = React.useContext(DialogContext);

    const getDialogResponse = ({ ...options }) =>
        new Promise((res) => openDialog({ actionCallback: res, ...options }))

    const showNotification = ({ ...opts }) => addNotification({ ...opts })

    return { getDialogResponse, showNotification }
}

class PushNotification {
    constructor(settings) {
        this.duration = settings.duration ?? 1000
        this.message = settings.message
        this.variant = settings.variant ?? 'success'
        this.arraySetter = settings.arraySetter
        this.sound = settings.sound
        this.array = settings.array
        this.id = this.uniqueid()
        this.show = true
        this.forceUpdate = settings.forceUpdate

        this.create()
    }

    uniqueid() {
        var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var ID_LENGTH = 8;
        var rtn = '';
        for (var i = 0; i < ID_LENGTH; i++) {
            rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
        }
        return rtn;
    }

    create() {
        this.timeout = setTimeout(() => {
            this.show = false
            this.forceUpdate()
        }, this.duration)
    }

    remove() {
        console.log('removing', this.id)
        this.show = false
        clearTimeout(this.timeout)
        this.forceUpdate()
    }

}





