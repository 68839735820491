import React from 'react'
import moment from 'moment'
import { db } from '../../lib/fuego'

import { Card, Form, Button } from 'react-bootstrap'

import { useConfig } from '../../Hook/ConfigContext'

moment.locale('es')

export default () => {
    const { config, update } = useConfig()

    const onSubmit = async e => {
        e.preventDefault()

        const form = e.target

        const collectionRef = db.collection(`config/${sessionStorage.local_id}/turnos`)
        const { id } = await collectionRef.add({
            startAmount: parseFloat(form.elements.startAmount.value),
            name: form.elements.name.value,
            created: new Date(),
            username: sessionStorage.username
        })

        update({
            caja: {
                ...config.caja,
                active: id,
                activeShiftName: form.elements.name.value || ''
            }
        })
    }

    return (
        <Card className='m-auto'>
            <Card.Body>
                <Card.Title className='text-center'>
                    Abrir turno
                </Card.Title>
                <Form onSubmit={onSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre del turno</Form.Label>
                        <Form.Control size='sm' name='name' />
                        <Form.Text className="text-muted">
                            Este campo es opcional.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Monto de apertura</Form.Label>
                        <Form.Control required size='sm' name='startAmount' type='number' step='0.01' />
                    </Form.Group>
                    <Form.Label className='mr-2'>Hora de apertura:</Form.Label>
                    <span className='text-success'>{moment().format('dddd, D [de] MMMM YYYY, h:mm:ss a')}</span> <br />
                    <Button className='float-right' size='sm' type='submit'>
                        Abrir
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    )
}