import React, { Fragment } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faHotel, faMotorcycle, faUser, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
import Table from 'react-bootstrap/Table'

import ItemList from '../ItemList'
import Escp from './Escp'
import StatusBadge from '../StatusBadge'

export default ({ data, onHide }) => {
    const Content = () => {
        switch (data?.objectType) {
            case 'salon':
                return (
                    <Modal.Body>
                        <h5 className='d-flex justify-content-between'>
                            <Badge pill variant='dark'><FontAwesomeIcon icon={faHotel} className='mr-2' />{data.id}</Badge>
                            <Badge pill variant='dark'><FontAwesomeIcon icon={faClock} className='mr-2' />{new Date(data.apertura).toLocaleString()}</Badge>
                        </h5>

                        {
                            data.memo ?
                                <h6 className='d-flex justify-content-between'>
                                    <span className="text-danger font-weight-bold h5 m-0 card text-center mx-auto px-1">{data.memo}</span>
                                </h6> : null
                        }

                        <ItemList
                            comandados={data?.items || []}
                            descuento={data?.descuento}
                            delivery={0} // NO DELIVERY ON SALON
                        />

                        {
                            data.metaData?.nota ?
                                <h6 className=''>
                                    <span className="text-danger font-weight-bold h5 m-0 card">{data.metaData?.nota}</span>
                                </h6> : null
                        }

                        {
                            data.metaData?.deletedItems ?
                                <Fragment>
                                    <hr className='my-2' />
                                    <h5 className='text-center'>Items Eliminados</h5>
                                    <Table size='sm' className='m-0 bg-pink'>
                                        <thead>
                                            <tr>
                                                <th>Desc.</th>
                                                <th className="w-1">Cant.</th>
                                                <th className="text-right w-1">Precio</th>
                                                <th className="text-right w-1">Importe</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.metaData?.deletedItems?.map((i, index) => (
                                                    <tr key={index}>
                                                        <td>{i.name}</td>
                                                        <td className="text-center">{i.cant}</td>
                                                        <td className="text-right">{i.precio.toFixed(2)}</td>
                                                        <td className="text-right">{(i.precio * i.cant).toFixed(2)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Fragment> : null
                        }

                    </Modal.Body>
                )
            case 'delivery':
                return (
                    <Modal.Body>
                        <h5 className='d-flex justify-content-between'>
                            <StatusBadge id={data.id} status={data?.status} small />
                            <Badge pill variant='dark'><FontAwesomeIcon icon={faClock} className='mr-2' />{new Date(data.apertura).toLocaleString()}</Badge>
                        </h5>

                        {
                            data.memo ?
                                <h6 className='d-flex justify-content-between'>
                                    <span className="text-danger font-weight-bold h5 m-0 card text-center mx-auto px-1">{data.memo}</span>
                                </h6> : null
                        }

                        <h6 className='d-flex'>
                            <Badge pill variant='dark'>ID: {data.cliente}</Badge>
                            <span className='ml-2'><FontAwesomeIcon icon={faUser} className='mr-2' />{data.nombre || data.metadata.comandappOnlineUserName}</span>
                            <span className='ml-auto'><FontAwesomeIcon icon={faPhone} className='mr-2' />{data.telefono || data.metadata.comandappOnlineUserPhone}</span>
                        </h6>
                        <h6 className='mb-0'>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />{data.direccion || data.metadata.comandappOnlineUserAddress}<br />
                        </h6>

                        <ItemList comandados={data?.items || []} descuento={data?.descuento} delivery={data?.delivery} />
                    </Modal.Body>
                )

            case 'escp':
                return <Escp data={data} />
            default:
                return (
                    <Modal.Body>
                        Error
                    </Modal.Body>
                )
        }
    }

    return (
        <Modal show={!!data} onHide={() => onHide()}>
            <Modal.Header closeButton />
            <Content />
        </Modal>
    )
}