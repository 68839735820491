import React, { useState } from 'react'
import Axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload } from '@fortawesome/free-solid-svg-icons'

export default () => {

    const [gridApi, setGridApi] = useState()
    const [rowData, setRowData] = useState([])
    const [gridColumnApi, setGridColumnApi] = useState()

    const fetchData = async e => {
        e.preventDefault()
        const form = e.target

        gridApi.showLoadingOverlay()
        const startString = moment(form.elements.start.value).add(5, 'h').format('YYYY-MM-DD HH:mm:ss')
        const endString = moment(form.elements.end.value).endOf('day').add(5, 'h').format('YYYY-MM-DD HH:mm:ss')

        try {
            const response = await Axios.get(`/api/reportes/delivery/?start=${startString}&end=${endString}`)

            setRowData(response.data)

            if (!response.data.length)
                gridApi.showNoRowsOverlay()
            else
                gridApi.hideOverlay()

        } catch (error) {
            gridApi.hideOverlay()
            alert(error.message)
        }
    }

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        var dateParts = dateAsString.split(' ');
        dateParts = dateParts[0].split('/');
        var day = Number(dateParts[2]);
        var month = Number(dateParts[1]) - 1;
        var year = Number(dateParts[0]);
        var cellDate = new Date(day, month, year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        } else {
            return 0;
        }
    }

    let columnDefs = [
        { headerName: "Local", headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
            valueGetter: params => JSON.parse(sessionStorage.locales).find(localInStorage => localInStorage.id === params?.data?.local)?.nombre
        },
        { headerName: "Id", field: "id"},
        { headerName: "Usuario", field: "usuario"},
        { headerName: "Origen", field: "origen"},
        { headerName: "Status", field: "status"},
        { headerName: "Memo", field: "memo"},
        { headerName: "Cliente", field: "cliente" },
        { headerName: "Nombre", field: "nombre" },
        { headerName: "Telefono", field: "telefono" },
        { headerName: "Direccion", field: "direccion"},
        { headerName: "Apertura", filter: 'agDateColumnFilter',
            valueGetter: params => moment(params.data?.apertura).local().format('DD/MM/YYYY, h:mm:ss a'),
            filterParams: { comparator: dateComparator }
        },
        { headerName: "Enviado", filter: 'agDateColumnFilter',
            valueGetter: params => params.data?.enviado ? moment(params.data.apertura).local().format('DD/MM/YYYY, h:mm:ss a') : '',
            filterParams: { comparator: dateComparator }
        },
        { headerName: "Despacho", filter: 'agDateColumnFilter',
            valueGetter: params => params.data?.despacho ? moment(params.data.apertura).local().format('DD/MM/YYYY, h:mm:ss a') : '',
            filterParams: { comparator: dateComparator }
        },
        { headerName: "Despachado", filter: 'agDateColumnFilter',
            valueGetter: params => params.data?.despachado ? moment(params.data.apertura).local().format('DD/MM/YYYY, h:mm:ss a') : '',
            filterParams: { comparator: dateComparator }
        },
        { headerName: "Entregado", filter: 'agDateColumnFilter',
            valueGetter: params => params.data?.entregado ? moment(params.data.enrtegado).local().format('DD/MM/YYYY, h:mm:ss a') : '',
            filterParams: { comparator: dateComparator }
        },
        {headerName: "Forma de Pago", resizable: true, filter: true, valueGetter: params => params.data?.metadata?.formaDePago},
        { headerName: "Delivery", field: "delivery", filter: 'agNumberColumnFilter',
            valueFormatter: params => 'S/. ' + params.value.toFixed(2)
        },
        { headerName: "Total", sortable: true, resizable: true, filter: 'agNumberColumnFilter',
            valueGetter: params => params.data?.items ? params.data.items.reduce((acc, item) => acc + (item.precio * item.cant), 0) : 0,
            valueFormatter: params => 'S/. ' + params.value.toFixed(2)
        },
        { headerName: "Total (inc. delivery)", sortable: true, resizable: true, filter: 'agNumberColumnFilter',
            valueGetter: params => (params.data?.items ? params.data.items.reduce((acc, item) => acc + (item.precio * item.cant), 0) : 0) + params.data?.delivery,
            valueFormatter: params => 'S/. ' + params.value.toFixed(2)
        }
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 d-flex flex-row mw-100'>
                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='start' required/>
                    </Form.Group>

                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='end' required/>
                    </Form.Group>

                    <Button block size='sm' variant='outline-dark' type='submit'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1'/>Reporte
                    </Button>
                </div>

                <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1'/>Exportar
                    </Button>
                </div>
                
            </Form>

            <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={rowData || []}
                    onGridReady={ params => {setGridApi(params.api); setGridColumnApi(params.columnApi)} }
                    onFirstDataRendered={() => {autoSizeAll()}}
                />         
            </div>
        </div>
    )

}