import React from 'react'
import moment from 'moment'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Card } from 'react-bootstrap'

import { useConfig } from '../../Hook/ConfigContext'

import Summary from './Summary'
import Create from './Create'

moment.locale('es')

export default () => {
    const { config } = useConfig()

    return (
        <Switch>
            <Route exact path='/caja'
                render={(props) => config?.caja?.active ? <Summary /> : <Create />}
            />
            <Route exact path='/caja/:id'
                render={(props) => <ShiftSummary/>}
            />
            <Redirect to='/' />
        </Switch>
    )
}

const ShiftSummary = () => {
    return (
        <Card className='flex-grow-1'>
            <Card.Body>
                <h1>ShiftSummary</h1>
            </Card.Body>
        </Card>
    )
}