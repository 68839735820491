import React, { useState } from 'react'
import Axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import { flatten } from 'lodash'
import firebase from 'firebase/app'
import { chain, groupBy, reduce, map } from 'lodash'

import { Row, Button, Form, Card, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { useInfoCards } from '../../Hook/InfoCardsContext'

export default () => {
    const [local, setLocal] = useState()

    if (!local) {
        const locales = JSON.parse(sessionStorage.locales)

        return (
            <Card className='flex-grow-1'>
                <Row xl={6} lg={5} md={4} sm={3} xs={2}>
                    {
                        locales.map(local => (
                            <Col key={local.id} className="my-1 px-1">
                                <Button
                                    className="text-truncate"
                                    block
                                    style={{ height: '110px' }}
                                    onClick={e => setLocal(local)}
                                >
                                    {local.nombre}
                                </Button>
                            </Col>
                        ))
                    }
                </Row>
            </Card>
        )
    }

    return (
        <Content local={local} setLocal={setLocal} />
    )
}


const Content = ({ local, setLocal }) => {
    const [gridApi, setGridApi] = useState()
    const [gridColumnApi, setGridColumnApi] = useState()
    const [rowData, setRowData] = useState([])

    const { showSummary } = useInfoCards()

    const fetchData = async e => {
        e.preventDefault()

        gridApi.showLoadingOverlay()

        const form = e.target

        //Date Strings
        const startString = `${form.elements.start.value} 05:00:00`
        const end = new Date(new Date(form.elements.end.value).valueOf() + 1000 * 60 * 60 * 24 + 1000 * 60 * 60 * 5)
        const endString = `${end.getFullYear()}-${end.getMonth() + 1 < 10 ? '0' : ''}${end.getMonth() + 1}-${end.getDate() < 10 ? '0' : ''}${end.getDate()} 05:00:00`

        try {
            //ITEMS ARRAY CORRESPONDS TO EACH LINE OF THE LIST (MAY CONTAIN MORE THAN 1 ITEM PER LINE)

            const { data } = await Axios.get(`/api/reportes/salon/?start=${startString}&end=${endString}&local_id=${local.id}&cliente_id=${local.cliente_id}`)

            console.log(data)

            const filteredData = data.filter(e => (e.status === 'anulado' && e.items.length))

            console.log(filteredData)

            const parsedData = filteredData.reduce((acc, orden) => {
                acc.push(...orden.items.map(item => ({
                    ...item,
                    ordenStatus: orden.status,
                    statusNota: orden.status_nota
                })))
                return acc
            }, [])

            console.log(parsedData)

            // const processedData = chain(parsedData).groupBy('itemId').map((value, key, length) => {
            //     const cantTotal = value.reduce((acc, e) => acc + e.cant, 0)
            //     const cantTerminadas = value.reduce((acc, e) => acc + (e.ordenStatus === 'terminado' ? e.cant : 0), 0)
            //     const cantAnuladas = value.reduce((acc, e) => acc + (e.ordenStatus === 'anulado' ? e.cant : 0), 0)

            //     return {
            //         itemId: value[0].itemId,
            //         name: value[0].name,
            //         path: value[0].path,
            //         cantTotal,
            //         cantAnuladas,
            //         cantTerminadas
            //     }
            // }).value()

            // console.log(processedData)

            setRowData(parsedData)

        } catch (error) {
            alert(error.message)
        }
    }

    let columnDefs = [
        { headerName: "Id", field: "itemId", checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, },
        { headerName: "Nombre", field: "name" },
        { headerName: "Grupo", field: "path" },
        { headerName: "Cantidad", field: "cant" },
        { headerName: "Numero de Orden", field: 'orderId' },
        { headerName: "Motivo Anulación", field: 'statusNota' },
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => setLocal(null)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
                    <div className='btn btn-light btn-sm'>{local.nombre}</div>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1' />Exportar
                    </Button>
                </div>

                <div className='mb-1 d-flex flex-row mw-100'>
                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='start' required />
                    </Form.Group>

                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='end' required />
                    </Form.Group>

                    <Button block size='sm' variant='outline-dark' type='submit'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1' />Reporte
                    </Button>
                </div>

            </Form>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={rowData}
                    onGridReady={params => {
                        setGridApi(params.api)
                        setGridColumnApi(params.columnApi)
                    }}
                />
            </div>
        </div>
    )

}