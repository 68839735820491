import React from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default function Loader ({ loading, opacity, loaderColor, displayLoader, background }) {
	if (loading) {
		return (
			<div className="loading-parcial-background" style={{ background: background || `rgba(236, 240, 241, ${opacity})` }}>
				{
					displayLoader ?
						<div className="loading-bar" >
							<div className="loading-circle-1" style={{ background: loaderColor }} />
							<div className="loading-circle-2" style={{ background: loaderColor }} />
						</div> : null
				}
			</div>
		)
	}

	return null
}

Loader.defaultProps = {
	loading: false,
	loaderColor: '#3498db',
	opacity: 0.7,
	displayLoader: true
}

Loader.propTypes = {
	loading: PropTypes.bool,
	opacity: PropTypes.number,
	loaderColor: PropTypes.string,
	displayLoader: PropTypes.bool
}

