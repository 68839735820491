import React, { useState } from 'react'
import Axios from 'axios'
import { Link, useParams, useHistory } from "react-router-dom"
import useSWR from 'swr'
import { chunk } from 'lodash'
import { between } from '../../lib/helperFunctions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBars, faBan, faDollarSign, faEdit, faHamburger, faPercent, faInfoCircle, faPrint } from '@fortawesome/free-solid-svg-icons'
import { faBluetooth } from '@fortawesome/free-brands-svg-icons'

import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'

import MemoButton from '../../components/MemoModal'
import CustomToggle from '../../components/CustomToggle'
import AnularButton from '../../components/AnularButton'
import ItemList from '../../components/ItemList'
import Carta from '../../components/Carta'
import StatusBadge from '../../components/StatusBadge'
import PrintedBadge from '../../components/PrintedBadge'
import CobrarButton from '../../components/Callcenter/CobrarButton'
import DespacharButton from '../../components/DespacharButton'

import { usePrinter } from '../../Hook/PrinterContext'
import { useDialog } from '../../Hook/DialogContext'
import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    let history = useHistory()
    const { cliente, local, id } = useParams()

    const [ items, setItems ] = useState([])
    const [ showCarta, setShowCarta ] = useState(false)

    const { getDialogResponse } = useDialog()
    const { hasPermission } = useConfig()
    const { print } = usePrinter()

    const locales = JSON.parse(sessionStorage.locales)
    const localDef = locales.find(localDef => localDef.id === local)

    const { data, mutate } = useSWR(`/api/orden/delivery/get?cliente=${cliente}&local=${local}&id=${id}`, {
        onErrorRetry: (err) => {
            getDialogResponse({
                title: 'Error!',
                message: err.message,
                buttons: [
                    {
                        label: 'Aceptar'
                    }
                ]
            })
        }
    })
 
    //Local Only
    const addItem = item => setItems([...items, {
        ...item,
        orderId: data?.id
    }])

    //Local Only
    const removeItem = index => {
        let array = [...items]
        array.splice(index, 1)
        setItems(array)
    } 

    const updateOrden = async obj => {
        mutate({
            ...data,
            ...obj
        }, false)

        await Axios.post(`/api/orden/delivery/update?cliente=${cliente}&local=${local}&id=${id}`, obj)

        mutate()
    }

    const terminar = async () => {
        await updateOrden(
            {
                status: 'terminado'
            }
        )
        history.push('/callcenter')
    }

    const DropdownComponent = () => (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle}variant='success' size="lg">
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* Anular */}
                <AnularButton actionToAuth='anulacion-orden' onAnular={async motivo => {
                    await updateOrden(
                        {
                            status: 'anulado',
                            status_nota: motivo
                        }
                    )
                    history.push('/salon')
                }} as={Dropdown.Item}>
                    <FontAwesomeIcon icon={faBan} className='mr-1' />Anular
                </AnularButton>

                <Dropdown.Item onClick={() => {
                    print('delivery', data)

                    updateOrden(
                        {
                            printed: '[timestamp]'
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faPrint} className='mr-1' />Imprimir Ticket de Delivery
                </Dropdown.Item>

                {/* RawBT */}
                <Dropdown.Item onClick={() => {
                    let dt = new Date(data.apertura)
                    dt.setHours( dt.getHours() + 1 );
                    let est = dt.toLocaleString()
                    let total = (data.items.reduce((acc, item) => acc + (item.precio * item.cant), 0)) + parseFloat(data.delivery);
                    
                    const esc = '\x1B'; //ESC byte in hex notation
                    const newLine = '\x0A'; //LF byte in hex notation
                    let cmds = esc + "@"; //Initializes the printer (ESC @)
                    cmds += esc + '!\x38' //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                    cmds += esc + 'a\x01' //Align Center
                    cmds += 'PEDIDO';
                    cmds += newLine
                    cmds += `${data.id}`;
                    cmds += newLine;
                    cmds += esc + '!\x00' + esc + '\x61\x00'; //Character font A selected (ESC ! 0)
                    cmds += '================================';
                    cmds += newLine;
                    cmds += `HORA: ${new Date().toLocaleString()}`;
                    cmds += newLine;
                    cmds += `APERTURA: ${new Date(data.apertura).toLocaleString()}`;
                    cmds += newLine;
                    cmds += `EST: ${est}`;
                    cmds += newLine;
                    cmds += `CLIENTE: ${data.nombre}`;
                    cmds += newLine;
                    cmds += `TELEFONO: ${data.telefono}`;
                    cmds += newLine;
                    cmds += `DIR: ${data.direccion}`;
                    cmds += newLine;
                    cmds += `REF: ${data.referencia || ''}`;
                    cmds += newLine;
                    cmds += '================================';
                    cmds += newLine;
                    cmds += `MEMO: ${data.memo || ''}`;
                    cmds += newLine;
                    cmds += '================================';
                    data.items.forEach(i => {
                        cmds += newLine;
                        let precio = ` S/.${(i.cant*i.precio).toFixed(2)}`
                        let cant = `${i.cant}x `
                        if ((32 - precio.length - cant.length) >= i.name.length) {
                            cmds += cant + i.name + ' '.repeat(32 - precio.length - cant.length - i.name.length) + precio;
                        } else {
                            const chunks = chunk(i.name.split(''), (32 - precio.length - cant.length))
                            cmds += cant + chunks.shift().join('') + precio;
                            while (chunks.length) {
                                cmds += newLine;
                                cmds += ' '.repeat(cant.length) + chunks.shift().join('')
                            }
                        }
                    })
                    cmds += newLine;
                    cmds += '================================';
                    cmds += newLine;
                    cmds += 'CARGO POR DELIVERY:' + ' '.repeat(32 - 'CARGO POR DELIVERY:'.length - `S/.${data.delivery}`.length) + `S/.${data.delivery}`;
                    cmds += newLine;
                    cmds += 'TOTAL A COBRAR:' + ' '.repeat(32 - 'TOTAL A COBRAR:'.length - `S/.${total.toFixed(2)}`.length) + `S/.${total.toFixed(2)}`;
                    cmds += newLine;
                    cmds += 'FORMA DE PAGO:' + ' '.repeat(32 - 'FORMA DE PAGO:'.length - (data.forma_de_pago || '').length) + (data.forma_de_pago || '');
                    
                    var S = "#Intent;scheme=rawbt;"
                    var P =  "package=ru.a402d.rawbtprinter;end;"
                    var textEncoded = encodeURI(cmds)
                    window.location.href="intent:"+textEncoded+S+P
                    
                    updateOrden(
                        {
                            printed: '[timestamp]'
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faBluetooth} className='mr-1'/>Imprimir con <b>RawBT</b>
                </Dropdown.Item>
                
                {/* Delivery Amount */}
                <Dropdown.Item onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Modificar Monto por Delivery',
                        message: 'Ingresa el nuevo monto.',
                        input: true,
                        buttons: [
                            {
                                label: 'Cancelar',
                                variant: 'secondary'
                            },
                            {
                                label: 'Confirmar',
                                resolveInput: true,
                                disableNoInput: true
                            }
                        ]
                    })

                    if ( !response ) return
                    if ( isNaN(response) ) return getDialogResponse({
                        title: 'Error!',
                        message: 'El monto ingresado no es valido.',
                        buttons: [
                            {
                                label: 'Aceptar'
                            }
                        ]
                    })

                    updateOrden(
                        {
                            delivery: parseFloat(response)
                        }
                    )
                }}><FontAwesomeIcon icon={faDollarSign} className='mr-1'/>Modificar Monto de Delivery</Dropdown.Item>

                {/* Agregar Nota */}   
                <Dropdown.Item onClick={async () => {
                    const res = await getDialogResponse({
                       title: 'Agregar Nota',
                       textArea: true,
                       textAreaCancelButton: true,
                       textAreaDefaultValue: data?.metadata?.nota || ''
                    })
                    if (!res) return
                    updateOrden(
                    {
                        metadata: data.metadata ? { ...data.metadata, nota: res } : { nota: res }
                    }
                )
                }}><FontAwesomeIcon icon={faEdit} className='mr-1'/>Agregar Nota</Dropdown.Item>

                {/* Descuento Global */}
                <Dropdown.Item onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Modificar Descuento',
                        message: 'Ingresa el % de descuento ( 1-100 )',
                        input: true,
                        buttons: [
                            {
                                label: 'Cancelar',
                                variant: 'secondary'
                            },
                            {
                                label: 'Confirmar',
                                resolveInput: true,
                                disableNoInput: true
                            }
                        ]
                    })

                    if ( !response ) return

                    if (!between(parseInt(response), 1, 100)) {
                        return getDialogResponse({
                            title: 'Error!',
                            message: 'El monto ingresado no es valido.',
                            buttons: [
                                {
                                    label: 'Aceptar'
                                }
                            ]
                        })
                    }
                    updateOrden(
                        {
                            descuento: (response/100).toFixed(2)
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faPercent} className='mr-1'/>Aplicar Descuento
                </Dropdown.Item>
                
                <Dropdown.Item onClick={() => getDialogResponse({
                    message: <pre className='mb-0'>{ JSON.stringify(data.metadata, undefined ,4) }</pre>,
                    textNotCentered: true,
                    dismissible: true
                })}>
                    <FontAwesomeIcon icon={faInfoCircle} className='mr-1'/>Ver Metadatos
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
    
    return (
        <>
        <div className="mb-1 d-flex d-lg-none justify-content-between">
            <Link to="/callcenter"><Button size='lg' variant='success'><FontAwesomeIcon icon={faArrowLeft}/></Button></Link>
                
            <Button size='lg' variant={showCarta ? 'light' : 'outline-light'} onClick={() => setShowCarta(!showCarta)}><FontAwesomeIcon icon={faHamburger}/></Button>

            <DropdownComponent/>   
        </div>

        <Row className="h-100 overflow-hidden">
            <Col className={`p-0 h-100 ${showCarta ? 'd-none' : 'd-flex'} d-lg-flex flex-column`}>

                <div className="mb-1 d-none d-lg-flex justify-content-between">
                    <Link to="/callcenter"><Button size='lg' variant='success'><FontAwesomeIcon icon={faArrowLeft}/></Button></Link>
                    
                    <div className='d-flex align-items-start my-auto mbt-2'>
                        <Badge style={{ lineHeight: '24px' }} variant='light'>
                            {JSON.parse(sessionStorage.locales).filter(_local => _local.id === local)[0].nombre.toUpperCase()}
                        </Badge>

                        <StatusBadge id={id} status={data?.status}/>   

                        {data?.cobrado && <Badge variant='dark'>
                                <FontAwesomeIcon className='text-success mx-1' icon={faDollarSign} size='2x'/>
                        </Badge>}

                        {data?.printed && <PrintedBadge time={data?.printed}/>}
                    </div>
                    
                    <DropdownComponent/>
                </div>
                
                <div className='d-flex d-lg-none justify-content-center mbt-1 mb-1'>
                    <Badge style={{ lineHeight: '24px' }} variant='light'>
                        {JSON.parse(sessionStorage.locales).filter(_local => _local.id === local)[0].nombre.toUpperCase()}
                    </Badge>
                    <StatusBadge id={id} status={data?.status}/>   
                    {data?.pagado && <Badge variant='dark'>
                            <FontAwesomeIcon className='text-success mx-1' icon={faDollarSign} size='2x'/>
                    </Badge>}
                    {data?.printed && <PrintedBadge time={data?.printed}/>}
                </div>

                <Card className='d-flex justify-content-around text-center mb-1 border-0 py-1'>
                    <span><b>Nombre: </b>{data?.nombre}</span>
                    <span><b>Apertura: </b>{new Date(data?.apertura).toLocaleString()}</span>
                    <span className="text-danger font-weight-bold h5 m-0 my-auto">{data?.memo}</span>
                </Card>

                <ItemList
                    items={items} // Items que no se han incluido aun
                    comandados={data?.items || []} // Items que ya se incluyeron en la orden
                    delivery={data?.delivery} // Monto de delivery
                    descuento={data?.descuento}
                    onRemoveItem={removeItem}
                    onDeleteItem={itemIndex => {
                        let array = [...data?.items]
                        array.splice(itemIndex, 1)
                
                        updateOrden({items: array})
                    }}
                    onEditItem={(item, index) => {
                        items[index] = item
                        setItems([...items])
                    }}
                />

                <div className="mt-1 mbt-1 d-flex justify-content-center flex-wrap">
                    <MemoButton
                        memo={data?.memo}
                        onUpdate={newMemo => updateOrden({memo: newMemo})}
                    />

                    <DespacharButton
                        id={id}
                        local={local}
                        update={updateOrden}
                        hidden={!data?.items?.length}
                        orden={data}
                    />

                    <Button className='border-0' size='lg' style={{ backgroundColor: '#8332AC' }} hidden={!data?.cobrado} onClick={async () => {
                        const confirmation = await getDialogResponse({
                            title: 'Estas seguro que quieres terminar la orden?',
                            message: 'este proceso es irreversible.',
                            buttons: [
                                {label: 'Si', variant: 'danger'},
                                {label: 'NO', variant: 'secondary'}
                            ]
                        })

                        if (confirmation !== 0) return

                        terminar()
                    }}>
                        Terminar
                    </Button>

                    <Button variant="primary" size="lg" onClick={() => {
                        updateOrden({
                            status: 'activo',
                            enviado: '[timestamp]',
                            items: data?.items ? [...data?.items, ...items] : [...items]
                        })
                        setItems([])
                        Axios.get(`/api/orden/delivery/notifyLocal?local=${local}&id=${id}`)
                    }} hidden={data?.status !== 'enviar' || (!items.length && !data?.items?.length)}>
                        Enviar
                    </Button>

                    <Button variant="primary" size="lg" onClick={() => {
                        updateOrden({
                            items: data?.items ? [...data?.items, ...items] : [...items]
                        })
                        setItems([])
                    }} hidden={data?.status === 'enviar' || !items.length}>
                        Reenviar
                    </Button>

                    <CobrarButton hidden={data?.pagado} metodo={data?.forma_de_pago} onUpdate={metodo => {
                        updateOrden({
                            cobrado: '[timestamp]',
                            metadata: {...data?.metadata, formaDePago: metodo}
                        })
                    }}/>
                </div>
            </Col>

            {
                hasPermission('callcenter.carta') && 
                <Col className={`p-0 h-100 ${showCarta ? 'd-flex' : 'd-none'} d-lg-flex ml-lg-1`} xs={12} lg={6} xl={8}>
                    <Carta onAdd={addItem} disabled={data?.status !== 'activo' && data?.status !== 'enviar'} cartaId={localDef.carta} unico={true}/>
                </Col>
            }
        </Row>
        </>
    )
}