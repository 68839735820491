import React from 'react'
import Button from 'react-bootstrap/Button'

const CustomToggle = React.forwardRef(({ children, onClick, size, variant, margin }, ref) => (
    <Button
        variant={variant}
        size={size}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
));
export default CustomToggle