import React from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'

import Button from 'react-bootstrap/Button'

import Salon from './Salon'
import Delivery from './Delivery'
import Productos from './Productos'
import Documentos from './Documentos'
import Web from './Web.jsx'
import DeliverySimple from './DeliverySimple'
import Insumos from './Insumos'
import Impresiones from './Impresiones'
import Turnos from './Turnos'
import Anulados from './ProductosAnulados'

export default () => {
    return (
        <div className='flex-grow-1 d-flex flex-column'>
            Productos

            <div className='d-flex mbt-1 mb-1 mw-100 overflow-x-auto'>
                <Button size='sm' as={Link} to='/reportes/salon' variant={useRouteMatch('/reportes/salon') ? 'dark' : 'outline-dark'} >Salon</Button>
                <Button size='sm' as={Link} to='/reportes/turnos' variant={useRouteMatch('/reportes/turnos') ? 'dark' : 'outline-dark'} >Turnos</Button>
                <Button size='sm' as={Link} to='/reportes/delivery' variant={useRouteMatch('/reportes/delivery') ? 'dark' : 'outline-dark'} >Delivery</Button>
                <Button size='sm' as={Link} to='/reportes/deliverySimple' variant={useRouteMatch('/reportes/deliverySimple') ? 'dark' : 'outline-dark'} >Delivery Simple</Button>
                <Button size='sm' as={Link} to='/reportes/productos' variant={useRouteMatch('/reportes/productos') ? 'dark' : 'outline-dark'} >Productos</Button>
                {/* <Button size='sm' as={Link} to='/reportes/insumos' variant={useRouteMatch('/reportes/insumos') ?  'dark' : 'outline-dark'} >Insumos</Button> */}
                <Button size='sm' as={Link} to='/reportes/impresiones' variant={useRouteMatch('/reportes/impresiones') ? 'dark' : 'outline-dark'} >Impresiones</Button>
                {/* <Button size='sm' as={Link} to='/reportes/documentos' variant={useRouteMatch('/reportes/documentos') ?  'dark' : 'outline-dark'} >Documentos</Button> */}
                {/* <Button size='sm' as={Link} to='/reportes/web' variant={useRouteMatch('/reportes/web') ?  'dark' : 'outline-dark'} >Web</Button> */}
                <Button size='sm' as={Link} to='/reportes/anulados' variant={useRouteMatch('/reportes/salon') ? 'dark' : 'outline-dark'} >Anulados</Button>
            </div>

            <Switch>
                <Route exact path='/reportes/salon' component={Salon} />
                <Route exact path='/reportes/turnos' component={Turnos} />
                <Route exact path='/reportes/delivery' component={Delivery} />
                <Route exact path='/reportes/deliverySimple' component={DeliverySimple} />
                <Route exact path='/reportes/productos' component={Productos} />
                <Route exact path='/reportes/insumos' component={Insumos} />
                <Route exact path='/reportes/documentos' component={Documentos} />
                <Route exact path='/reportes/web' component={Web} />
                <Route exact path='/reportes/impresiones' component={Impresiones} />
                <Route exact path='/reportes/anulados' component={Anulados} />
            </Switch>
        </div>
    )
}