export const mapToRangeInteger = function(int: number, in_min: number , in_max: number, out_min: number, out_max: number) {
    return ((int - in_min) * (out_max - out_min) / (in_max - in_min) + out_min).toFixed(0)
}

export const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  var R = 6371; // Earth radius in km
  var dLat = degToRad(lat2-lat1);
  var dLon = degToRad(lon2-lon1);
  lat1 = degToRad(lat1);
  lat2 = degToRad(lat2);

  var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c;
  return d;
}

// Converts numeric degrees to radians
export function degToRad(Value: number): number 
{
    return Value * Math.PI / 180;
}

export function between(x: number, min: number , max: number ): boolean  {
  return x >= min && x <= max;
}

export const asyncFilter = async (arr: any, predicate: (dep: any) => boolean) => {
	const results = await Promise.all(arr.map(predicate));

	return arr.filter((_v: any, index: number) => results[index]);
}