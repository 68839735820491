import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { FuegoProvider } from '@nandorojo/swr-firestore'
import { Fuego } from './lib/fuego'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/es'

//BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.min.css'

//CSS from AgGrid
import './assets/sass/ag-theme-mixin.scss'

import './assets/sass/index.scss'

import { PrivateRoute } from './components/PrivateRoute'

import Login from './views/Login'
import App from './App'

import firebaseConfig from './firebaseConfig'

const fuego = new Fuego(firebaseConfig)

export default () => {
    useEffect(() => {
        moment.locale('es')

        window.addEventListener("keydown", disableF5)

        return () =>  window.removeEventListener("keydown", disableF5)
    }, [])

    const disableF5 = (e) => { if ((e.which || e.keyCode) === 116) e.preventDefault() }

    return (
        <SWRConfig 
            value={{
                fetcher: url => axios.get(url).then(res => res.data)
            }}
        >
            <FuegoProvider fuego={fuego}>
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        <PrivateRoute path="/" component={App}/>
                    </Switch>
                </Router>
            </FuegoProvider>
        </SWRConfig>
    )
}