export default function naturalSortByProp(array, prop) {
    return naturalSort(array.map(o => o[prop]))
    .map(_prop => array.find(o => o[prop] === _prop))
}

function naturalSort(ary, fullNumbers) {
    var re = fullNumbers ? /[\d\.\-]+|\D+/g : /\d+|\D+/g;

    // Perform a Schwartzian transform, breaking each entry into pieces first
    for (var i = ary.length; i--;)
        ary[i] = [ary[i]].concat((ary[i] + "").match(re).map(function (s) {
            return isNaN(s) ? [s, false, s] : [s * 1, true, s];
        }));

    // Perform a cascading sort down the pieces
    ary.sort(function (a, b) {
        var al = a.length, bl = b.length, e = al > bl ? al : bl;
        for (var i = 1; i < e; ++i) {
            // Sort "a" before "a1"
            if (i >= al) return -1; else if (i >= bl) return 1;
            else if (a[i][0] !== b[i][0])
                return (a[i][1] && b[i][1]) ?        // Are we comparing numbers?
                    (a[i][0] - b[i][0]) :         // Then diff them.
                    (a[i][2] < b[i][2]) ? -1 : 1; // Otherwise, lexicographic sort
        }
        return 0;
    });

    // Restore the original values into the array
    for (var i = ary.length; i--;) ary[i] = ary[i][0];
    return ary;
}