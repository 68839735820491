import React from 'react'
import { Switch, Route } from 'react-router-dom'
import axios from 'axios'

import Routes from './Routes'

import PrinterProvider from './Hook/PrinterContext'
import DialogProvider from './Hook/DialogContext'
import WebOrdersContext from './Hook/WebOrdersContext'
import ConfigProvider from './Hook/ConfigContext'
import OrdersProvider from './Hook/OrdersContext'
import InfoCardsProvider from './Hook/InfoCardsContext'

export default () => {

    const permisos = JSON.parse(sessionStorage.permisos)

    axios.defaults.headers.common['Authorization'] = sessionStorage.jwt

    return (
        <DialogProvider>
            <ConfigProvider>
                <InfoCardsProvider>
                    <PrinterProvider>
                        <WebOrdersContext>
                            <OrdersProvider>
                                <Switch>
                                    {
                                        Routes.filter(route => (permisos.includes(route.perm))).map(route => (
                                            <Route key={route.name} exact={route.exact} path={route.path}
                                                render={(props) => <route.template><route.component {...props} /></route.template>}
                                            />
                                        ))
                                    }
                                </Switch>
                            </OrdersProvider>
                        </WebOrdersContext>
                    </PrinterProvider>
                </InfoCardsProvider>
            </ConfigProvider>
        </DialogProvider>
    )
}
