import React, { useState } from 'react'
import Axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useInfoCards } from '../../Hook/InfoCardsContext'

export default () => {
    const [showModal, setShowModal] = useState()

    const { show } = useInfoCards()

    const locales = JSON.parse(sessionStorage.locales)

    const onSubmit = async e => {
        e.preventDefault()

        const form = e.target

        const localDef = locales.find(local => local.id === form.elements.local.value)

        let string = ''

        switch (form.elements.tipo.value) {
            case 'salon':
                string = `/api/reportes/getSalon?cliente=${localDef.cliente_id}&local=${form.elements.local.value}&id=${form.elements.id.value}`
                const { data } = await Axios.get(string)
                setShowModal(false)
                show({ ...data, objectType: 'salon' })
                break;
            case 'delivery':
                string = `/api/orden/delivery/get?cliente=${localDef.cliente_id}&local=${form.elements.local.value}&id=${form.elements.id.value}`
                const { data: deliveryData } = await Axios.get(string)
                setShowModal(false)
                show({ ...deliveryData, objectType: 'delivery' })
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Button size="lg" variant='warning' onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faSearch} />
            </Button>

            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar Orden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Label>Local:</Form.Label>
                            <Form.Control as='select' name='local'>
                                <option value='' hidden>Selecionar...</option>
                                {locales?.map(l => (
                                    <option key={l.id} value={l.id}>{l.nombre}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Row className="mb-3">
                            <Col>
                                <Form.Control as='select' name='tipo'>
                                    <option>delivery</option>
                                    <option>salon</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>#</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control name='id' />
                                </InputGroup>
                            </Col>
                        </Form.Row>
                        <Button variant="primary" type='submit'><FontAwesomeIcon className='mr-2' icon={faSearch} />Buscar</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
