import React from 'react'

import Modal from 'react-bootstrap/Modal'

export default ({ data }) => {
    return (
        <Modal.Body>
            <iframe src={`https://escp-to-img-escl9o8c3-comandapp-pe.vercel.app/?string=${encodeURIComponent(data.escp)}`} width='100%' height='600px'/>
        </Modal.Body>
    )
}