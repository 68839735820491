import { faCashRegister, faCog, faHamburger, faHome, faHotel, faMotorcycle, faPhone, faShoppingBasket, faTable } from '@fortawesome/free-solid-svg-icons'
import { faInternetExplorer } from '@fortawesome/free-brands-svg-icons'

//Templates
import Main from './Templates/Main'
import Empty from './Templates/Empty'

import Dash from './views/Dash'

import Salon from './Modules/Salon'
import Settings from './Modules/Settings'
import Delivery from './Modules/Delivery'
import Carta from './Modules/Carta'
import Reportes from './Modules/Reportes'
import ReportesHoy from './Modules/ReportesHoy'
import Callcenter from './Modules/Callcenter'
import Webview from './Modules/Webview'
import Caja from './Modules/Caja'
import Pos from './Modules/Pos'

export default [
    {
        path: "/",
        exact: true,
        name: "Dashboard",
        acceso: ['pos', 'callcenter'],
        perm: '',
        icon: faHome,
        template: Main,
        component: Dash,
    },
    {
        path: "/salon",
        exact: false,
        name: "Salon",
        acceso: ['pos'],
        perm: 'MODULO-SALON',
        icon: faHotel,
        template: Empty,
        component: Salon,
    },
    {
        path: "/delivery",
        exact: false,
        name: "Delivery",
        acceso: ['pos'],
        perm: 'MODULO-DELIVERY',
        icon: faMotorcycle,
        template: Empty,
        component: Delivery,
    },
    {
        path: "/pos",
        exact: true,
        name: "Pos",
        perm: 'MODULO-POS',
        icon: faShoppingBasket,
        template: Main,
        component: Pos,
    },
    {
        path: "/callcenter",
        exact: false,
        name: "Callcenter",
        acceso: ['callcenter'],
        perm: 'MODULO-CALLCENTER',
        icon: faPhone,
        template: Empty,
        component: Callcenter,
    },
    {
        path: "/carta",
        exact: false,
        name: "Carta",
        acceso: ['pos', 'callcenter'],
        perm: 'MODULO-CARTA',
        icon: faHamburger,
        template: Main,
        component: Carta,
    }, 
    {
        path: "/reportes",
        exact: false,
        name: "Reportes",
        acceso: ['pos', 'callcenter'],
        perm: 'MODULO-REPORTES',
        icon: faTable,
        template: Main,
        component: Reportes,
    }, 
    {
        path: "/reporteshoy",
        exact: false,
        name: "ReportesHoy",
        acceso: ['pos', 'callcenter'],
        perm: 'MODULO-REPORTESHOY',
        icon: faTable,
        template: Main,
        component: ReportesHoy,
    }, 
    {
        path: "/webview",
        exact: false,
        name: "Webview",
        acceso: ['pos', 'callcenter'],
        perm: 'MODULO-WEBVIEW',
        icon: faInternetExplorer,
        template: Main,
        component: Webview,
    },
    {
        path: "/settings",
        exact: true,
        name: "Settings",
        acceso: ['pos', 'callcenter'],
        perm: '',
        icon: faCog,
        template: Main,
        component: Settings,
    },
    {
        path: "/caja",
        exact: false,
        name: "Caja",
        acceso: ['pos', 'callcenter'],
        perm: 'MODULO-CAJA',
        icon: faCashRegister,
        template: Main,
        component: Caja,
    }
]
