import React, { Fragment, useState } from 'react'
import { v1 as uuidv1 } from 'uuid'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Modal, Button, Form, Col } from "react-bootstrap"

import { useConfig } from '../Hook/ConfigContext'
import moment from 'moment'

export default function PlatoUnicoModal ({onAdd, ...rest}) {
    const { config } = useConfig()

    const [show, setShow] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    const onSubmit = async e => {
        e.preventDefault()
        const form = e.target

        let obj = {}
        if (!/^\d+$/.test(form.elements.cant.value)) {
            obj.cant = 'Debe ser un número entero.'
        }
        if(!/^\d*[.]?\d{1,2}$/.test(form.elements.precio.value)) {
            obj.precio = 'Debe ser un número entero o un número con 1 o 2 decimales.'
        }

        if (Object.keys(obj).length) {
            setFormErrors(obj)
            form.elements[Object.keys(obj)[0]].focus()
            return
        }

        const plato = {
            id: moment().format('YYDDDHms'),
            tipo: 'simple',
            name: form.elements.nombre.value,
            cant: parseInt(form.elements.cant.value),
            precio: parseFloat(form.elements.precio.value),
            cocina: config?.printConfig.defaultComandaPrinter
        }

        onAdd(plato)
    }

    return (
        <Fragment>
            <Button
                variant="primary"
                onClick={e => {
                    setShow(true)
                    setTimeout(() => {
                        document.getElementById('first-input').focus()
                    }, 10)
                }}
                {...rest}
            >
                Único
            </Button>

            <Modal
                show={show}
                centered
                onHide={e => {
                    setShow(false)
                    setFormErrors({})
                }}
            >
                <Modal.Body className='p-1'>
                    <Modal.Title className='text-center mb-3'>Agregar Plato Único</Modal.Title>
                    <Form onSubmit={onSubmit}>
                            <Form.Group controlId="first-input">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control required name='nombre'/>
                            </Form.Group>
                        <Form.Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control required name='cant' isInvalid={formErrors.cant}/>
                                    <Form.Control.Feedback type='invalid'>{formErrors.cant}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Precio</Form.Label>
                                    <Form.Control required name='precio' isInvalid={formErrors.precio}/>
                                    <Form.Control.Feedback type='invalid'>{formErrors.precio}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Button variant="primary" type="submit" className='float-right'>
                            <FontAwesomeIcon icon={faPlus} className='mr-1'/>Agregar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}