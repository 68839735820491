import React, { createContext, useState, useEffect } from 'react'
import qz from 'qz-tray'
import moment from 'moment'
import { chunk, groupBy } from 'lodash'
import { useCollection, update } from '@nandorojo/swr-firestore'
import { db } from '../lib/fuego'

import { useConfig } from './ConfigContext'
import { useDialog } from './DialogContext'

import PDFTest from './pdf-test.pdf'

// SIGNATURES FOR QZ TRAY
qz.security.setCertificatePromise(function (resolve) {
    resolve(
        '-----BEGIN CERTIFICATE-----' +
        'MIID3TCCAsWgAwIBAgIUV+0+QZk5gFRdaSdOvfPA7Qi4+EYwDQYJKoZIhvcNAQEL' +
        'BQAwfjELMAkGA1UEBhMCUEUxDTALBgNVBAgMBExJTUExDTALBgNVBAcMBExJTUEx' +
        'DjAMBgNVBAoMBVFVSVBVMQ4wDAYDVQQLDAVRVUlQVTEOMAwGA1UEAwwFUVVJUFUx' +
        'ITAfBgkqhkiG9w0BCQEWEnZtaHMxOTk4QGdtYWlsLmNvbTAeFw0yNDA0MTkwMzM2' +
        'NTVaFw0yNTA0MTkwMzM2NTVaMH4xCzAJBgNVBAYTAlBFMQ0wCwYDVQQIDARMSU1B' +
        'MQ0wCwYDVQQHDARMSU1BMQ4wDAYDVQQKDAVRVUlQVTEOMAwGA1UECwwFUVVJUFUx' +
        'DjAMBgNVBAMMBVFVSVBVMSEwHwYJKoZIhvcNAQkBFhJ2bWhzMTk5OEBnbWFpbC5j' +
        'b20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC1Odo4Vfl0II5RRH4u' +
        'nJQfc1cCVnssbpRhHOY3z01JOC78sCOoxquaazXERsjOINXCHhw9+nnX+TN74MSY' +
        'ojFudrd3q4j8MlFyoYspD+77NBn226wPYDrjCOrkiNsnzpQ4lChJqudIInlrSGB3' +
        'R7DMb1+PIK76yAxAfotIEsRzjhyKAKV1ZaLtWbpecmfX4Pzfz9KJEH4jT7XflUMn' +
        'pcCZxi2yxmfJS9G2zGNbtgersRRfN8IbkIg3u48mrqVI5MqycWF65CFx/0H9+gkh' +
        'Uc9p9me3jEm46kbvHRyhREoUckmsksYOvhkye92b5OPCm52VBaUMpXjwI7jdKWGo' +
        'HtmXAgMBAAGjUzBRMB0GA1UdDgQWBBRbHq/gaeJwACIlNN7gzW92dSPZpDAfBgNV' +
        'HSMEGDAWgBRbHq/gaeJwACIlNN7gzW92dSPZpDAPBgNVHRMBAf8EBTADAQH/MA0G' +
        'CSqGSIb3DQEBCwUAA4IBAQAgA1QOvCIYQq78tUXOB5m019j7q+8P9HHxJ3cfmuDj' +
        'RxmrkzUSKLO2pXqkinPdn88Fgbtmix0TIpejyAqnZSuj1p40IJuLpRWJQOo5SOIL' +
        'Cd41ghWCqqf2AGuSmI7Oatl9VuS+J1mNPWAgGZQiphzPVMkxAN4uUVBk76atylZP' +
        '9piw6/nh8eFomKw64N28d6TorDvVkX4QkGk8wrRdgzQhcc7s9t5WVRmokVrQyGmw' +
        'spEOSOz02brXCALtkw1fgk/i1FesxolRro7ncCokTrsqwrGK3mI5yVfBsBoiIrum' +
        'zkd/IVU0EidtMxML05IcZAAKH9xtVsWXZ6pyCA0CNSv6' +
        '-----END CERTIFICATE-----'
    )
})
qz.security.setSignatureAlgorithm("SHA512") // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
    return function (resolve) {
        var crypto = require('crypto')

        const key =
            '-----BEGIN PRIVATE KEY-----' +
    'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC1Odo4Vfl0II5R' +
    'RH4unJQfc1cCVnssbpRhHOY3z01JOC78sCOoxquaazXERsjOINXCHhw9+nnX+TN7' +
    '4MSYojFudrd3q4j8MlFyoYspD+77NBn226wPYDrjCOrkiNsnzpQ4lChJqudIInlr' +
    'SGB3R7DMb1+PIK76yAxAfotIEsRzjhyKAKV1ZaLtWbpecmfX4Pzfz9KJEH4jT7Xf' +
    'lUMnpcCZxi2yxmfJS9G2zGNbtgersRRfN8IbkIg3u48mrqVI5MqycWF65CFx/0H9' +
    '+gkhUc9p9me3jEm46kbvHRyhREoUckmsksYOvhkye92b5OPCm52VBaUMpXjwI7jd' +
    'KWGoHtmXAgMBAAECggEABfOkx2LeHW069KT44T5X3ayOYqTvyKbHuA9t6XlPws7V' +
    '5DXuBeCjHsrUlOH06FLLEmP7biGoF2q3a83BnHhUgpni2UrzOxfKoFF5M4Zdb+Hn' +
    'Kbn52Ettw6pgTynGIMmNCUqnBBGueipVAyv5AbyDVu4gHPWS1D5Lq2E8i8e/V0LO' +
    'R5jTg2q0zH4XeJIM5jBU/PHU35euzyqZU/oQlQ0eHPrhLuCew2vSk/SjU5Dv3l0O' +
    '/hi12D4s/zllg7e4JaqRRNuGi7T+71QWu7Mn9pNazo4zYG7Bzfdd9Va1lW5Bex5H' +
    'sjXnKw8j2WouB9eC394U6d9lt82cO4SHqf+FTl6QeQKBgQD1fjGakurVve46epLE' +
    'Id6zWknrX2Cv402Y1MArnmIetJ4wUG5fnR7WjD1Mk4QMEN1l03B6UJAj9r25WCt8' +
    'hI+MSyD950NwE0EbyBIvVeIHKWx1N6PTli3RRHH8TQDsG3BemSP/OkL4ofrNX7ks' +
    'PU2LGRnVBr112DC75Mcuc0gvawKBgQC8+4BUjzHxy5og77SlYyi5QNYhWot7IOBk' +
    '+Pv1bRJAoTPI//azrhux9v4G8ElcyII+kJJHj4dcWzDkTcpruWykpFrjF9NJBUHF' +
    'BYKNqjbiEqqf1v8SVnR4R1pVy9FQQAD9It0Hf6O8jHTrCy6a7g6pyDRV91EcnGXn' +
    'JCvjIftlhQKBgQDPzVFJycEUyqAESks/0HfFk9DsdyDN4feGPdipU3k1Cqi4tuym' +
    '7MX00GL3Fgt5dzEhh3g3YUiR2MPCRzsQPNmR5Op5YjA4h2Pek12pSeZ5L3xKQJ3h' +
    'dcc00940ti3orlVRDgplejjEY8B75eJF62JfMxElvH3Vafneoopj7FuXHwKBgQC7' +
    'CTcjeW1234k1m8fv/8moP3EuJYzyWcF8bagAaOitql20SxCBGIo0gj075MjW2WN+' +
    'RFtiE71mPKKQ8iSqpSPPdxK0uznThS5ormFdj98ttEfGVmSwY3x2JjhTvZr1j8Lq' +
    'xXx4FW2aCHEWx0Ri9cTxaEHT6ygN5wQ71WLSMym6eQKBgQC+AGHjPES1V6dlS7lR' +
    'WwJlzAHEX5/bGr3qr4hr/wVEmPDCsiaBUlwZxHzMEGz+RiinVk2U+/ODRXKkMFSP' +
    'm1K98cq09xD7KiY7SjltbiHsymnQMpcxAHVMq9e0BoAxaJaQYC6PBUFzYmV3kCX/' +
    'D/uN0srmB2nZ4+o5sGOvEpGjAg==' +
    '-----END PRIVATE KEY-----'

        var sign = crypto.createSign('RSA-SHA512'); // Use "SHA1" for QZ Tray 2.0 and older

        sign.update(toSign);
        var signature = sign.sign(key, 'base64')

        resolve(signature)
    };
})

const PrinterContext = createContext()

export default ({ children }) => {
    const { config } = useConfig()
    const { showNotification } = useDialog()

    const { data: printOrders } = useCollection(`printOrders/${sessionStorage.local_id}/printOrders`, { where: ['done', '==', false], listen: true })

    const [isConnected, setIsConnected] = useState()

    //DEBUG
    useEffect(() => {
        if (localStorage.printServer !== 'recieve') return
        printOrders?.length && printOrders.forEach(printOrder => {
            console.log('checking, ', printOrder.id)
            console.log(localStorage[printOrder.id + '-done'] ? 'not printed' : 'printed')

            if (!localStorage[printOrder.id + '-done']) {
                if (printOrder.type === 'comandas') {
                    console.log('skip', printOrder?.data?.items[0]?.cocina)
                    const skipKitchen = localStorage['skip' + printOrder?.data?.items[0]?.cocina]
                    console.log(skipKitchen)
                    if (skipKitchen) return
                }

                print(printOrder.type, printOrder.data, true)
                showNotification({
                    message: 'printed from server',
                    variant: 'success'
                })
            }

            localStorage[printOrder.id + '-done'] = true

            update(`printOrders/${sessionStorage.local_id}/printOrders/${printOrder.id}`, {
                done: true,
            })
        })
    }, [printOrders])
    //END DEBUG

    // HANDLE WEBSOCKET CONNECTION
    useEffect(() => {
        qz.websocket.setClosedCallbacks(() => {
            setIsConnected(false)
        })
        connect()
    }, [isConnected])

    const connect = async () => {
        try {
            if (!qz.websocket.isActive()) {
                await qz.websocket.connect()
                setIsConnected(true)
            }
            return true
        } catch (error) {
            return false
        }
    }

    const print = async (type, data, printServerBypass) => {
        try {
            const printServerOverrideOptions = JSON.parse(localStorage.printServerOverrideOptions || '{}')
            let sendToPrintServer = printServerOverrideOptions[type] ?? localStorage.printServer === 'send' ? true : false

            if (printServerBypass) {
                sendToPrintServer = false
            }

            const collectionRef = db.collection(`printOrders/${sessionStorage.local_id}/printOrders`)

            if (type === 'comandas') {
                const groups = data.items.reduce((acc, item) => {
                    if (item.cocina === 'undefined' || item.cocina === 'null' || !item.cocina) {
                        if (acc[config?.printConfig.defaultComandaPrinter]) {
                            acc[config?.printConfig.defaultComandaPrinter].push(item)
                        } else {
                            acc[config?.printConfig.defaultComandaPrinter] = [item]
                        }
                        return acc
                    }
                    const cocina = item.cocina.split(',')
                    cocina.forEach(cocina => {
                        if (acc[cocina]) {
                            acc[cocina].push(item)
                        } else {
                            acc[cocina] = [item]
                        }
                    })
                    return acc
                }, {})

                Object.keys(groups).forEach(async (group, index) => {
                    const { id } = await collectionRef.add({
                        created: new Date(),
                        done: !sendToPrintServer,
                        type: type,
                        data: {
                            ...data,
                            items: groups[group]
                        },
                        username: sessionStorage.username,
                        mesa: data.id || ''
                    })

                    if (sendToPrintServer) {
                        showNotification({
                            message: 'Se envio la impresion al servidor.',
                            variant: 'success'
                        })
                        return
                    }

                    if (!isConnected) {
                        const connectionSuccessfull = await connect()
                        if (!connectionSuccessfull) throw new Error('No se pudo establecer la coneccion con el servidor de impresion QZTRAY')
                    }

                    printComandas({ ...data, items: groups[group], qrData: id }, config?.printConfig?.formats?.comanda, group, collectionRef)
                })
            } else {
                const { id } = await collectionRef.add({
                    created: new Date(),
                    done: !sendToPrintServer,
                    type: type,
                    data: {
                        ...data,
                    },
                    username: sessionStorage.username,
                    mesa: data.id || ''
                })

                if (sendToPrintServer) {
                    showNotification({
                        message: 'Se envio la impresion al servidor.',
                        variant: 'success'
                    })
                    return
                }

                if (!isConnected) {
                    const connectionSuccessfull = await connect()
                    if (!connectionSuccessfull) throw new Error('No se pudo establecer la coneccion con el servidor de impresion QZTRAY')
                }

                switch (type) {
                    case 'test':
                        printTest()
                        break;
                    case 'testPDF':
                        printTestPdf()
                        break;
                    case 'precuenta':
                        printPrecuenta({ ...data, firebaseId: id }, collectionRef)
                        break;
                    case 'interno':
                        printInterno({ ...data, firebaseId: id }, collectionRef)
                        break;
                    case 'delivery':
                        printDelivery({ ...data, firebaseId: id }, collectionRef)
                        break;
                    case 'voucher':
                        printVoucher(data)
                        break;
                    case 'comandas':
                        printComandas({ ...data, qrData: id }, config?.printConfig?.formats?.comanda, collectionRef)
                        break;
                    case 'detalleDeTurno':
                        printDetalleTurno(data)
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    //TESTING
    const printTest = () => {
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig.defaultPrinter))

        const PrinterConfig = qz.configs.create(printer.def)

        let comanda = new EscPos(printer.width)
        comanda.addRaw('\x1B!\x08')
        comanda.align('center')
        comanda.addRaw('Esta es una prueba.')

        qz.print(
            PrinterConfig,
            [
                { data: comanda.string },
                { data: '\x0A\x0A\x0A\x0A\x0A\x1B\x69\x43' }
            ],
        ).then(() => showNotification({
            message: 'Se envio correctamente la impresion de prueba.',
            variant: 'success'
        }))
    }

    const printTestPdf = () => {
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig?.defaultPrinter))

        const PrinterConfig = qz.configs.create(printer.def)

        qz.print(
            PrinterConfig,
            [
                {
                    type: 'pixel',
                    format: 'pdf',
                    flavor: 'file',
                    data: PDFTest
                }
            ],
        ).then(() => showNotification({
            message: 'Se envio correctamente la impresion del archivo PDF de prueba.',
            variant: 'success'
        }))
    }
    //END TESTING

    //INFO DATA
    const getDetails = async () => {
        const details = await qz.printers.details()
        console.log(details)
        return details
    }

    const getNetworkInfo = async () => {
        const info = await qz.networking.device()
        console.log(info)
        return info
    }
    //END INFO DATA

    //PRINT TYPES
    const printPrecuenta = ({ items, id, descuento, orden, firebaseId, metaData }, collectionRef) => {
        const printerToFind = localStorage.overrideDefaultPrinter ? localStorage.overrideDefaultPrinter : config?.printConfig.defaultPrecuentaPrinter ? config?.printConfig.defaultPrecuentaPrinter : config?.printConfig.defaultPrinter
        const printer = config?.printConfig?.printers?.find(e => e.name === printerToFind)

        const agregado = (items.reduce((acc, item) => acc + (item.precio * item.cant * (1 - item.desc / 100)), 0))

        const direccionComercial = config.comprobantes?.ruc === '20601265398' ? 'Calle San Martín 533 Miraflores' : undefined

        let comanda = new EscPos(printer.width)

        comanda.setBold()
        comanda.align('center') //Align Center
        config?.comprobantes?.razonSocial && comanda.addRaw(config.comprobantes.razonSocial)
        comanda.resetStyle()
        config?.comprobantes?.ruc && comanda.addLine(`RUC ${config.comprobantes.ruc}`)
        direccionComercial && comanda.addLine(direccionComercial)

        comanda.addSeparator()
        comanda.addRaw('\x1B!\x20') //DOUBLE WIDTH
        comanda.addLine('Precuenta')
        comanda.resetStyle()
        comanda.addSeparator()

        comanda.addLine()
        comanda.align('left')
        comanda.addRaw(`Fecha imp. : ${moment().format('DD/MM/YYYY hh:mm A')}`)
        comanda.addLine(`Mesa       : ${id}`)
        comanda.addLine(`Orden      : ${orden}`)
        comanda.addLine(`Mesero      : ${metaData.mesero}`)

        comanda.addSeparator()
        comanda.addRaw('\x1B!\x01')
        comanda.addLine('CANT DESC' + ' '.repeat(64 - 'CANT DESC'.length - 'TOTAL  '.length - 'P.UNIT '.length) + 'P.UNIT TOTAL  ')
        comanda.resetStyle()
        comanda.addSeparator('-')
        comanda.addRaw('\x1B!\x01')
        items.forEach(i => {
            let cant = `${parseInt(i.cant)}${' '.repeat(5 - `${parseInt(i.cant)}`.length)}`
            let precio = ` ${parseFloat(i.precio * (1 - i.desc / 100)).toFixed(2)}${' '.repeat(7 - `${parseFloat(i.precio * (1 - i.desc / 100)).toFixed(2)}`.length)}`
            const _total = (i.precio * i.cant * (1 - i.desc / 100)).toFixed(2)
            let total = `${_total}${' '.repeat(7 - _total.length)}`

            if (i.name.length <= 44) {
                comanda.addLine(cant + i.name + ' '.repeat(44 - i.name.length) + precio + total)
            } else {
                let chunks = chunk(i.name, 44)
                comanda.addLine(cant + chunks.shift().join('') + precio + total)
                while (chunks.length) {
                    comanda.addLine(' '.repeat(5) + chunks.shift().join(''))
                }
            }
        })
        comanda.resetStyle()
        comanda.addSeparator()
        comanda.addLine()
        comanda.align('right')
        comanda.addRaw(`SUBTOTAL: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)}`)
        descuento && comanda.addLine(`DESCUENTO: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18) * parseFloat(descuento)).toFixed(2)}`)
        comanda.addLine(`IGV: S/.${(agregado * (1 - parseFloat(descuento || 0)) * (config?.igv ?? 18) / (config?.igvPlus100 ?? 118)).toFixed(2)}`)
        comanda.addLine(`TOTAL A COBRAR: S/.${(agregado * (1 - parseFloat(descuento || 0))).toFixed(2)}`)
        comanda.addSeparator()

        comanda.addLine()
        comanda.addLine('R. Social:' + '_'.repeat(printer.width - 10))
        comanda.addLine()
        comanda.addLine('RUC:' + '_'.repeat(printer.width - 4))

        comanda.addLine()
        comanda.align('center')
        comanda.addLine('GRACIAS POR SU COMPRA')
        comanda.addLine('Software disenado por COMANDAPP')
        comanda.addLine('www.comandapp.pe')
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()

        console.log(comanda.string)

        collectionRef.doc(firebaseId).update({
            escp: comanda.string
        })

        const printerConfig = qz.configs.create(printer.def);
        qz.print(
            printerConfig,
            [
                { data: comanda.string },
                { data: '\x1B\x69\x43' }
            ]
        ).then(() => {
            showNotification({
                message: 'Se imprimio correctamente la precuenta.',
                variant: 'success'
            })
            console.log('updating escp string for', firebaseId)
            collectionRef.doc(firebaseId).update({
                escp: comanda.string
            })
        }).catch(error => showNotification({
            message: 'Error al imrpimir la precuenta: ' + error.message,
            variant: 'danger'
        }))
    }
    const printInterno = ({ items, descuento, orden, firebaseId, mesero, mesa }, collectionRef) => {
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig.defaultPrinter))

        const agregado = (items.reduce((acc, item) => acc + (item.precio * item.cant * (1 - item.desc / 100)), 0))

        const direccionComercial = config.comprobantes?.ruc === '20601265398' ? 'Calle San Martín 533 Miraflores' : undefined

        let comanda = new EscPos(printer.width)

        comanda.setBold()
        comanda.align('center') //Align Center
        config?.comprobantes?.razonSocial && comanda.addRaw(config.comprobantes.razonSocial)
        comanda.resetStyle()
        config?.comprobantes?.ruc && comanda.addLine(`RUC ${config.comprobantes.ruc}`)
        direccionComercial && comanda.addLine(direccionComercial)

        comanda.addSeparator()
        comanda.addRaw('\x1B!\x20') //DOUBLE WIDTH
        comanda.addLine('Interno')
        comanda.resetStyle()
        comanda.addSeparator()

        comanda.addLine()
        comanda.align('left')
        comanda.addRaw(`Fecha imp. : ${moment().format('DD/MM/YYYY hh:mm A')}`)
        comanda.addLine(`Mesa       : ${mesa}`)
        comanda.addLine(`Orden      : ${orden}`)
        comanda.addLine(`Mesero     : ${mesero}`)

        comanda.addSeparator()
        comanda.addRaw('\x1B!\x01')
        comanda.addLine('CANT DESC' + ' '.repeat(64 - 'CANT DESC'.length - 'TOTAL  '.length - 'P.UNIT '.length) + 'P.UNIT TOTAL  ')
        comanda.resetStyle()
        comanda.addSeparator('-')
        comanda.addRaw('\x1B!\x01')
        items.forEach(i => {
            let cant = `${parseInt(i.cant)}${' '.repeat(5 - `${parseInt(i.cant)}`.length)}`
            let precio = ` ${parseFloat(i.precio * (1 - i.desc / 100)).toFixed(2)}${' '.repeat(7 - `${parseFloat(i.precio * (1 - i.desc / 100)).toFixed(2)}`.length)}`
            const _total = (i.precio * i.cant * (1 - i.desc / 100)).toFixed(2)
            let total = `${_total}${' '.repeat(7 - _total.length)}`

            if (i.name.length <= 44) {
                comanda.addLine(cant + i.name + ' '.repeat(44 - i.name.length) + precio + total)
            } else {
                let chunks = chunk(i.name, 44)
                comanda.addLine(cant + chunks.shift().join('') + precio + total)
                while (chunks.length) {
                    comanda.addLine(' '.repeat(5) + chunks.shift().join(''))
                }
            }
        })
        comanda.resetStyle()
        comanda.addSeparator()
        comanda.addLine()
        comanda.align('right')
        comanda.addRaw(`SUBTOTAL: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)}`)
        descuento && comanda.addLine(`DESCUENTO: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18) * parseFloat(descuento)).toFixed(2)}`)
        comanda.addLine(`IGV: S/.${(agregado * (1 - parseFloat(descuento || 0)) * (config?.igv ?? 18) / (config?.igvPlus100 ?? 118)).toFixed(2)}`)
        comanda.addLine(`TOTAL A COBRAR: S/.${(agregado * (1 - parseFloat(descuento || 0))).toFixed(2)}`)
        comanda.addSeparator()
        comanda.addLine()
        comanda.align('center')
        comanda.addLine('GRACIAS POR SU COMPRA')
        comanda.addLine('Software disenado por COMANDAPP')
        comanda.addLine('www.comandapp.pe')
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()

        console.log(comanda.string)

        collectionRef.doc(firebaseId).update({
            escp: comanda.string
        })

        const printerConfig = qz.configs.create(printer.def);
        qz.print(
            printerConfig,
            [
                { data: comanda.string },
                { data: '\x1B\x69\x43' }
            ]
        ).then(() => {
            showNotification({
                message: 'Se imprimio correctamente la precuenta.',
                variant: 'success'
            })
            console.log('updating escp string for', firebaseId)
            collectionRef.doc(firebaseId).update({
                escp: comanda.string
            })
        }).catch(error => showNotification({
            message: 'Error al imrpimir la precuenta: ' + error.message,
            variant: 'danger'
        }))
    }
    const printDelivery = ({ firebaseId, id, apertura, nombre, telefono, direccion, memo, items, descuento, delivery, metadata }, collectionRef) => {
        console.log('config', config)
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig.defaultPrinter))

        const agregado = (items.reduce((acc, item) => acc + (item.precio * item.cant), 0))

        let comanda = new EscPos(printer.width)

        comanda.setBold()
        comanda.align('center')
        comanda.addLine('DELIVERY')
        comanda.addLine(id)
        comanda.resetStyle()
        comanda.addSeparator()
        comanda.addLine()
        comanda.addRaw(`Fecha/Hora. : ${moment(apertura).format('DD/MM/YYYY hh:mm A')}`)
        comanda.addLine(`Estimado   : ${moment(apertura).format('DD/MM/YYYY hh:mm A')}`)
        comanda.addLine(`Cliente   : ${nombre || metadata?.comandappOnlineUserName}`)
        comanda.addLine(`Telefono   : ${telefono || metadata?.comandappOnlineUserPhone}`)
        comanda.addLine(`Direccion   : ${direccion || metadata?.comandappOnlineUserAddress}`)
        comanda.addSeparator()
        comanda.addLine(`Memo   : ${memo}`)
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x01')
        items.forEach(i => {
            let cant = `${parseInt(i.cant)}${' '.repeat(5 - `${parseInt(i.cant)}`.length)}`
            let precio = ` ${parseFloat(i.precio).toFixed(2)}${' '.repeat(7 - `${parseFloat(i.precio).toFixed(2)}`.length)}`
            const _total = (i.precio * i.cant).toFixed(2)
            let total = `${_total}${' '.repeat(7 - _total.length)}`

            if (i.name.length <= 44) {
                comanda.addLine(cant + i.name + ' '.repeat(44 - i.name.length) + precio + total)
            } else {
                let chunks = chunk(i.name, 44)
                comanda.addLine(cant + chunks.shift().join('') + precio + total)
                while (chunks.length) {
                    comanda.addLine(' '.repeat(5) + chunks.shift().join(''))
                }
            }
        })
        comanda.resetStyle()
        comanda.addSeparator()
        comanda.addLine()
        comanda.align('right')
        comanda.addRaw(`SUBTOTAL: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)}`)
        descuento && comanda.addLine(`DESCUENTO: S/.${(agregado / (config?.igvPlus100Decimal ?? 1.18) * parseFloat(descuento)).toFixed(2)}`)
        comanda.addLine(`DELIVERY: S/.${(delivery / (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)}`)
        comanda.addLine(`IGV: S/.${(agregado * (1 - parseFloat(descuento || 0)) * (config?.igv ?? 18) / (config?.igvPlus100 ?? 118)).toFixed(2)}`)
        comanda.addLine(`TOTAL A COBRAR: S/.${((agregado + delivery) * (1 - parseFloat(descuento || 0))).toFixed(2)}`)
        comanda.addSeparator()
        comanda.addLine()
        comanda.align('center')
        comanda.addLine('GRACIAS POR SU COMPRA')
        comanda.addLine('Software disenado por COMANDAPP')
        comanda.addLine('www.comandapp.pe')
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()

        console.log(comanda.string)

        collectionRef.doc(firebaseId).update({
            escp: comanda.string
        })

        const printerConfig = qz.configs.create(printer.def);
        qz.print(
            printerConfig,
            [
                { data: comanda.string },
                { data: '\x1B\x69\x43' }
            ]
        ).then(() => {
            showNotification({
                message: 'Se imprimio correctamente la precuenta.',
                variant: 'success'
            })
            console.log('updating escp string for', firebaseId)
            collectionRef.doc(firebaseId).update({
                escp: comanda.string
            })
        }).catch(error => showNotification({
            message: 'Error al imrpimir la precuenta: ' + error.message,
            variant: 'danger'
        }))
    }
    const printVoucher = (document, mesero) => {
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig.defaultPrinter))
        let comanda = new EscPos(printer.width)

        const direccionComercial = config.comprobantes.ruc === '20601265398' ? 'Calle San Martín 533 Miraflores' : undefined

        comanda.addRaw('\x1B!\x08') //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 0 + 0)) 8 dec => 8 hex
        comanda.align('center') //Align Center
        comanda.addRaw(config.comprobantes.razonSocial)
        comanda.addRaw('\x1B!\x01')
        comanda.addLine(`RUC ${config.comprobantes.ruc}`)
        direccionComercial && comanda.addLine(direccionComercial)
        comanda.addRaw('\x1B!\x00') //Reset
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x20')
        comanda.addLine(document.document.data_json.codigo_tipo_documento === '03' ? 'Boleta Electronica' : 'Factura Electronica')
        comanda.addLine(document.number)
        comanda.addRaw('\x1B!\x00') //Reset
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x01')
        comanda.addLine()
        comanda.align('left') //Align Left
        comanda.addRaw(`F. Emision : ${document.document.data_json.fecha_de_emision}`)
        comanda.addLine(`Cliente    : ${document.document.customer.name}`)
        comanda.addLine(`RUC / ${document.document.data_json.datos_del_cliente_o_receptor.codigo_tipo_documento_identidad === '4' ? 'CE ' : 'DNI'}  : ${document.document.customer.number}`)
        comanda.addLine(`Direccion  : ${document.document.customer.address || ''}`)
        comanda.addRaw('\x1B!\x00')
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x09')
        comanda.addLine('CANT DESC' + ' '.repeat(64 - 'CANT DESC'.length - 'TOTAL  '.length - 'P.UNIT '.length) + 'P.UNIT TOTAL  ')
        comanda.addRaw('\x1B!\x00')
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x01')
        document.document.items.forEach(i => {
            let cant = `${parseInt(i.quantity)}${' '.repeat(5 - `${parseInt(i.quantity)}`.length)}`
            let precio = ` ${parseFloat(i.unit_price).toFixed(2)}${' '.repeat(7 - `${parseFloat(i.unit_price).toFixed(2)}`.length)}`
            let total = `${i.total}${' '.repeat(7 - `${i.total}`.length)}`

            if (i.item.description.length <= 44) {
                comanda.addLine(cant + i.item.description + ' '.repeat(44 - i.item.description.length) + precio + total)
            } else {
                let chunks = chunk(i.item.description, 44)
                console.log(chunks)
                comanda.addLine(cant + chunks.shift().join('') + precio + total)
                while (chunks.length) {
                    comanda.addLine(' '.repeat(5) + chunks.shift().join(''))
                }
            }
        })
        comanda.addRaw('\x1B!\x00')
        comanda.addSeparator()
        comanda.addRaw('\x1B!\x08')
        comanda.addLine()
        comanda.addRaw(`\x1Ba\x02OP. GRAVADAS: S/.${document.document.data_json.totales.total_operaciones_gravadas.toFixed(2)}`)
        comanda.addLine(`IGV: S/. ${document.document.data_json.totales.total_igv.toFixed(2)}`)
        comanda.addLine(`TOTAL A COBRAR: S/. ${document.document.data_json.totales.total_venta.toFixed(2)}`)
        comanda.addLine()
        comanda.addLine()
        comanda.addRaw('\x1B!\x00\x1Ba\x00')
        comanda.addRaw(`SON: \x1B!\x08${document.number_to_letter}`)
        comanda.addLine()
        comanda.addRaw('\x1B!\x00\x1Ba\x00')
        comanda.addRaw(`FORMA DE PAGO: \x1B!\x08${document.formaDePago.toUpperCase()}`)
        comanda.align('center')
        comanda.addLine()
        comanda.qrCode('sdfkjsdbfhjsdvflkjashdbfkjhadsvfjkhasdvjbv')
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        comanda.addLine()
        document.formaDePago === 'efectivo' && comanda.openCashDrawer()

        console.log(comanda.string)

        const printerConfig = qz.configs.create(printer.def)
        qz.print(
            printerConfig,
            [
                { data: '\x1B@\x1Ba\x01' },
                config?.printConfig.logoUrl ? {
                    type: 'pixel',
                    format: 'image',
                    flavor: 'file',
                    data: config?.printConfig.logoUrl,
                    options: {
                        language: "ESCPOS",
                        dotDensity: 'double'
                    }
                } : { data: '' },
                { data: comanda.string },
                { data: '\x1B\x69\x43' }
            ],
        )
    }
    const printComandas = ({ items, id, memo, origen, qrData, orden, metaData }, format, printerName, collectionRef) => {
        console.log('printComanda', items, format, printerName)
        const printer = config?.printConfig.printers.find(e => e.name === printerName)
        let comanda = new EscPos(printer.width)

        switch (format) {
            case 'inverted':
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addSeparator()
                comanda.addLine(`Hora de impresion: ${moment().format('DD/MM/YY h:mm A')}`)
                comanda.addLine(`MEMO: ${memo || ''}`)
                comanda.addSeparator()
                items.forEach(i => {
                    let cant = `${i.cant}x `

                    if ((printer.width - (cant.length + 9)) >= i.name.length) {
                        comanda.addLine(cant + i.name + ' '.repeat((printer.width - (cant.length + 9 + i.name.length))) + moment(i.time).format(' hh:mm A'))
                    } else {
                        let chunks = chunk(i.name, printer.width - cant.length - 9)
                        comanda.addLine(cant + chunks.shift().join('') + moment(i.time).format(' hh:mm A'))
                        while (chunks.length) {
                            comanda.addLine(' '.repeat(cant.length) + chunks.shift().join(''))
                        }
                    }

                    //If nota add extra line
                    if (i.nota) comanda.addLine(`${' '.repeat(cant.length)}-> ` + i.nota)
                })
                comanda.addSeparator()
                comanda.addRaw('\x1B!\x38') //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                comanda.addRaw('\x1Ba\x01COMANDA')
                comanda.addLine(`${origen === 'salon' ? 'MESA' : 'ORDEN'}: ${id}`)
                comanda.addLine()
                comanda.addRaw('\x1B!\x00') //Reset
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                break;
            case 'advanced':
                comanda.addRaw('\x1B!\x38') //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                comanda.align('center') //Align Center
                comanda.addRaw('COMANDA')
                comanda.addLine(`${origen === 'salon' ? 'MESA' : 'ORDEN'}: ${id}`)
                comanda.addLine()
                comanda.addRaw('\x1B!\x00') //Reset
                comanda.align('left') //Align Left
                comanda.addSeparator()
                comanda.addLine(`Hora de impresion: ${moment().format('DD/MM/YY h:mm A')}`)
                comanda.addLine(`MEMO: ${memo || ''}`)
                comanda.addLine(`MESERO: ${metaData.mesero || ''}`)
                comanda.addSeparator()
                items.forEach(i => {
                    let cant = `${i.cant}x `

                    if ((printer.width - (cant.length + 9)) >= i.name.length) {
                        comanda.addLine(cant + i.name + ' '.repeat((printer.width - (cant.length + 9 + i.name.length))) + moment(i.time).format(' hh:mm A'))
                    } else {
                        let chunks = chunk(i.name, printer.width - cant.length - 9)
                        comanda.addLine(cant + chunks.shift().join('') + moment(i.time).format(' hh:mm A'))
                        while (chunks.length) {
                            comanda.addLine(' '.repeat(cant.length) + chunks.shift().join(''))
                        }
                    }

                    //If nota add extra line
                    if (i.nota) comanda.addLine(`${' '.repeat(cant.length)}-> ` + i.nota)

                    if (i.tipo === 'menu') {
                        i.menuSelections.forEach((selection, index) => {
                            comanda.addLine(`${' '.repeat(cant.length)}-> ` + i.menuOptions[index].name + ': ' + selection.name)
                        })
                    }
                })
                comanda.addLine()
                comanda.addLine()
                comanda.align('center')

                let text = qrData
                let s = text.length + 3
                let store_pL = String.fromCharCode(s % 256)
                let store_pH = String.fromCharCode(s / 256)

                let escpos = ""
                escpos += `\x1D\x28\x6B\x04\x00\x31\x41\x32\x00`
                escpos += "\x1D\x28\x6B\x03\x00\x31\x43\x07"
                escpos += `\x1D\x28\x6B\x03\x00\x31\x45\x31`
                escpos += `\x1D\x28\x6B${store_pL}${store_pH}\x31\x50\x30`
                escpos += text
                escpos += "\x1D\x28\x6B\x03\x00\x31\x51\x30"
                comanda.addRaw(escpos)
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                break;
            case 'big':
                comanda.addRaw('\x1B!\x38') //Emphasized + Double-height + Double-width mode selected
                comanda.align('center') //Align Center
                comanda.addRaw('COMANDA')
                comanda.addLine(`${origen === 'salon' ? 'MESA' : 'ORDEN'}: ${id}`)
                comanda.addRaw('\x1B!\x00') //Reset
                comanda.addLine(`ORDEN: ${orden}`)
                comanda.addLine()
                comanda.align('left') //Align Left
                comanda.addSeparator()
                comanda.addLine(`Hora de impresion: ${moment().format('DD/MM/YY h:mm A')}`)
                comanda.addLine(`MEMO: ${memo || ''}`)
                comanda.addSeparator()
                comanda.addRaw('\x1B!\x39') //Emphasized + Double-height + Double-width mode selected
                items.forEach(i => {
                    let cant = `${i.cant}x `

                    if ((printer.width - cant.length) >= i.name.length) {
                        comanda.addLine(cant + i.name + ' '.repeat((printer.width - (cant.length + 9 + i.name.length))))
                    } else {
                        let chunks = chunk(i.name, printer.width - cant.length)
                        comanda.addLine(cant + chunks.shift().join(''))
                        while (chunks.length) {
                            comanda.addLine(' '.repeat(cant.length) + chunks.shift().join(''))
                        }
                    }

                    //If nota add extra line
                    if (i.nota) comanda.addLine(`${' '.repeat(cant.length)}-> ` + i.nota)
                })
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                break;
            default:
                comanda.addRaw('\x1B!\x38') //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                comanda.align('center') //Align Center
                comanda.addRaw('COMANDA')
                comanda.addLine(`${origen === 'salon' ? 'MESA' : 'ORDEN'}: ${id}`)
                comanda.addLine()
                comanda.addRaw('\x1B!\x00') //Reset
                comanda.align('left') //Align Left
                comanda.addSeparator()
                comanda.addLine(`Hora de impresion: ${moment().format('DD/MM/YY h:mm A')}`)
                metaData?.mesero && comanda.addLine(`Mesero: ${metaData.mesero}`)
                comanda.addLine(`MEMO: ${memo || ''}`)
                comanda.addSeparator()
                items.forEach(i => {
                    let cant = `${i.cant}x `

                    if ((printer.width - (cant.length + 9)) >= i.name.length) {
                        comanda.addLine(cant + i.name + ' '.repeat((printer.width - (cant.length + 9 + i.name.length))) + moment(i.time).format(' hh:mm A'))
                    } else {
                        let chunks = chunk(i.name, printer.width - cant.length - 9)
                        comanda.addLine(cant + chunks.shift().join('') + moment(i.time).format(' hh:mm A'))
                        while (chunks.length) {
                            comanda.addLine(' '.repeat(cant.length) + chunks.shift().join(''))
                        }
                    }

                    //If nota add extra line
                    if (i.nota) comanda.addLine(`${' '.repeat(cant.length)}-> ` + i.nota)
                })
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                comanda.addLine()
                break;
        }

        console.log(comanda.string)

        const PrinterConfig = qz.configs.create(printer.def)

        qz.print(
            PrinterConfig,
            [
                { data: comanda.string },
                { data: '\x1B\x69\x43' }
            ]
        ).then(() => {
            console.log('Comanda impresa')
            showNotification({
                message: 'Se imprimió correctamente la comanda.',
                variant: 'success'
            })
            collectionRef.doc(qrData).update({
                escp: comanda.string
            })
        }).catch(error => showNotification({
            message: 'Error al imprimir la comanda: ' + error.message,
            variant: 'danger'
        }))
    }
    const printDetalleTurno = ({ shiftName, id, apertura, username, cierre, metodosDePago, hourlyGroupKeys, ingresoTotal, numeroBoletas, numeroFacturas }) => {
        const printer = config?.printConfig?.printers?.find(e => (e.name === config?.printConfig.defaultPrinter))

        let ticket = new EscPos(printer.width)

        ticket.addRaw('\x1B!\x20') //DOUBLE WIDTH
        ticket.addLine('DETALLE DE TURNO')
        ticket.resetStyle()
        ticket.addSeparator()

        ticket.addLine()
        ticket.align('left')
        ticket.addRaw(`Fecha imp.      : ${moment().format('DD/MM/YYYY hh:mm A')}`)
        ticket.addLine(`Nombre de turno : ${shiftName}`)
        ticket.addLine(`Id              : ${id}`)
        ticket.addLine(`Usuario         : ${username}`)
        ticket.addLine(`apertura  : ${moment(apertura).format('DD/MM/YYYY hh:mm A')}`)
        ticket.addLine(`cierre    : ${moment(cierre).format('DD/MM/YYYY hh:mm A')}`)

        ticket.addSeparator()
        ticket.align('center')
        ticket.addLine('RESUMEN DE VENTAS')
        ticket.addSeparator()
        ticket.addLine(`# Boletas      : ${numeroBoletas}`)
        ticket.addLine(`# Facturas     : ${numeroFacturas}`)
        ticket.addLine(`# Total        : ${numeroBoletas + numeroFacturas}`)
        ticket.addSeparator()
        ticket.addLine(`Valor Venta    : S/. ${(ingresoTotal * 100 / 118).toFixed(2)}`)
        ticket.addLine(`        IGV    : S/. ${(ingresoTotal * (config?.igv ?? 18) / (config?.igvPlus100 ?? 118)).toFixed(2)}`)
        ticket.addLine(`Total Venta    : S/. ${ingresoTotal.toFixed(2)}`)

        //ticket.addSeparator()
        //ticket.align('center')
        //ticket.addLine('DETALLE ANULACIONES')
        //ticket.addSeparator()
        //ticket.addLine(`# Anulaciones  : 0`)
        //ticket.addLine(`NO HAY DETALLE`)

        ticket.addSeparator()
        ticket.align('center')
        ticket.addLine('VENTAS POR METODO DE PAGO')
        ticket.addSeparator()
        Object.keys(metodosDePago).map(key => ticket.addLine(`${key.toUpperCase().padEnd(15)}: S/. ${metodosDePago[key].toFixed(2)}`))
        ticket.addLine(`TOTAL          : S/. ${ingresoTotal?.toFixed(2)}`)

        ticket.addSeparator()
        ticket.align('center')
        ticket.addLine('VENTAS POR HORA')
        ticket.addSeparator()

        Object.keys(hourlyGroupKeys).forEach(element => {
            const amount = hourlyGroupKeys[element].reduce((acc, orden) => (
                acc + orden.items.reduce((acc, item) => (
                    acc + (item.precio * item.cant)
                ), 0)
            ), 0)?.toFixed(2)
            ticket.addLine(`${element.split(', ')[1]}: S/. ${amount}`)
        })

        ticket.addLine()
        ticket.align('center')
        ticket.addLine('Software disenado por COMANDAPP')
        ticket.addLine('www.comandapp.pe')
        ticket.addLine()
        ticket.addLine()
        ticket.addLine()
        ticket.addLine()
        ticket.addLine()


        console.log(ticket.string)

        const printerConfig = qz.configs.create(printer.def);
        qz.print(
            printerConfig,
            [
                { data: ticket.string },
                { data: '\x1B\x69\x43' }
            ]
        ).then(() => showNotification({
            message: 'Se imprimio correctamente el resumen de turno.',
            variant: 'success'
        }))
    }
    //END PRINT TYPES

    return (
        <PrinterContext.Provider value={{ getDetails, getNetworkInfo, print, isConnected }}>
            {children}
        </PrinterContext.Provider>
    )
}

export function usePrinter() {
    return React.useContext(PrinterContext)
}

class EscPos {
    string = '\x1B@' //Initializes the printer (ESC @)
    width

    constructor(width) {
        this.width = width
    }

    addRaw = raw => {
        this.string += raw
    }

    addLine = line => {
        this.string += '\x0A' //New Line
        this.string += line || ''
    }

    openCashDrawer = () => {
        this.string += '\x10' + '\x14' + '\x01' + '\x00' + '\x05'
    }

    addSeparator = (char, skipNewLine) => {
        if (!skipNewLine) {
            this.string += '\x0A' //New Line
        }
        this.string += (char || '-').repeat(this.width)
    }

    align = pos => {
        this.string += pos === 'center' ? '\x1Ba\x01' : pos === 'left' ? '\x1Ba\x00' : pos === 'right' ? '\x1Ba\x02' : ''
    }

    setBold = () => this.string += '\x1B!\x08'

    resetStyle = () => this.string += '\x1B!\x00'

    qrCode = (qrData) => {
        let text = qrData
        let s = text.length + 3
        let store_pL = String.fromCharCode(s % 256)
        let store_pH = String.fromCharCode(s / 256)

        let escpos = ""
        escpos += `\x1D\x28\x6B\x04\x00\x31\x41\x32\x00`
        escpos += "\x1D\x28\x6B\x03\x00\x31\x43\x07"
        escpos += `\x1D\x28\x6B\x03\x00\x31\x45\x31`
        escpos += `\x1D\x28\x6B${store_pL}${store_pH}\x31\x50\x30`
        escpos += text
        escpos += "\x1D\x28\x6B\x03\x00\x31\x51\x30"

        this.string += escpos
    }

    getRaw = () => this.string
}






