import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Salon from './Salon'
import Mesa from './Mesa'

export default () => {
    if ( !sessionStorage.local_id ) return <h1>No local Id</h1>
    return (
        <Switch>
            <Route exact path='/salon'
                render={(props) => <Salon {...props}/>}
            />
            <Route exact path='/salon/:id'
                render={(props) => <Mesa {...props}/>}
            />
            <Redirect to='/'/>
        </Switch>
    )
}