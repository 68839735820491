import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Callcenter from './Callcenter'
import Pedido from './Pedido'

export default () => (
    <Switch>
        <Route exact path='/callcenter'
            render={props => <Callcenter {...props}/>}
        />
        <Route exact path='/callcenter/:cliente/:local/:id'
            render={props => <Pedido {...props}/>}
        />
        <Redirect to='/'/>
    </Switch>
)