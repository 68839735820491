import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Button, Card, Row, Col, Image } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default function Dash() {
    return (
        <Fragment>
            <div className="mb-1 flex-grow-1 overflow-y-auto">
                <Card className='rounded-4 border-0 shadow mb-3'>
                    <Card.Body>
                        <Row>
                            <h5>Progreso Comandapp Next</h5>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <img src={require('./ss4.png')} width='100%' className='rounded shadow' />
                            </Col>
                            <Col ls={4}>
                                <img src={require('./ss5.png')} width='100%' className='rounded shadow mb-4' />
                            </Col>
                            <Col>
                                <img src={require('./ss6.png')} width='60%' className='rounded shadow' />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='rounded-4 border-0 shadow mb-3'>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h5 className='mb-0'>Presentamos Comandapp Next</h5>
                                <p>Una solución moderna para las necesidades de tu negocio.</p>
                                <h5>Un vistazo al Módulo de Salon.</h5>
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col>
                                <Image src={require('./ss1.png')} alt="screenshot" rounded className='w-100' />
                            </Col>
                            <Col>
                                <Image src={require('./ss3.png')} alt="screenshot" rounded className='w-100' />
                            </Col>
                            <Col>
                                <Image src={require('./ss2.png')} alt="screenshot" rounded className='w-100' />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h5>Mejoras y Nuevas Funciones</h5>

                                <ul>
                                    <li>Separación de mesas por area.</li>
                                    <li>Administra tu mismo tus mesas.</li>
                                    <li>Crea mesas temporales cuando lo necesitas.</li>
                                    <li>Varias Ordenes por mesa.</li>
                                    <li>Transferir cuenta a otra mesa.</li>
                                    <li>Cambia el mesero asignado.</li>
                                    <li>Cobra ordenes por separado.</li>
                                    <li>Muchas funciones mas.</li>
                                </ul>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='rounded-4 border-0 shadow'>
                    <Card.Body className='d-flex flex-column'>
                        <img className='mb-3 mx-auto' src={require('../../assets/img/comandappIcon.png')} alt='' width='200px' />
                        <h5 className='text-center'>Un nuevo Comandapp</h5>

                        <p className='text-center'>Una solución moderna para las necesidades de tu negocio.</p>

                        <p>Estamos contentos de anunciar que ya estamos desarrollando una nueva versión de Comandapp. Con muchas nuevas herramientas para ayudarte a llevar control y hacer crecer tu negocio.</p>

                        <h5>¡Un Nuevo Look!</h5>

                        <p>Hemos rediseñado la interfaz utilizando las ultimas tendencias en diseño web, dale un vistazo</p>

                        <h5>Mejoras</h5>

                        <ul>
                            <li>Cambiamos nuestra infraestructura para darte mas velocidad, la nueva versión de Comandapp será casi 30% mas ágil.</li>
                            <li>Ahora podrás solicitar módulos personalizados, solo para tu negocio.</li>
                            <li>Control total, podrás ver y auditar todo lo que pasa dentro de Comandapp.</li>
                            <li>Muchos nuevos módulos y herramientas como:</li>
                            <ul>
                                <li>Reservas</li>
                                <li>Distintas Áreas de Servicio</li>
                                <li>Mesas Temporales</li>
                                <li>Áreas de Producción</li>
                                <li>Y Muchas Mas…</li>
                            </ul>
                        </ul>
                    </Card.Body>
                </Card>
            </div>
            <div className='d-flex'>
                <Card className='d-inline px-1 flex-grow-1 mr-1'>
                    {sessionStorage.cliente_id} - {sessionStorage.local_id} - {sessionStorage.username} - {sessionStorage.tipo}
                </Card>
                <Button as={Link} size='lg' to="/login" variant='outline-light'>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </Button>
            </div>
        </Fragment>
    )
}