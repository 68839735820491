import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import { useDialog } from "../../Hook/DialogContext"

export default ({ cartaObj, update }) => {
    const [ gridApi, setGridApi ] = useState()
    const [ editItem, setEditItem ] = useState()

    const { showNotification } = useDialog()

    const onSubmit = async e => {
        e.preventDefault()

        let form = e.target

        try {
            const newGruposArray = cartaObj.grupos

            if ( editItem.index !== undefined ) {
                newGruposArray[editItem.index] = {
                    ...newGruposArray[editItem.index],
                    path: editItem.path,
                    color: editItem.color,
                    folderName: editItem.folderName || ''
                }
            } else {
                newGruposArray.push({
                    path: editItem.path,
                    color: editItem.color,
                    folderName: editItem.folderName || ''
                })
            }

            await update({
                grupos: newGruposArray
            })

            showNotification({
                message: `Grupo '${form.elements.nombre.value}' agregado/editado exitosamente.`,
                variant: 'success'
            })
            gridApi.setRowData(newGruposArray)
        } catch (error) {
            showNotification({
                message: `${error.message}`,
                variant: 'danger'
            })
        }
    }

    const columnDefs = [
        {headerName: "Path", field: "path", sortable: true, filter: true, resizable: true},
        {headerName: "Nombre", field: "folderName", sortable: true, filter: true, resizable: true},
        {headerName: "Color", field: "color", sortable: true, filter: true, resizable: true,
            cellStyle: (params) => (
                {backgroundColor: params.value}
            ),
        }
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>

            <Modal show={!!editItem} onHide={() =>  setEditItem(null) } centered>
                <Modal.Header className='text-center' closeButton>
                    <Modal.Title>{editItem?.index ? 'Editar Grupo' : 'Nuevo Grupo'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={onSubmit}>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Path</Form.Label>
                                    <Form.Control name='nombre' required value={editItem?.path} onChange={e => {setEditItem({...editItem, path: e.target.value})}}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control name='nombre' value={editItem?.folderName} onChange={e => {setEditItem({...editItem, folderName: e.target.value})}}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control type='color' name='color' required value={editItem?.color} onChange={e => {setEditItem({...editItem, color: e.target.value})}}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Button type="submit" className='float-right'>
                            Guardar Cambios
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* RIGHT ACTION BUTTTONS */}
            <div className='ml-auto mb-1 mbt-1 position-absolute' style={{ right: '4px', top: '56px' }}>
                <Button size='sm' variant='outline-light' onClick={() => {
                    const selectedNodes = gridApi.getSelectedNodes()
                    const selectedData = selectedNodes.map( node => ({...node.data, index: node.rowIndex}) )
                    if (!selectedData[0]) return
                    const item = selectedData[0]
                    setEditItem(item)
                }}>
                    <FontAwesomeIcon icon={faEdit} className='mr-1'/>Editar
                </Button>
                <Button size='sm' variant='outline-light' onClick={ () => setEditItem({}) }>
                    <FontAwesomeIcon icon={faPlus}/>
                </Button>
            </div>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    rowSelection="single"
                    columnDefs={columnDefs}
                    rowData={cartaObj?.grupos}
                    onGridReady={params => {
                        setGridApi(params.api)
                    }}
                />
            </div>

        </div>
    )
}
