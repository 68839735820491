import React from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'

import Button from 'react-bootstrap/Button'

import Salon from './Salon'
import Delivery from './Delivery'
import Productos from './Productos'
import Documentos from './Documentos'
import Web from './Web.jsx'
import DeliverySimple from './DeliverySimple'
import Insumos from './Insumos'
import Impresiones from './Impresiones'
import Turnos from './Turnos'

export default () => {
    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <div className='d-flex mbt-1 mb-1 mw-100 overflow-x-auto'>
                <Button size='sm' as={Link} to='/reporteshoy/salon' variant={useRouteMatch('/reportes/salon') ?  'dark' : 'outline-dark'} >Salon</Button>
            </div>
            <Switch>
                <Route exact path='/reporteshoy/salon' component={Salon}/>
            </Switch>
        </div>
    )
}