import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { chunk } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'

import CustomToggle from '../CustomToggle'
import useWindowDimensions from '../Helpers/useWindowDimensions'

import BuscarButton from '../Buscar'
import WebButton from '../WebButton'

import Routes from '../../Routes'

import { usePrinter } from '../../Hook/PrinterContext'

export default () => {
    const permisos = JSON.parse(sessionStorage.permisos)
    const windowDimensions = useWindowDimensions()

    const { isConnected } = usePrinter()

    const logo = require(`../../assets/img/comandappIcon.png`)

    const modules = variant => Routes.filter(route => (permisos.includes(route.perm))).map(route => (
        <Button
            key={route.name}
            as={Link}
            size='lg'
            style={{ widht: '48px', height: '48px', maxWidht: '48px', maxHeight: '48px' }}
            variant={(route.exact ? useRouteMatch(route.path)?.isExact : useRouteMatch(route.path)) ? variant : `outline-${variant}`}
            to={route.path}
        >
            <FontAwesomeIcon icon={route.icon}/>
        </Button>
    ))

    const actionButtons = <React.Fragment>
        <BuscarButton/>
        { permisos.includes('MODULO-WEBORDERS') && <WebButton/> }
    </React.Fragment>

    if ( windowDimensions.width <= 1000 ) {
        return (
            <div className='mb-1 d-flex justify-content-between'>
                <Dropdown drop='right'>
                    <Dropdown.Toggle as={CustomToggle} size='lg' variant='outline-light'><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
        
                    <Dropdown.Menu className='p-0'>
                        <ButtonsMenu>
                            {modules('dark')}
                        </ButtonsMenu>
                    </Dropdown.Menu>
                </Dropdown>
                
                <img src={logo} alt='logo' height='48' style={{WebkitFilter: 'drop-shadow(0 0 7px rgba(0, 0, 0, 0.5))'}}/>

                <Dropdown drop='left'>
                    <Dropdown.Toggle as={CustomToggle} size='lg' variant='outline-light'><FontAwesomeIcon icon={faEllipsisH}/></Dropdown.Toggle>
        
                    <Dropdown.Menu className='p-0'>
                        <ButtonsMenu>
                            {actionButtons}
                        </ButtonsMenu>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    } else return (
        <div className="mb-1 d-flex justify-content-between">
            <div className="d-flex mbt-1">
                {modules('light')}
            </div>
            
            <div className="d-flex mbt-1">
                {actionButtons}
            </div>

            <div className='d-flex' style={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)'
            }}>
                <img alt='logo' height='48' src={logo} style={{
                    WebkitFilter: 'drop-shadow(0 0 7px rgba(0, 0, 0, 0.5))',
                }}/>
                <FontAwesomeIcon icon={faPrint} className={isConnected ? 'text-success' : 'text-danger'}/>
            </div>
            
        </div>
    )
}

const ButtonsMenu = (({ children }) => {
    let array = chunk(children, 4)
	return (
        <div className='pt-1 px-1'>
            {array.map((chunk, index) => (
                <div className='d-flex mb-1 mbt-1' key={index}>
                    {chunk}
                </div>
            ))}
        </div>
	)
})