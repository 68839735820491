import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({component: Component, ...rest}) => {
  const jwt = sessionStorage.jwt
  return (
    <Route {...rest} render={props => (
      jwt ? <Component {...props}/> : <Redirect to="/login"/>
    )} />
  )
}

   

