import React, { useState, useEffect, Fragment } from 'react'

import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'

import Nav from '../../components/Nav'
import DeliveryOrderButton from '../../components/DeliveryOrderButton'
import NewOrderButton from '../../components/NewOrder/NewOrderButton'

import { useOrders } from '../../Hook/OrdersContext'

export default () => {
    const { callcenterData, mutateCallcenter } = useOrders()
    const [ showSingles, setShowSingles ] = useState(false)

    useEffect(mutateCallcenter, [])

    const grupos = callcenterData?.map((grupo, index) => (
        <Group key={index} grupo={grupo} showSingles={showSingles}/>
    ))

    return (
        <>
            <Nav/>
            <Card className='flex-grow-1 overflow-y-auto'>
                <div className='mb-2 pl-1 pt-1 mbt-1'>
                    <NewOrderButton/>
                    <Button variant={showSingles ? 'primary' : 'outline-primary'} size='sm' onClick={() => setShowSingles(!showSingles)}>Monstrar Individuales</Button>
                </div>
                {
                    showSingles ?
                    <Row xl={6} lg={5} md={4} sm={3} xs={2}>
                        {grupos}
                    </Row> :  grupos
                }
            </Card>
        </>
    )
}

const Group = ({ grupo, showSingles, ...rest }) => {
    const [open , setOpen] = useState(false)

    const groupName =  JSON.parse(sessionStorage.locales).find(localInStorage => localInStorage.id === grupo.local)?.nombre

    const buttons = grupo.ordenes.map((orden, index) => (
        <DeliveryOrderButton
            key={index}
            localName={ groupName }
            pedido={ {...orden, cliente:grupo.cliente, local: grupo.local } }
            modulo='callcenter'
        />
    ))

    if ( showSingles ) return <Fragment>
        {buttons}
    </Fragment>

    return (
        <Card className='mx-1 mb-2 bg-light' { ...rest }>
            <Card.Header className='cursor-pointer h4' onClick={() => setOpen(!open)}>
                { groupName }
                <Badge variant={grupo.ordenes.length ? 'primary' : 'secondary'} className="ml-1 my-auto">{grupo.ordenes.length}</Badge>
            </Card.Header>
            <Card.Body hidden={!open} as={Row} xl={6} lg={5} md={4} sm={3} xs={2}>
                {
                    buttons.length ?
                        buttons
                    :
                    <h5 className='m-auto py-5 text-nowrap'>No hay ordenes para mostrar...</h5>
                }
            </Card.Body>
        </Card>
    )
}