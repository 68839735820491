import React, { useState } from 'react'
import Axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import _ from "lodash"
import Card from "react-bootstrap/Card"

export default () => {
    const [local, setLocal] = useState()

    const locales = _.filter(JSON.parse(sessionStorage.locales), (local) => !!local.apiKey && !!local.apiUrl)

    if (!local) {
        return (
            <Card className="flex-grow-1">
                <Row xs={2} md={3} lg={4} xl={6}>
                    {
                        locales.map(local => (
                            <Col className="my-1 px-1">
                                <Button
                                    className="text-truncate"
                                    block
                                    style={{ height: '110px' }}
                                    onClick={e => setLocal(local)}
                                >   
                                    { local.nombre }
                                </Button>
                            </Col>
                        ))
                    }
                </Row>
            </Card>
        )
    }

    return (
        <Content local={local} setLocal={setLocal}/>
    )
}

const Content = ({local, setLocal}) => {
    const [gridApi, setGridApi] = useState()
    const [rowData, setRowData] = useState([])
    const [gridColumnApi, setGridColumnApi] = useState()

    const fetchData = async e => {
        e.preventDefault()
        const form = e.target
        
        gridApi.showLoadingOverlay()

        const startString = moment(form.elements.start.value).add(5, 'h').format('YYYY-MM-DD HH:mm:ss')
        const endString = moment(form.elements.end.value).endOf('day').add(5, 'h').format('YYYY-MM-DD HH:mm:ss')
        
        try {
            const response = await Axios.get(local.apiUrl + `api/documents/lists/${startString}/${endString}`, {headers: {Authorization: `Bearer ${local.apiKey}`}})
            setRowData(response.data.data)
            gridApi.hideOverlay()
        } catch (error) {
            gridApi.hideOverlay()
            alert(error.message)
        }
    }

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    let columnDefs = [
        { headerName: "Tipo Documento", field: "document_type_description", sortable: true, filter: true, resizable: true},
        { headerName: "Comprobante", field: "number", sortable: true, filter: true, resizable: true,},
        { headerName: "Fecha Emision", field: "date_of_issue", sortable: true, resizable: true, filter: true },
        { headerName: "Cliente", field: "customer_name", sortable: true, resizable: true, filter: true },
        { headerName: "Estado", field: "state_type_description", sortable: true, resizable: true, filter: true },
        { headerName: "Total Gravado", field: "total_taxed", sortable: true, resizable: true, filter: true },
        { headerName: "Igv", field: "total_igv", sortable: true, resizable: true, filter: true },
        { headerName: "Total", field: "total", sortable: true, resizable: true, filter: true }
    ]

    return (
        <div className='d-flex flex-column h-100'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 d-flex flex-row mw-100'>
                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='start' required/>
                    </Form.Group>

                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='end' required/>
                    </Form.Group>

                    <Button block size='sm' variant='outline-dark' type='submit'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1'/>Reporte
                    </Button>
                </div>

                <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1'/>Exportar
                    </Button>
                </div>

            </Form>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    rowData={rowData || []}
                    onGridReady={ params => {setGridApi(params.api); setGridColumnApi(params.columnApi)} }
                    onFirstDataRendered={() => {autoSizeAll()}}
                />         
            </div>
        </div>
    )

}