import React, { useState, useCallback } from 'react'
import Axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import { flatten } from 'lodash'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload, faEye } from '@fortawesome/free-solid-svg-icons'

import { useInfoCards } from '../../Hook/InfoCardsContext'

export default () => {
    const [gridApi, setGridApi] = useState()
    const [rowData, setRowData] = useState([])
    const [gridColumnApi, setGridColumnApi] = useState()

    const { show } = useInfoCards()

    const fetchData = async e => {
        e.preventDefault()

        gridApi.showLoadingOverlay()

        const form = e.target

        gridApi.showLoadingOverlay()
        const startString = moment(form.elements.start.value).add(5, 'h').format('YYYY-MM-DD HH:mm:ss')
        const endString = moment(form.elements.end.value).endOf('day').add(5, 'h').format('YYYY-MM-DD HH:mm:ss')

        try {
            const res = await Axios.get(`/api/reportes/salon/?start=${startString}&end=${endString}`)

            setRowData(flatten(res.data.map(orden => {
                const object = {
                    ...orden,
                    total: orden.items.reduce((acc, item) => acc + (item.precio * item.cant), 0)
                }
                if (orden.metaData?.formaDePago === 'combinado') return [
                    object,
                    ...orden.metaData.combinado
                ]
                return object
            })))

            if (!res.data.length)
                gridApi.showNoRowsOverlay()
            else
                gridApi.hideOverlay()

        } catch (error) {
            alert(error.message)
        }
    }

    const getRowStyle = useCallback((params) => {
        if (params.node.rowPinned) {
            return { 'font-weight': 'bold' };
        }
    }, []);

    const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;

        if (dateAsString == null) {
            return 0;
        }

        // In the example application, dates are stored as dd/mm/yyyy
        // We create a Date object for comparison against the filter date
        var dateParts = dateAsString.split(' ');
        dateParts = dateParts[0].split('/');
        var day = Number(dateParts[2]);
        var month = Number(dateParts[1]) - 1;
        var year = Number(dateParts[0]);
        var cellDate = new Date(day, month, year);

        // Now that both parameters are Date objects, we can compare
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        } else {
            return 0;
        }
    }

    let columnDefs = [
        { headerName: "Id", field: "id", sortable: true, filter: true, resizable: true, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, },
        {
            headerName: "Apertura", sortable: true, resizable: true, filter: 'agDateColumnFilter',
            filterParams: { comparator: dateComparator },
            valueGetter: params => params.data.apertura ? moment(params.data.apertura).local().format('DD/MM/YYYY') : ''
        },
        {
            headerName: "Apertura Hora", sortable: true, resizable: true, filter: 'agDateColumnFilter',
            filterParams: { comparator: dateComparator },
            valueGetter: params => params.data.apertura ? moment(params.data.apertura).local().format('h:mm:ss a') : ''
        },
        {
            headerName: "Cierre", sortable: true, resizable: true, filter: 'agDateColumnFilter',
            filterParams: { comparator: dateComparator },
            valueGetter: params => params.data.cierre ? moment(params.data.cierre).local().format('DD/MM/YYYY') : '',
        },
        {
            headerName: "Cierre Hora", sortable: true, resizable: true, filter: 'agDateColumnFilter',
            filterParams: { comparator: dateComparator },
            valueGetter: params => params.data.cierre ? moment(params.data.cierre).local().format('h:mm:ss a') : '',
        },
        {
            headerName: "Tiempo de Estadia", resizable: true, filter: 'agNumberColumnFilter',
            valueGetter: params => (params.data.apertura && params.data.cierre) ? moment(params.data.apertura).to(params.data.cierre, true) : ''
        },
        { headerName: "Memo", field: "memo", resizable: true, filter: true },
        {
            headerName: "Mozo", resizable: true, filter: true, valueGetter: params => {
                return params.data?.metaData?.mesero
            }
        },
        { headerName: "Nro. Mesa", field: "mesa", resizable: true, filter: true },
        { headerName: "Status", field: "status", resizable: true, filter: true },
        { headerName: "Nota Status", field: "status_nota", resizable: true, filter: true },
        { headerName: "Usuario", field: "usuario", resizable: true, filter: true },
        {
            headerName: "Descuento", field: "descuento", resizable: true, filter: true,
            valueFormatter: function (params) {
                return params.value ? params.value * 100 + '%' : '';
            }
        },
        {
            headerName: "Forma de Pago", resizable: true, filter: true, valueGetter: params => {
                return params.data.metaData?.formaDePago || params.data.metodo
            }
        },
        {
            headerName: "Total", sortable: true, resizable: true, filter: 'agNumberColumnFilter', valueGetter: params => {
                return params.data.total || params.data.amount
            }, valueFormatter: function (params) {
                return 'S/. ' + parseFloat(params.value).toFixed(2)
            }
        },
        {
            headerName: "Total Descontado", sortable: true, resizable: true, filter: 'agNumberColumnFilter', valueGetter: params => {
                return params.data.totalDescontado ?? (
                    params.data.total * (1 - (params.data.descuento || 0)) || params.data.amount
                )
            }, valueFormatter: function (params) {
                return 'S/. ' + parseFloat(params.value).toFixed(2)
            }
        },
        {
            headerName: "Propina", resizable: true, filter: 'agNumberColumnFilter', valueGetter: params => {
                return params.data.propina || params.data.metaData?.propina
            }, valueFormatter: function (params) {
                return params.value ? 'S/. ' + params.value : ''
            }
        },
        {
            headerName: "Comprobante(s)", resizable: true, filter: true, valueGetter: params => {
                return params.data.metaData?.comprobantes ? params.data.metaData?.comprobantes.join(', ') : null
            }
        }
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 d-flex flex-row mw-100'>
                    <Form.Control type='date' name='start' required defaultValue={moment().startOf('day').format('YYYY-MM-DD')} hidden />
                    <Form.Control type='date' name='end' required defaultValue={moment().startOf('day').format('YYYY-MM-DD')} hidden />

                    <Button block size='sm' variant='outline-dark' type='submit' className='text-nowrap'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1' />Reporte
                    </Button>
                </div>

                <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1' />Exportar
                    </Button>
                </div>

                <div className='mb-1 mbt-1 ml-auto'>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        const selectedNodes = gridApi.getSelectedNodes()
                        const selectedData = selectedNodes.map(node => node.data)
                        if (!selectedData[0]) return
                        const item = selectedData[0]

                        show({ ...item, objectType: 'salon' })
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faEye} className='mr-1' />Ver Orden
                    </Button>
                </div>

            </Form>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    rowData={rowData}
                    onGridReady={params => { setGridApi(params.api); setGridColumnApi(params.columnApi) }}
                    getRowStyle={getRowStyle}
                    components={{
                        agDateInput: CustomDateComponent
                    }}
                    pinnedBottomRowData={[{
                        total: rowData.reduce((acc, order) => {
                            return order.id ? (acc + order.total) : acc
                        }, 0),
                        totalDescontado: rowData.reduce((acc, order) => {
                            return order.id ? (acc + (order.total * (1 - (order.descuento || 0)))) : acc
                        }, 0),
                        propina: rowData.reduce((acc, order) => acc + (order.propina || order.metaData?.propina || 0), 0)
                    }]}
                />
            </div>
        </div>
    )

}

function CustomDateComponent() { }

CustomDateComponent.prototype.init = function (params) {
    this.params = params;
    this.eGui = document.createElement('div');
    this.eInput = document.createElement('input');
    this.eGui.appendChild(this.eInput);
    window.jQuery(this.eInput).datetimepicker({
        mask: true, // '9999/19/39 29:59' - digit is the maximum possible for a cell
        onChangeDateTime: this.onDateChanged.bind(this)
    });
}

CustomDateComponent.prototype.onDateChanged = function (currentDateTime) {
    this.date = currentDateTime;
    this.params.onDateChanged();
};

CustomDateComponent.prototype.getGui = function () {
    return this.eGui;
}

CustomDateComponent.prototype.getDate = function () {
    return this.date;
}

CustomDateComponent.prototype.setDate = function (date) {
    // implement this to make it work with floating filters...

}

CustomDateComponent.prototype.destroy = function () {
    window.jQuery(this.eInput).datetimepicker('destroy');
}