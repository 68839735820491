import React, { useState } from 'react'
import { map } from 'lodash'
import { useParams } from "react-router-dom"
import { Typeahead } from "react-bootstrap-typeahead"
import { AgGridReact } from 'ag-grid-react'
import { update } from "@nandorojo/swr-firestore"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faEdit, faCircle, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

import { useDialog } from '../../Hook/DialogContext'

export default ({ items, add, cartaObj, update: updateDoc, insumos }) => {
	const { cartaId } = useParams()

	const [gridApi, setGridApi] = useState()
	const [gridColumnApi, setGridColumnApi] = useState()
	const [editItem, setEditItem] = useState()

	const { showNotification } = useDialog()

	const autoSizeAll = skipHeader => {
		var allColumnIds = [];
		gridColumnApi.getAllColumns().forEach(function (column) {
			allColumnIds.push(column.colId);
		});
		gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
	}

	const onSubmit = async event => {
		event.preventDefault()

		const item = {
			activo: editItem.activo,
			cocina: editItem.cocina || '',
			color: editItem.color || '#000000',
			id: editItem.id,
			name: editItem.name,
			precio: parseFloat(editItem.precio),
			tipo: 'simple',
			itemId: editItem.itemId || items.length + 1,
			path: editItem.path
		}

		const path = editItem.path
		const hasGrupo = !!path

		const paths = map(cartaObj?.grupos, 'path')
		let hasValidPath = paths.includes(path)

		if (path === 'main') { hasValidPath = true }

		if (hasGrupo) {
			if (!hasValidPath) {
				// eslint-disable-next-line no-restricted-globals
				const userWantsToCreateGroup = confirm(`El grupo ${path} no existe. ¿Deseas crearlo?`)

				if (!userWantsToCreateGroup) {
					return
				}

				await updateDoc({
					grupos: [...cartaObj.grupos, { path, color: "#808080" }]
				})

				alert(`Se creó el grupo ${path}.`)

				item.path = path
			}
		} else {
			item.path = 'main'
		}

		try {
			if (item.id) {
				await update(`cartas/${cartaId}/items/${item.id}`, item)
			} else {
				await add(item)
			}

			showNotification({
				message: `Item '${editItem.value}' agregado/editado exitosamente.`,
				variant: 'success'
			})
		} catch (error) {
			showNotification({
				message: `${error.message}`,
				variant: 'danger'
			})
		}
	}

	const columnDefs = [
		{ headerName: "Id", field: "itemId", sortable: true, filter: true, resizable: true },
		{ headerName: "Nombre", field: "name", sortable: true, filter: true, resizable: true },
		{ headerName: "Grupo", field: "path", sortable: true, filter: true, resizable: true },
		{ headerName: "Tipo", field: "tipo", sortable: true, filter: true, resizable: true },
		{ headerName: "Cocina", field: "cocina", sortable: true, filter: true, resizable: true, valueFormatter: params => (params.value ?? 'ninguna') },
		{ headerName: "Insumos", field: "insumos.length", sortable: true, filter: true, resizable: true },
		{
			headerName: "Precio", field: "precio", sortable: true, filter: true, resizable: true,
			valueFormatter: (params) => 'S/. ' + (params.value || 0)
		},
		{
			headerName: "Color", field: "color", sortable: true, filter: true, resizable: true,
			cellStyle: (params) => ({
				backgroundColor: params.value
			}),
		},
		{
			headerName: "Activo", field: "activo", sortable: true, filter: true, resizable: true
		},
		{
			headerName: "Activo", field: "activo", sortable: true, filter: true, resizable: true,
			cellRendererFramework: (params) => (
				<div className='text-center'>
					<FontAwesomeIcon icon={faCircle} className={params.value ? 'text-success' : 'text-danger'} />
				</div>
			),
		}
	]

	return (
		<div className='flex-grow-1 d-flex flex-column'>

			<Modal show={!!editItem} onHide={() => setEditItem(null)} centered>
				<Modal.Header closeButton>
					<Modal.Title>{editItem?.id ? 'Editar Item' : 'Nuevo Item'}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{/* ITEM ID FORWARDS/BACKWARDS */}
					<div className={`${editItem?.itemId ? 'd-flex' : 'd-none'} justify-content-center`}>
						<Button size='sm' className='mr-3' disabled={editItem?.itemId <= 1} onClick={() => {
							const item = items.find(item => item.itemId === editItem?.itemId - 1)
							setEditItem(item)
						}}>
							<FontAwesomeIcon icon={faChevronLeft} className='mr-1' />Anterior
						</Button>

						<span className='h4 mb-0'>ID: {editItem?.itemId}</span>

						<Button size='sm' className='ml-3' onClick={() => {
							const item = items.find(item => item.itemId === editItem?.itemId + 1)
							setEditItem(item)
						}}>
							Siguiente<FontAwesomeIcon icon={faChevronRight} className='ml-1' />
						</Button>
					</div>


					<Form onSubmit={onSubmit}>
						<Form.Row>
							<Col xs={12}>
								<Form.Group className='mb-2'>
									<Form.Label>Tipo</Form.Label>
									<Form.Control clas size='sm' required value={editItem?.tipo || ''} onChange={e => setEditItem({ ...editItem, tipo: e.target.value })} as='select'>
										<option hidden value=''>Seleccionar...</option>
										<option value='simple'>Simple</option>
										<option value='menu'>Menu</option>
									</Form.Control>
								</Form.Group>
							</Col>
							<Col xs={12}>
								<Form.Group className='mb-2'>
									<Form.Label>Nombre</Form.Label>
									<Form.Control size='sm' required value={editItem?.name || ''} onChange={e => setEditItem({ ...editItem, name: e.target.value })} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className='mb-2'>
									<Form.Label>Cocina</Form.Label>
									<Form.Control size='sm' value={editItem?.cocina || ''} onChange={e => setEditItem({ ...editItem, cocina: e.target.value })} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group className='mb-2'>
									<Form.Label>Grupo</Form.Label>
									<Typeahead
										size='sm'
										id='typehead'
										required
										onChange={([input]) => {
											if (input) setEditItem({ ...editItem, path: input })
										}}
										onInputChange={input => setEditItem({ ...editItem, path: input })}
										options={cartaObj?.grupos?.map(grupo => grupo.path) || []}
										defaultSelected={[editItem?.path || 'main']}
									>
									</Typeahead>
								</Form.Group>
							</Col>
						</Form.Row>

						<hr hidden={editItem?.tipo !== 'menu'} className='mb-1' />
						<div className='mb-2'>
							<span hidden={editItem?.tipo !== 'menu'} className='cursor-pointer text-primary' onClick={() => {
								setEditItem({ ...editItem, menuOptions: [...editItem.menuOptions, { name: '', path: '' }] })
							}}>
								<FontAwesomeIcon icon={faPlus} /> Agregar
							</span>
						</div>

						{
							editItem?.menuOptions?.map(option => (
								<Form.Row className='mb-1'>
									<Col>
										<Form.Control placeholder='Nombre' size='sm' required value={option.name} onChange={e => {}} />
									</Col>
									<Col>
										<Typeahead
											placeholder='Grupo'
											size='sm'
											id='typehead'
											required
											onChange={([input]) => {
												if (input) {}
											}}
											onInputChange={input => {}}
											options={cartaObj?.grupos?.map(grupo => grupo.path) || []}
											defaultSelected={[option.path || 'main']}
										>
										</Typeahead>
									</Col>
									<Col xs={1}>
										<Button size='sm' variant='danger'>
											<FontAwesomeIcon icon={faTimes} />
										</Button>
									</Col>
								</Form.Row>
							))
						}
						<hr hidden={editItem?.tipo !== 'menu'} />

						<Form.Row>
							<Col>
								<Form.Group>
									<Form.Label>Insumos</Form.Label>
									<Typeahead
										size='sm'
										id='typehead'
										required
										onChange={([input]) => {
											if (input) setEditItem({ ...editItem, path: input })
										}}
										onInputChange={input => setEditItem({ ...editItem, path: input })}
										labelKey={insumo => `${insumo.insumoId}-${insumo.name}-${insumo.und}`}
										options={insumos || []}
									>
									</Typeahead>
								</Form.Group>

								{JSON.stringify(editItem?.insumos)}
							</Col>
						</Form.Row>
						<Form.Row>
							<Col>
								<Form.Group>
									<Form.Label>Color</Form.Label>
									<Form.Control size='sm' type='color' required value={editItem?.color || ''} onChange={e => setEditItem({ ...editItem, color: e.target.value })} />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label>Precio</Form.Label>
									<Form.Control size='sm' type='number' step='0.01' required value={editItem?.precio ?? ''} onChange={e => setEditItem({ ...editItem, precio: e.target.value })} />
								</Form.Group>
							</Col>
						</Form.Row>
						<Form.Group>
							<Form.Check type="checkbox" label="Hablilitar" checked={editItem?.activo ?? true} onChange={e => setEditItem({ ...editItem, activo: e.target.checked })} />
						</Form.Group>
						<Button type="submit" className='float-right'>
							Guardar Cambios
						</Button>
					</Form>
				</Modal.Body>
			</Modal>

			{/* RIGHT ACTION BUTTTONS */}
			<div className='ml-auto mb-1 mbt-1 position-absolute' style={{ right: '4px', top: '56px' }}>
				<Button size='sm' disabled={!items} variant='outline-light' onClick={() => {
					const selectedNodes = gridApi.getSelectedNodes()
					const selectedData = selectedNodes.map(node => node.data)
					if (!selectedData[0]) return
					const item = selectedData[0]
					setEditItem(item)
				}}>
					<FontAwesomeIcon icon={faEdit} className='mr-1' />Editar
				</Button>
				<Button size='sm' disabled={!items} variant='outline-light' onClick={() => setEditItem({ path: 'main', cocina: '', precio: 0, activo: true, name: '', tipo: 'simple' })}>
					<FontAwesomeIcon icon={faPlus} />
				</Button>
			</div>

			{/* TABLE */}
			<div className="ag-theme-alpine flex-grow-1">
				<AgGridReact
					rowSelection="single"
					columnDefs={columnDefs}
					rowData={items}
					onGridReady={params => {
						setGridApi(params.api)
						setGridColumnApi(params.columnApi)
					}}
					onFirstDataRendered={() => {
						autoSizeAll()
					}}
				/>
			</div>
		</div>
	)
}