import React, { useState } from 'react'
import { v1 as uuidv1 } from 'uuid'
import useSWR from 'swr'
import { flattenDeep } from 'lodash'
import Axios from 'axios'
import { sortBy } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFolder, faUtensils } from '@fortawesome/free-solid-svg-icons'

import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Loader from './Loader'
import PlatoUnicoModal from './PlatoUnicoModal'

import { useDialog } from '../Hook/DialogContext'

export default function Carta({ onAdd, disabled, cartaId, unico }) {
    const [selection, setSelection] = useState({})
    const [searchInput, setSearchInput] = useState('')
    const [searchOutput, setSearchOutput] = useState()

    const { showNotification } = useDialog()

    //FETCHER FOR SWR
    const fetcher = async (url, cartaId) => {
        if (sessionStorage[cartaId]) return JSON.parse(sessionStorage[cartaId])
        const res = await Axios.get(url)
        sessionStorage[cartaId] = JSON.stringify(res.data)
        return res.data
    }

    const { data: carta } = useSWR([`/api/carta?cartaId=${cartaId}`, cartaId], fetcher, {
        onSuccess: carta => {
            setSelection(carta)
        },
        revalidateOnFocus: false
    })

    //CUSTOM SEARCH FUNCTION
    const searchString = (folder, string) => new Promise(async (resolve) => {
        const found = folder.items.map(async element => {
            if (element.type === 'folder') {
                const cleanFolder = await searchString(element)
                return cleanFolder
            } else {
                return element
            }
        })

        const resolved = await Promise.all(found)

        resolve(flattenDeep(resolved).filter(element => element.name.toLowerCase().search(string) !== -1))
    })

    function onInput(tecla) {
        onAdd({
            ...tecla,
            cant: 1,
            time: Date.now(),
            desc: 0
        })

        showNotification({
            message: `${tecla.name} agregado.`
        })
    }

    return (
        <Card className="w-100 h-100 d-flex border-0 flex-column">
            <Loader loading={disabled} displayLoader={false}/>

            {/* SEARCH BAR */}
            <div className="d-flex m-1">
                <FormControl
                    value={searchInput}
                    onChange={async e => {
                        const string = e.target.value.replace(/^ /, '')
                        setSearchInput(string)
                        if (!string) {
                            setSearchOutput(null)
                            return
                        }
                        const found = await searchString(carta, string.toLowerCase())
                        setSearchOutput(found)
                    }}
                />

                <PlatoUnicoModal className='ml-1' onAdd={onInput} hidden={!unico ?? true} />
            </div>

            {/* carta */}
            <Row className="d-flex flex-wrap align-content-start overflow-y-auto w-100" noGutters xs={2} sm={3} md={4} lg={2} xl={4} style={{ height: '100%', maxHeight: '100%' }}>
                {
                    selection.type === 'folder' &&
                    <Col className="p-1" style={{ height: '120px' }} >
                        <Button
                            block
                            variant='outline-dark'
                            className="h-100 py-auto hover-darken-35 font-weight-semibold"
                            onClick={() => {
                                let currentObj = carta
                                const pathSections = selection.path.split('/')

                                pathSections.forEach(element => {
                                    if (element !== 'main' && element) {
                                        currentObj = currentObj.items.find(item => item.name === element)
                                    }
                                })

                                setSelection(currentObj)
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                    </Col>
                }
                {
                    ( searchOutput || sortBy(selection.items, cartaItem => [cartaItem.type, cartaItem.name]) )?.map(button => (
                        <Col key={uuidv1()} className="p-1" style={{ height: '120px' }}>
                            <Button
                                block
                                className="h-100 py-auto border-0 font-weight-semibold "
                                style={{ backgroundColor: button.color }}
                                onClick={() => {
                                    if (button.type === 'item') onInput(button)
                                    if (button.type === 'folder') setSelection(button)
                                }}
                            >
                                {
                                    button.type === 'folder' && <FontAwesomeIcon icon={faFolder} className='mr-2' />
                                }
                                {
                                    button.tipo === 'menu' && <FontAwesomeIcon icon={faUtensils} className='mr-2' />
                                }
                                {button.name}
                            </Button>
                        </Col>
                    ))
                }
                {
                    searchOutput?.length === 0 && <h1>Not Found</h1>
                }
            </Row>
        </Card>
    )
}
