import React, { Fragment, useState } from 'react'
import Axios from 'axios'
import fpFix from '../Helpers/fpFix'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReceipt, faDownload, faDollarSign, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

import Modal from "react-bootstrap/Modal"
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Badge from 'react-bootstrap/Badge'
import Row from 'react-bootstrap/Row'
import Loader from '../Loader'
import DataForm from './DataForm'

import { useDialog } from '../../Hook/DialogContext'
import { usePrinter } from '../../Hook/PrinterContext'
import { useConfig } from '../../Hook/ConfigContext'

export default function CobrarButton({
    mesa, orden, items, descuento, metaData,
    apiKey, apiUrl,
    hidden, block, disabled,
    updateOrden, newPosOrder, pos, autoPrint
}) {
    // const apiUrl = 'https://demo.facturapp.pe/'
    // const apiKey = '9JogeLVp70uOpGiP8YK3VbtbrsrlSw2K9bcI8mdvLhd6Tvusea'

    const { config } = useConfig()

    const { getDialogResponse } = useDialog()
    const { print } = usePrinter()

    const [loading, setLoading] = useState()
    const [show, setShow] = useState()

    const [data, setData] = useState()
    const [links, setLinks] = useState()

    const [formaDePago, setFormaDePago] = useState('efectivo')
    const [combinado, setCombinado] = useState()
    const [propina, setPropina] = useState()
    const [payment, setPayment] = useState()

    const total = items.reduce((acc, item) => acc + item?.cant * item?.precio * ((100 - (item?.desc || 0)) / 100).toFixed(2), 0)
    const totalDescontado = total * (1 - parseFloat(descuento || 0))
    const valor = totalDescontado / (config?.igvPlus100Decimal ?? 1.18)
    const igv = totalDescontado - valor

    const time = new Date()
    const fecha = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`
    const hora = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`

    const generateItems = items => {
        return items.map(i => {
            const valor = i.precio * ((100 - (i.desc || 0)) / 100).toFixed(2) / (config?.igvPlus100Decimal ?? 1.18)
            return {
                "codigo_interno": `P${i.itemId || i.id}`,
                "descripcion": i.name,
                "codigo_producto_sunat": "",
                "unidad_de_medida": "NIU",
                "cantidad": i.cant,
                "valor_unitario": parseFloat(valor.toFixed(2)),
                "codigo_tipo_precio": "01",
                "precio_unitario": parseFloat((valor * (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)),
                "codigo_tipo_afectacion_igv": "10",
                "total_base_igv": parseFloat((valor * i.cant).toFixed(2)),
                "porcentaje_igv": parseFloat((config?.igv ?? 18).toFixed(2)),
                "total_igv": parseFloat((valor * i.cant * (config?.igvDecimal ?? 0.18)).toFixed(2)),
                "total_impuestos": parseFloat((valor * i.cant * (config?.igvDecimal ?? 0.18)).toFixed(2)),
                "total_valor_item": parseFloat((valor * i.cant).toFixed(2)),
                "total_item": parseFloat((valor * (config?.igvPlus100Decimal ?? 1.18) * i.cant).toFixed(2))
            }
        })
    }

    const emitirExpress = async () => {
        console.log('emitiendo express')
        setLoading(true)

        const body = {
            "acciones": {
                "enviar_email": false,
                "formato_pdf": "ticket"
            },
            "serie_documento": localStorage.boletaSerie || "B001",
            "numero_documento": "#",
            "fecha_de_emision": fecha,
            "hora_de_emision": hora,
            "codigo_tipo_operacion": "0101",
            "codigo_tipo_documento": "03",
            "codigo_tipo_moneda": "PEN",
            "fecha_de_vencimiento": fecha,
            "numero_orden_de_compra": "",

            "datos_del_cliente_o_receptor": {
                "apellidos_y_nombres_o_razon_social": 'Varios',
                "codigo_pais": "PE",
                "codigo_tipo_documento_identidad": "1",
                "correo_electronico": '',
                "direccion": '',
                "numero_documento": '0',
                "ubigeo": '',
                "telefono": ''
            },

            "totales": {
                "total_descuentos": parseFloat(((total / (config?.igvPlus100Decimal || 1.18)) * parseFloat(descuento || 0)).toFixed(2)),
                "total_exportacion": 0.00,
                "total_operaciones_gravadas": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_operaciones_inafectas": 0.00,
                "total_operaciones_exoneradas": 0.00,
                "total_operaciones_gratuitas": 0.00,
                "total_igv": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_impuestos": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_valor": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_venta": parseFloat((total * (1 - parseFloat(descuento || 0))).toFixed(2))
            },

            "items": [],

            "informacion_adicional": `Forma de pago:${formaDePago}|Caja: 1`
        }

        if (parseFloat(descuento)) {
            body.descuentos = [
                {
                    "codigo": "02",
                    "descripcion": "Descuento Global afecta a la base imponible",
                    "factor": parseFloat(descuento),
                    "monto": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * parseFloat(descuento)).toFixed(2)),
                    "base": parseFloat((total / (config?.igvPlus100Decimal || 1.18)).toFixed(2))
                }
            ]
        }

        body.items = generateItems(items)

        try {
            const res = await Axios.post(apiUrl + 'api/documents', body, { headers: { Authorization: `Bearer ${apiKey}` } })
            if (!res.data.success) {
                throw new Error(res.data.message)
            }
            update({
                metaData: {
                    ...metaData,
                    tipoCobro: 'total',
                    comprobantes: [res.data.data.number],
                    formaDePago: formaDePago,
                    propina: Number(propina),
                    combinado: combinado,
                    pagaCon: payment
                }
            }, res.data.data, res.data.links)
            setLoading(false)
        } catch (error) {
            setShow(false)
            setLoading(false)
            getDialogResponse({
                title: 'Error',
                message: error.message,
                buttons: [{
                    variant: 'primary',
                    label: 'Aceptar'
                }]
            })
        }
    }

    const emitirBoleta = async (nombre, dni, ce) => {
        setLoading(true)

        const body = {
            "acciones": {
                "enviar_email": false,
                "formato_pdf": "ticket"
            },
            "serie_documento": localStorage.boletaSerie || "B001",
            "numero_documento": "#",
            "fecha_de_emision": fecha,
            "hora_de_emision": hora,
            "codigo_tipo_operacion": "0101",
            "codigo_tipo_documento": "03",
            "codigo_tipo_moneda": "PEN",
            "fecha_de_vencimiento": fecha,
            "numero_orden_de_compra": "",
            "datos_del_cliente_o_receptor": {
                "apellidos_y_nombres_o_razon_social": nombre,
                "codigo_pais": "PE",
                "codigo_tipo_documento_identidad": ce ? "4" : "1",
                "correo_electronico": '',
                "direccion": '',
                "numero_documento": ce ? ce : dni,
                "ubigeo": ''
            },
            "totales": {
                "total_descuentos": parseFloat(((total / (config?.igvPlus100Decimal || 1.18)) * parseFloat(descuento || 0)).toFixed(2)),
                "total_exportacion": 0.00,
                "total_operaciones_gravadas": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_operaciones_inafectas": 0.00,
                "total_operaciones_exoneradas": 0.00,
                "total_operaciones_gratuitas": 0.00,
                "total_igv": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_impuestos": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_valor": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_venta": parseFloat((total * (1 - parseFloat(descuento || 0))).toFixed(2))
            },
            "items": []
        }

        if (parseFloat(descuento)) {
            body.descuentos = [
                {
                    "codigo": "02",
                    "descripcion": "Descuento Global afecta a la base imponible",
                    "factor": parseFloat(descuento),
                    "monto": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * parseFloat(descuento)).toFixed(2)),
                    "base": parseFloat((total / (config?.igvPlus100Decimal || 1.18)).toFixed(2))
                }
            ]
        }

        body.items = generateItems(items)

        try {
            const res = await Axios.post(apiUrl + 'api/documents', body, { headers: { Authorization: `Bearer ${apiKey}` } })
            if (!res.data.success) {
                throw new Error(res.data.message)
            }
            update({
                metaData: {
                    ...metaData,
                    tipoCobro: 'total',
                    comprobantes: [res.data.data.number],
                    formaDePago: formaDePago,
                    propina: Number(propina),
                    combinado: combinado,
                    pagaCon: payment
                }
            }, res.data.data, res.data.links)
            setLoading(false)
        } catch (error) {
            setShow(false)
            setLoading(false)
            getDialogResponse({
                title: 'Error',
                message: error.message,
                buttons: [{
                    variant: 'primary',
                    label: 'Aceptar'
                }]
            })
        }
    }

    const emitirFactura = async (ruc, rs, dir, ubigeo) => {
        setLoading(true)

        const body = {
            "acciones": {
                "enviar_email": false,
                "formato_pdf": "ticket"
            },
            "serie_documento": localStorage.facturaSerie || "F001",
            "numero_documento": "#",
            "fecha_de_emision": fecha,
            "hora_de_emision": hora,
            "codigo_tipo_operacion": "0101",
            "codigo_tipo_documento": "01",
            "codigo_tipo_moneda": "PEN",
            "fecha_de_vencimiento": fecha,
            "numero_orden_de_compra": "",
            "datos_del_cliente_o_receptor": {
                "apellidos_y_nombres_o_razon_social": rs,
                "codigo_pais": "PE",
                "codigo_tipo_documento_identidad": "6",
                "correo_electronico": false,
                "direccion": dir,
                "numero_documento": ruc,
                "ubigeo": ubigeo
            },
            "totales": {
                "total_descuentos": parseFloat(((total / (config?.igvPlus100Decimal || 1.18)) * parseFloat(descuento || 0)).toFixed(2)),
                "total_exportacion": 0.00,
                "total_operaciones_gravadas": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_operaciones_inafectas": 0.00,
                "total_operaciones_exoneradas": 0.00,
                "total_operaciones_gratuitas": 0.00,
                "total_igv": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_impuestos": parseFloat((fpFix((total * (1 - parseFloat(descuento || 0))) - ((total * (1 - parseFloat(descuento || 0))) / (config?.igvPlus100Decimal || 1.18)))).toFixed(2)),
                "total_valor": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * (1 - parseFloat(descuento || 0))).toFixed(2)),
                "total_venta": parseFloat((total * (1 - parseFloat(descuento || 0))).toFixed(2))
            },
            "items": []
        }

        if (parseFloat(descuento)) {
            body.descuentos = [
                {
                    "codigo": "02",
                    "descripcion": "Descuento Global afecta a la base imponible",
                    "factor": parseFloat(descuento),
                    "monto": parseFloat((total / (config?.igvPlus100Decimal || 1.18) * parseFloat(descuento)).toFixed(2)),
                    "base": parseFloat((total / (config?.igvPlus100Decimal || 1.18)).toFixed(2))
                }
            ]
        }

        body.items = generateItems(items)

        try {
            const res = await Axios.post(apiUrl + 'api/documents', body, { headers: { Authorization: `Bearer ${apiKey}` } })
            if (!res.data.success) {
                throw new Error(res.data.message)
            }
            update({
                metaData: {
                    ...metaData,
                    tipoCobro: 'total',
                    comprobantes: [res.data.data.number],
                    formaDePago: formaDePago,
                    propina: Number(propina),
                    combinado: combinado,
                    pagaCon: payment
                }
            }, res.data.data, res.data.links)
            setLoading(false)
        } catch (error) {
            setShow(false)
            setLoading(false)
            getDialogResponse({
                title: 'Error',
                message: error.message,
                buttons: [{
                    variant: 'primary',
                    label: 'Aceptar'
                }]
            })
        }
    }

    const emitirInterno = async () => {
        setLoading(true)

        try {
            update({
                metaData: {
                    ...metaData,
                    tipoCobro: 'total',
                    formaDePago: formaDePago,
                    propina: Number(propina),
                    combinado: combinado,
                    comprobantes: ['interno'],
                    pagaCon: payment
                }
            }, { type: 'interno' })
        } catch (error) {
            setShow(false)
            setLoading(false)
            getDialogResponse({
                title: 'Error',
                message: error.message,
                buttons: [{
                    variant: 'primary',
                    label: 'Aceptar'
                }]
            })
        }
    }

    const update = (obj, data, links) => {
        updateOrden(obj)
        setData(data)
        setLinks(links)
        setLoading(false)
        if (autoPrint) print('voucher', { ...data, formaDePago: formaDePago || obj.metaData.formaDePago || '' })
    }

    return (
        <Fragment>
            <Button
                disabled={disabled}
                block={block}
                hidden={hidden}
                variant="success"
                size='lg'
                onClick={e => {
                    setLoading(true)
                    setShow(true)

                    if (!metaData?.comprobantes) {
                        setLoading(false)
                    } else {
                        if (metaData.comprobantes[0] === 'interno') {
                            setData({ type: 'interno' })
                            setLoading(false)
                            return
                        }
                        Axios.post(apiUrl + 'api/documents/status', { serie_number: metaData?.comprobantes[0] }).then(res => {
                            setData(res.data.data)
                            setLinks(res.data.links)
                            setLoading(false)
                        })
                    }
                }}
            >
                <FontAwesomeIcon icon={faDollarSign} className='mr-1' />Cobrar
            </Button>

            <Modal size='lg' show={show} centered onHide={() => setShow(false)}>
                <Loader loading={loading} />

                <Modal.Body className='px-0'>
                    <Row>
                        <Col hidden={data} xs={12} lg={6} className='mb-3 mb-lg-0'>
                            <h5 className='mbt-2'>
                                <Badge variant='dark'>{`S/. ${totalDescontado.toFixed(2)}`}{descuento && <span className='text-warning'>*</span>}</Badge>
                                {descuento && <Badge variant='warning'>{descuento * 100}%</Badge>}
                            </h5>

                            <Form.Group className='mb-2'>
                                <Form.Label>Forma de Pago</Form.Label>
                                <Form.Control size='sm' as='select' onChange={e => {
                                    if (e.target.value === 'combinado') {
                                        setCombinado([{
                                            amount: 0
                                        }])
                                    } else {
                                        setCombinado(undefined)
                                    }
                                    setFormaDePago(e.target.value)
                                }}>
                                    <option value='efectivo'>Efectivo</option>
                                    <option value='tarjeta'>Tarjeta</option>
                                    <option value='transferencia'>Transferencia</option>
                                    <option value='yape'>Yape</option>
                                    <option value='plin'>Plin</option>
                                    <option value='rappi'>Rappi</option>
                                    <option value='combinado'>Combinado</option>
                                </Form.Control>
                            </Form.Group>

                            {
                                combinado &&
                                <React.Fragment>
                                    <Form.Group>
                                        <Form.Label>Pago Combinado</Form.Label>
                                        {
                                            combinado?.map((parte, index) => (
                                                <div className='d-flex mb-2' key={index}>
                                                    <Form.Control size='sm' as='select' required value={parte?.metodo} onChange={e => {
                                                        const newArray = [...combinado]
                                                        newArray[index].metodo = e.target.value
                                                        setCombinado(newArray)
                                                    }}>
                                                        <option value={''} hidden>Selccionar...</option>
                                                        <option value='efectivo'>Efectivo</option>
                                                        <option value='tarjeta'>Tarjeta</option>
                                                        <option value='transferencia'>Transferencia</option>
                                                        <option value='yape'>Yape</option>
                                                        <option value='plin'>Plin</option>
                                                        <option value='rappi'>Rappi</option>
                                                    </Form.Control>

                                                    <Form.Control size='sm' className='mx-2' type='number' step='0.01' value={parte.amount} onChange={e => {
                                                        const newArray = [...combinado]
                                                        newArray[index].amount = e.target.value
                                                        setCombinado(newArray)
                                                    }} />

                                                    <Button size='sm' variant='danger' onClick={() => {
                                                        const newArray = [...combinado]
                                                        newArray.splice(index, 1)
                                                        setCombinado(newArray)
                                                    }}><FontAwesomeIcon icon={faTrash} /></Button>
                                                </div>
                                            ))
                                        }
                                    </Form.Group>
                                    <div className='mb-2 d-flex justify-content-between'>
                                        <Button size='sm' variant='outline-primary' onClick={() => setCombinado([...combinado, { amount: 0 }])}>Agregar</Button>
                                        <span className='my-auto'>Restante: S/. {(totalDescontado - combinado.reduce((acc, element) => acc += Number(element.amount), 0)).toFixed(2)}</span>
                                    </div>
                                </React.Fragment>
                            }

                            {
                                formaDePago === 'efectivo' ?
                                    <Form.Row className='mb-2'>
                                        <Col>
                                            <Form.Group className='mb-0'>
                                                <Form.Label>Paga con</Form.Label>
                                                <Form.Control size='sm' type='number' step='0.01' value={payment} onChange={e => setPayment(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className='mb-0'>
                                                <Form.Label>Vuelto</Form.Label>
                                                <Form.Control size='sm' readOnly value={payment ? (totalDescontado - payment) : 0} />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    : null
                            }

                            <Form.Group>
                                <Form.Label>Propina</Form.Label>
                                <Form.Control size='sm' type='number' step='0.01' onChange={e => setPropina(e.target.value)} />
                            </Form.Group>

                            <div className='d-flex justify-content-between mbt-2'>
                                <Button className='mt-0' size='lg' variant="success" block onClick={() => {
                                    setPayment('50')
                                }}>50</Button>
                                <Button className='mt-0' size='lg' variant="success" block onClick={() => {
                                    setPayment('100')
                                }}>100</Button>
                                <Button className='mt-0' size='lg' variant="warning" block onClick={() => emitirExpress()}>Express</Button>
                            </div>
                        </Col>

                        <Col hidden={data} xs={12} lg={6}>
                            <DataForm
                                onBoleta={(data) => emitirBoleta(data.nombre, data.dni, data.ce)}
                                onFactura={(data) => emitirFactura(data.ruc, data.rs, data.dir, data.ubigeo)}
                                onInterno={() => emitirInterno()}
                            />
                        </Col>

                        {/* PAYMENT DONE */}
                        <Col hidden={!data}>
                            <h4>Comprobante: {data?.number || data?.type} <Badge variant="secondary">{data?.state_type_description}</Badge></h4>

                            {
                                metaData?.pagaCon && <Card className='mb-3'>
                                    <Card.Body>
                                        Vuelto: {metaData?.pagaCon - total}
                                    </Card.Body>
                                </Card>
                            }

                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Enviar por WhatsApp"
                                    id='waPhone'
                                    disabled={data?.type === 'interno'}
                                />
                                <InputGroup.Append>
                                    <Button disabled={data?.type === 'interno'} variant="outline-success" onClick={() => {
                                        let val = document.getElementById('waPhone').value
                                        const url = encodeURI(`https://wa.me/${val}?text=Su comprobante de pago electrónico ${data?.number} ha sido generado correctamente, puede revisarlo en el siguiente enlace: ${links?.pdf}`)
                                        window.open(url)
                                    }}><FontAwesomeIcon icon={faWhatsapp} size='lg' /></Button>
                                </InputGroup.Append>
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <FormControl
                                    disabled
                                    placeholder="Enviar por Correo"
                                    id='client-email'
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-primary" disabled onClick={() => null}><FontAwesomeIcon icon={faEnvelope} size='lg' /></Button>
                                </InputGroup.Append>
                            </InputGroup>

                            <div className='d-flex mbt-2 justify-content-center'>
                                <Button variant="info" onClick={() => {
                                    if (data.type === 'interno') {
                                        console.log(data)
                                        print('interno', { orden, items, descuento, mesero: metaData.mesero || '', mesa })
                                        return
                                    }

                                    print('voucher', { ...data, formaDePago: formaDePago || metaData.formaDePago })
                                }}>
                                    <FontAwesomeIcon icon={faReceipt} size='lg' className='mr-2' />
                                    Ticket
                                </Button>
                                <Button variant="dark" href={links?.pdf}><FontAwesomeIcon icon={faDownload} size='lg' className='mr-2' />Descargar</Button>
                                <Button hidden={!pos} onClick={() => {
                                    newPosOrder()
                                    setShow(false)
                                }}>
                                    Nueva Orden
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
