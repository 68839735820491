import React from 'react'
import { Switch, Route, Redirect, Link, useRouteMatch } from 'react-router-dom'

import Button from 'react-bootstrap/Button'

import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    const { userConfig } = useConfig()
    
    return (
        <>
        <div className='d-flex mbt-1 mb-1'>
            {
                userConfig?.webView?.map((view, index) => (
                    <Button key={index} as={Link} to={'/webview/' + view.path} variant={useRouteMatch('/webview/' + view.path)?.isExact ? 'warning' : 'outline-warning'}>{view.name}</Button>
                ))
            }
        </div>

        <Switch>
            {
                userConfig?.webView?.map((view, index) => (
                    <Route key={index} exact path={'/webview/' + view.path}
                        render={() => <iframe title={view.name} src={view.url} className="flex-grow-1"></iframe>}
                    />
                ))
            }
            <Redirect to='/webview'/>
        </Switch>
        </>
    )
}
