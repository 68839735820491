import React from 'react'

import Nav from '../components/Nav'

import Container from 'react-bootstrap/Container'

export default ({children }) => (
    <Container fluid className="p-1 h-100 d-flex flex-column" style={{background:'linear-gradient(180deg, #0094FF 0%, #00308E 100%)'}}>
        <Nav/>
        {children}
    </Container>
)