import React, { useState } from 'react'
import Axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import { flatten } from 'lodash'
import firebase from 'firebase/app'

import { Row, Button, Form, Card, Col } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { useInfoCards } from '../../Hook/InfoCardsContext'

export default () => {
    const [local, setLocal] = useState()

    if ( !local ) {
        const locales = JSON.parse(sessionStorage.locales)

        return (
            <Card className='flex-grow-1'>
                <Row xl={6} lg={5} md={4} sm={3} xs={2}>
                    {
                        locales.map(local => (
                            <Col key={local.id} className="my-1 px-1">
                                <Button
                                    className="text-truncate"
                                    block
                                    style={{ height: '110px' }}
                                    onClick={e => setLocal(local)}
                                >   
                                    { local.nombre }
                                </Button>
                            </Col>
                        ))
                    }
                </Row>
            </Card>
        )
    }

    return (
        <Content local={local} setLocal={setLocal}/>
    )
}


const Content = ({ local, setLocal }) => {
    const [gridApi, setGridApi] = useState()
    const [gridColumnApi, setGridColumnApi] = useState()
    const [rowData, setRowData] = useState([])

    const [ data, setData ] = useState([])

    const { showSummary } = useInfoCards()

    const fetchData = async e => {
        e.preventDefault()

        gridApi.showLoadingOverlay()

        const form = e.target

        //Date Strings
        const startString = `${form.elements.start.value} 05:00:00`
        const end = new Date(new Date(form.elements.end.value).valueOf() + 1000 * 60 * 60 * 24 + 1000 * 60 * 60 * 5)
        const endString = `${end.getFullYear()}-${end.getMonth()+1 < 10 ? '0' : ''}${end.getMonth()+1}-${end.getDate() < 10 ? '0' : ''}${end.getDate()} 05:00:00`

        try {
            //ITEMS ARRAY CORRESPONDS TO EACH LINE OF THE LIST (MAY CONTAIN MORE THAN 1 ITEM PER LINE)

            const salonRequest = Axios.get(`/api/reportes/salon/?start=${startString}&end=${endString}&local_id=${local.id}&cliente_id=${local.cliente_id}`)
            const deliveryRequest = Axios.get(`/api/reportes/delivery/?start=${startString}&end=${endString}&local_id=${local.id}&cliente_id=${local.cliente_id}`)

            const [ salonResponse, deliveryResponse ] = await Promise.all([salonRequest, deliveryRequest])

            const salonData = salonResponse.data.filter(e => ( (e.status === 'terminado' || e.status === 'anulado') && e.items.length ))

            const deliveryData = deliveryResponse.data.filter(e => ( (e.status === 'terminado' || e.status === 'anulado') && e.items.length ))

            const combinedData = [...salonData, ...deliveryData]

            console.log('Combined data:', combinedData)

            const parsedData = combinedData.map(orden => {
                return orden.items.map(item => ({
                    ...item,
                    origen: orden.mesa ? 'salon' : 'delivery',
                    ordenStatus: orden.status
                }))
            })

            const data = flatten(parsedData).filter(e => !e.noCont)

            setData(data)

            console.log('Procesed items array:', data)

            const snapshot = await firebase
                .firestore()
                .collection(`cartas/${local.carta}/insumos`)
                .get()

            let cartaResult = []

            snapshot.forEach((doc) => {
                cartaResult.push({...doc.data()})
            })

            console.log(cartaResult, 'cartaResult')

            //setRowData(cartaResult.map(cartaItem => {
            //   const cantTotal = data.reduce( (acc, e) => acc + ( e.id === cartaItem.itemId ? e.cant : 0 ), 0  )
            //   const cantTerminadas = data.reduce( (acc, e) => acc + ( ( e.id === cartaItem.itemId && e.ordenStatus === 'terminado' ) ? e.cant : 0 ), 0  )
            //   const cantAnuladas = data.reduce( (acc, e) => acc + ( ( e.id === cartaItem.itemId && e.ordenStatus === 'anulado' ) ? e.cant : 0 ), 0  )
            //   return {
            //       ...cartaItem,
            //       cantTotal: cantTotal,
            //       cantTerminadas: cantTerminadas,
            //       cantAnuladas: cantAnuladas
            //   }
            //}))

        } catch (error) {
            alert(error.message)
        }
    }

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    };

    let columnDefs = [
        {headerName: "Id", field: "itemId", checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,},
        {headerName: "Nombre", field: "name"},
        {headerName: "Grupo", field: "path"},
        {headerName: "Precio Estandar", field: "precio", valueFormatter: params => (`S/. ${params.value.toFixed(2)}`) },
        {headerName: "Cantidad Comandada Total", field: 'cantTotal'},
        {headerName: "Cantidad Ordenes Terminadas", field: 'cantTerminadas'},
        {headerName: "Cantidad Cantidad Ordenes Anuladas", field: 'cantAnuladas'},
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => setLocal(null)}><FontAwesomeIcon icon={faArrowLeft}/></Button>
                    <div className='btn btn-light btn-sm'>{local.nombre}</div>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1'/>Exportar
                    </Button>
                </div>
                
                <div className='mb-1 d-flex flex-row mw-100'>
                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='start' required/>
                    </Form.Group>

                    <Form.Group className='m-0 mr-1' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='end' required/>
                    </Form.Group>

                    <Button block size='sm' variant='outline-dark' type='submit'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1'/>Reporte
                    </Button>
                </div>

            </Form>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    onCellDoubleClicked={ params => {
                        showSummary([...data.filter(e => e.id === params.data.itemId)])
                    }}
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={rowData}
                    onGridReady={ params => {
                        setGridApi(params.api)
                        setGridColumnApi(params.columnApi)
                    }}
                    onFirstDataRendered={() => {autoSizeAll()}}
                />
            </div>
        </div>
    )

}