import React from 'react'

import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { useWebOrders } from '../Hook/WebOrdersContext'

export default ({ hidden }) => {
    const { show } = useWebOrders()

    return (
        <>
        <Button hidden={hidden} size="lg" variant='warning' onClick={() => show()} style={{ position: 'relative' }}>
            <FontAwesomeIcon icon={faShoppingCart}/>
            <small><Badge variant='danger' style={{ position: 'absolute', right: '0', top: '0' }}></Badge></small>
            <small><Badge variant='primary' style={{ position: 'absolute', left: '0', top: '0' }}></Badge></small>
        </Button>
        </>
    )
}


