import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import _ from 'lodash'
import { AgGridReact } from 'ag-grid-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPhone, faUserPlus, faDatabase, faSpinner } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

export default function NewOrderButton({ className }) {
    let history = useHistory()

    const [gridColumnApi, setGridColumnApi] = useState()

    const [show, setShow] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [clientes, setClientes] = useState([])
    const [numero, setNumero] = useState()
    const [loading, setLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})

    async function nuevoDelivery(clienteId, local, cliente) {
        try {
            const res = await Axios.post(`/api/orden/delivery/create?local=${ local }&clienteId=${ clienteId }`, {
                status: 'enviar',
                origen: 'callcenter',
                delivery: 5.90,
                items: [],
                cliente: cliente
            })
            history.push(`/callcenter/${clienteId}/${local}/${res.data}`)
        } catch (error) {
            alert(error.message)
        }
    }

    async function buscarClientes() {
        setLoading(true)
        const localesArray = JSON.parse(sessionStorage.locales)

        const responses = localesArray.map(async local => {
            const res = await Axios.get(`/api/cliente/${ local.id }?telefono=${ numero }&cliente=${ local.cliente_id }`)

            return res.data.map(data => ({ ...data, local: local.id, clienteId: local.cliente_id }))
        })

        const resolved = await Promise.all(responses)

        setClientes(_.flatten(resolved))

        setLoading(false)
    }

    async function onSubmit(e) {
        e.preventDefault()
        const form = e.target

        //Form Validation
        let obj = {}
        if (!/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(form.elements.nombre.value)) {
            obj.nombre = 'No debe contener números ni caracteres especiales.'
        }
        if (!/^\d+$/.test(form.elements.telefono.value) || (form.elements.telefono.value.length !== 7 && form.elements.telefono.value.length !== 9)) {
            obj.telefono = 'Debe contener solamente 7 o 9 numeros.'
        }
        if (Object.keys(obj).length) {
            setFormErrors(obj)
            form.elements[Object.keys(obj)[0]].focus()
            setLoading(false)
            return
        }

        try {
            const [localClienteId, local] = _.split(form.elements.local.value, '/')

            let res = await Axios.post(`api/cliente/${ local }/new?clienteId=${ localClienteId }`, {
                nombre: form.elements.nombre.value,
                telefono: form.elements.telefono.value,
                direccion: form.elements.direccion.value
            })

            if (res.data.error) {
                throw new Error(res.data.error)
            }

            let id = res.data

            nuevoDelivery(localClienteId, local, id)
        } catch (error) {
            alert(error.message)
            setLoading(false)
        }
    }

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    const columnDefs = [
        { headerName: "#", field: "id", sortable: true, filter: true, resizable: true },
        { headerName: "Nombre", field: "nombre", sortable: true, filter: true, resizable: true },
        { headerName: "Telefono", field: "telefono", sortable: true, filter: true, resizable: true },
        { headerName: "Cliente ID", field: "clienteId", sortable: true, filter: true, resizable: true },
        { headerName: "Local", sortable: true, filter: true, resizable: true, valueGetter: params => JSON.parse(sessionStorage.locales).filter(local => local.id === params.data.local)[0].nombre },
        { headerName: "Direccion", field: "direccion", sortable: true, filter: true, resizable: true }
    ]

    function tabs() {
        if (showForm) return (
            <>
                <Modal.Header className='text-center' closeButton>
                    <Modal.Title>Nuevo Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => onSubmit(e)}>
                        <Form.Row>
                            <Col xs={12} lg={4}>
                                <Form.Group>
                                    <Form.Label>Local<span className="text-white font-weight-bold h4">*</span></Form.Label>
                                    <Form.Control as="select" name="local" required autoComplete="off">
                                        <option value={null}>Seleccionar...</option>
                                        {
                                            JSON.parse(sessionStorage.locales).map((local, index) => (
                                                <option key={index} value={`${local.cliente_id}/${local.id}`}>{local.nombre}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Form.Group as={Col}>
                                <Form.Label>Nombre<span className="text-danger font-weight-bold h4">*</span></Form.Label>
                                <Form.Control name="nombre" required autoComplete="off" isInvalid={formErrors.nombre} />
                                <Form.Control.Feedback type='invalid'>{formErrors.nombre}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Telefono<span className="text-danger font-weight-bold h4">*</span></Form.Label>
                                <Form.Control name="telefono" required autoComplete="off" value={numero} isInvalid={formErrors.telefono} onChange={e => { setNumero(e.target.value) }} />
                                <Form.Control.Feedback type='invalid'>{formErrors.telefono}</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Dirección<span className="text-danger font-weight-bold h4">*</span></Form.Label>
                                <Form.Control name="direccion" required autoComplete="off" />
                            </Form.Group>
                        </Form.Row>
                        <Button
                            variant="primary"
                            type="submit"
                            className="float-right mt-3"
                            disabled={loading}
                        >
                            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <><FontAwesomeIcon icon={faUserPlus} clasName='mr-2' />Crear Cliente y Pedido</>}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShowForm(false)}
                            className="float-right mt-3 mr-2"
                            disabled={loading}
                        >
                            Cancelar
                        </Button>
                    </Form>
                </Modal.Body>
            </>
        )

        return (
            <Modal.Body className='p-1'>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text>
                    </InputGroup.Prepend>

                    {/* NUMERO DE TELEFONO */}
                    <FormControl className="mr-1" onChange={e => setNumero(e.target.value)} />
                    {/* BOTTON BUSCAR */}
                    <Button className="mr-1" onClick={e => buscarClientes()} disabled={loading || !numero}>{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <><FontAwesomeIcon icon={faDatabase} className="mr-1" />Buscar</>}</Button>
                    {/* NUEVO CLIENTE */}
                    <Button onClick={e => setShowForm(true)}><FontAwesomeIcon icon={faUserPlus} /></Button>
                </InputGroup>
                <div className="ag-theme-alpine" style={{ height: '500px', width: '100%' }}>
                    <AgGridReact
                        onCellDoubleClicked={params => nuevoDelivery(params.data.clienteId, params.data.local, params.data.id)}
                        rowSelection="single"
                        columnDefs={columnDefs}
                        rowData={clientes}
                        onGridReady={params => {
                            setGridColumnApi(params.columnApi)
                        }}
                        onFirstDataRendered={() => {
                            autoSizeAll()
                        }}
                    />
                </div>
            </Modal.Body>
        )
    }

    return (
        <>
            <Button className={className} size="sm" variant="dark" onClick={e => setShow(true)}><FontAwesomeIcon icon={faPlus} />Nueva Orden</Button>

            <Modal
                show={show}
                centered
                size="xl"
                onHide={e => {
                    setShow(false)
                    setShowForm(false)
                    setNumero(null)
                }}>
                {tabs()}
            </Modal>
        </>
    )
}