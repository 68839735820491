import React, { Fragment, useEffect, useState } from 'react'
import Axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

//BOOTSTRAP COMPONENTS
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Modal from 'react-bootstrap/Modal'
//END BOOTSTRAP COMPONENTS

import { Table, Form } from 'react-bootstrap'

export default function TransferModal({ id, items, updateOrden, hidden }) {
    const [show, setShow] = useState(false)
    const [itemsArray, setItemsArray] = useState([])
    const [mesas, setMesas] = useState([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        items && setItemsArray(items.map(item => ({ ...item, cant: 0 })))

        const { data: mesas } = await Axios.get(`/api/orden/salon?local=${sessionStorage.local_id}`)

        setMesas(mesas)
    }, [items, show])

    const onSubmit = e => {
        e.preventDefault()
        Axios.post(`/api/orden/salon/addItems?id=${e.target.id.value}`, itemsArray.filter(item => item.cant))

        const newItemsArray = items.map((item, index) => ({
            ...item,
            cant: item.cant - itemsArray[index].cant
        })).filter(item => item.cant)

        updateOrden({items: newItemsArray})

        setShow(false)
    }

    return (
        <Fragment>
            <Modal show={show} centered onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transferir Items</Modal.Title>
                </Modal.Header>

                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <Table className='border rounded'>
                            {itemsArray?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faMinusCircle} onClick={() => {
                                            if (itemsArray[index].cant > 0) {
                                                const newItemsArray = [...itemsArray]
                                                newItemsArray[index].cant--
                                                setItemsArray(newItemsArray)
                                            }
                                        }} />
                                        <span className='mx-2'>{item.cant} / {items[index].cant}</span>
                                        <FontAwesomeIcon icon={faPlusCircle} onClick={() => {
                                            if (itemsArray[index].cant < items[index].cant) {
                                                const newItemsArray = [...itemsArray]
                                                newItemsArray[index].cant++
                                                setItemsArray(newItemsArray)
                                            }
                                        }} />
                                    </td>
                                    <td className="text-right">{item?.precio?.toFixed(2)}</td>
                                    <td className={`text-right${item?.desc ? ' text-warning' : ''}`}>{(item?.cant * item?.precio * ((100 - (item?.desc || 0)) / 100).toFixed(2)).toFixed(2)}</td>
                                </tr>
                            ))}
                        </Table>

                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Transferir a:</Form.Label>
                            <Form.Control as='select' name='id'>
                                {
                                    mesas.filter(mesa => mesa.status && mesa.id !== id).map(mesa => <option value={mesa.orden}>{mesa.id}</option>)
                                }
                            </Form.Control>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type='submit'>
                            Transferir
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Dropdown.Item hidden={true} onClick={() =>  setShow(true)}><FontAwesomeIcon icon={faEdit} className='mr-1' />Transferir Items</Dropdown.Item>
        </Fragment>
    )
}