import React, { createContext, useState } from 'react'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useCollection, update } from '@nandorojo/swr-firestore'
import { flattenDeep } from 'lodash'
import moment from 'moment'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faClock, faPhone, faUser, faMapMarkerAlt, faEdit } from '@fortawesome/free-solid-svg-icons'

import { useDialog } from './DialogContext'
import { useConfig } from './ConfigContext'

const WebOrdersContext = createContext()

export default ({ children }) => {
	let history = useHistory()
	const { getDialogResponse } = useDialog()
	const { userConfig } = useConfig()

	const [showModal, setShowModal] = useState()

	const containersArray = typeof userConfig?.orderContainer === 'string' ? [userConfig?.orderContainer] : userConfig?.orderContainer

	const fetchCarta = async cartaId => {
		if (sessionStorage[cartaId]) return JSON.parse(sessionStorage[cartaId])
		const res = await Axios.get(`/api/carta?cartaId=${cartaId}`)
		sessionStorage[cartaId] = JSON.stringify(res.data)
		return res.data
	}

	const createArray = folder => new Promise(async (resolve) => {
		const found = folder.items.map(async element => {
			if (element.type === 'folder') {
				const cleanFolder = await createArray(element)
				return cleanFolder
			} else {
				return element
			}
		})

		const resolved = await Promise.all(found)

		resolve(flattenDeep(resolved))
	})

	const _update = (orderContainer, documentId, data) => {
		update(`webOrders/${orderContainer}/orders/${documentId}`, data)
	}

	const reject = async (orderContainer, documentId) => {
		const response = await getDialogResponse({
			centered: true,
			title: 'Confirmacion',
			message: `Deseas eliminar la orden ${documentId}?`,
			buttons: [
				{
					label: 'Si, Eliminar',
					variant: 'danger'
				},
				{
					label: 'Cancelar'
				}
			]
		})
		response === 0 &&
			_update(orderContainer, documentId, {
				status: 'rechazado'
			})
	}

	const validate = async documentObj => {
		console.log(documentObj)
		try {
			if (!documentObj.cartaId) throw new Error('Orden no contiene CartaId')
			const carta = fetchCarta(documentObj.cartaId)

			//Get clientes from local's db (telefono)
			const clientes = await Axios.get(`/api/cliente/${documentObj.local}?cliente=${documentObj.clienteId}&telefono=${documentObj.telefono}`)

			//Determines the client's id | If no matching client create one
			const searchedCliente = clientes.data.find(cliente => cliente.placeId === documentObj.placeId)
			let clienteId = searchedCliente?.id
			if (!searchedCliente) {
				//create new cliente record if not found with same placeId and number
				const response = await Axios.post(`/api/cliente/${documentObj.local}/new?clienteId=${documentObj.clienteId}`, {
					nombre: documentObj.nombre,
					telefono: documentObj.telefono,
					direccion: documentObj.direccion,
					geo: documentObj.geo,
					placeId: documentObj.placeId
				})
				clienteId = response.data
			}
			//clienteId is set

			//create order in local's db
			const { data: orderId } = await Axios.post(`/api/orden/delivery/create?clienteId=${documentObj.clienteId}&local=${documentObj.local}`, {
				origen: 'web',
				delivery: documentObj.delivery,
				items: [],
				memo: documentObj.meta?.memo,
				cliente: clienteId,
				metaData: { formaDePago: documentObj.formaDePago }
			})

			const resolvedCarta = await carta
			const cartaArray = await createArray(resolvedCarta)

			//Builds items array
			let items = []
			documentObj.pedido.forEach(item => {
				console.log(item)
				const cartaItem = cartaArray.find(ci => (ci.itemId === item.link))
				if (!cartaItem) throw new Error('no se econtro el item.')
				let array = [{
					id: cartaItem.itemId,
					name: cartaItem.name,
					precio: cartaItem.precio,
					cant: item.cant || 1,
					time: Date.now(),
					cocina: cartaItem.cocina,
					orderId: orderId
				}]

				Object.keys(item.opciones).forEach(opcion => {
					//ADDER FUNCTION FOR EVERY OPTION
					const addItem = e => {
						const cartaItem = cartaArray.find(ci => (ci.itemId === e.link))
						if (!cartaItem) throw new Error('no se econtro el item')
						const itemToAdd = {
							id: cartaItem.itemId,
							name: cartaItem.name,
							precio: cartaItem.precio,
							cant: item.cant || 1,
							time: Date.now(),
							cocina: cartaItem.cocina,
							orderId: orderId
						}

						switch (e.tipo) {
							case 'remplaza':
								array[0] = itemToAdd
								break
							case 'agrega':
								array.push(itemToAdd)
								break
							default: break
						}
					}

					//RUN ADDER FUNTION ON EVERY OPTION
					if (Array.isArray(item.opciones[opcion])) {
						item.opciones[opcion].forEach(e => addItem(e))
					} else (
						addItem(item.opciones[opcion])
					)
				})

				items.push(...array)
			})

			//UPDATE ORDER WITH ITEMS AND COBRADO
			await Axios.post(`/api/orden/delivery/update?cliente=${documentObj.clienteId}&local=${documentObj.local}&id=${orderId}`, {
				items,
				cobrado: '[timestamp]'
			})

			//mark order as accepted
			_update(documentObj.orderContainer, documentObj.id, {
				status: 'aceptado',
				orderId: orderId
			})

			Axios.post('https://mighty-beyond-62636.herokuapp.com/order_confirmation_email', {
				order: {
					code: documentObj.id,
					container: documentObj.orderContainer
				}
			}, {
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			})

			//return orderId
			history.push(`/callcenter/${documentObj.clienteId}/${documentObj.local}/${orderId}`)

		} catch (error) {
			alert(error.message)
		}
	}

	const validateComandappOnline = async (documentObj, orderContainer) => {
		try {
			const carta = fetchCarta(documentObj.cartaId)

			const resolvedCarta = await carta
			const cartaArray = await createArray(resolvedCarta)

			//Builds items array
			let items = []
			documentObj.cart.items.forEach(item => {
				const foundItem = cartaArray.find(ci => (ci.itemId === item.metadata.comandappItemId))
				if (!foundItem) throw new Error('no se econtro el item.')
				let array = [{
					id: foundItem.itemId,
					name: foundItem.name,
					precio: foundItem.precio,
					cant: 1,
					time: Date.now(),
					cocina: foundItem.cocina
				}]

				item.selectedOptions && item.selectedOptions.forEach(option => {
					const cartaItem = cartaArray.find(ci => (ci.itemId === option.metadata.comandappItemId))
					if (!cartaItem) throw new Error('no se econtro el item')
					array.push({
						id: cartaItem.itemId,
						name: cartaItem.name,
						precio: option.price,
						cant: option.amount || 1,
						time: Date.now(),
						cocina: cartaItem.cocina
					})
				})

				items.push(...array)
			})

			//create order in local's db
			const { data: orderId } = await Axios.post(`/api/orden/delivery/create?clienteId=${documentObj.comandappClientId}&local=${documentObj.localId}`, {
				origen: 'web',
				delivery: documentObj.delivery,
				items,
				memo: documentObj.meta?.memo,
				metaData: {
					formaDePago: documentObj.paymentMethod,
					comandappOnlineUserEmail: documentObj.email,
					comandappOnlineUserName: documentObj.name,
					comandappOnlineUserAddress: documentObj.address.formatted_address,
					comandappOnlineUserPhone: documentObj.phone,
					EntregappOrderId: documentObj.EntregappOrderId
				}
			})

			//mark order as accepted
			_update(orderContainer, documentObj.id, {
				status: 'terminado',
				orderId: orderId
			})

			//return orderId
			history.push(`/callcenter/${documentObj.comandappClientId}/${documentObj.localId}/${orderId}`)

		} catch (error) {
			alert(error.message)
		}
	}

	const terminar = async (orderContainer, documentId) => {
		const response = await getDialogResponse({
			centered: true,
			title: 'Confirmacion',
			message: `Deseas terminar la orden ${documentId}?`,
			buttons: [
				{
					label: 'Si, Terminar',
					variant: 'warning'
				},
				{
					label: 'Cancelar'
				}
			]
		})
		response === 0 &&
			_update(orderContainer, documentId, {
				status: 'terminado'
			})
	}

	const V3Card = ({ orden, onAceptar, onRechazar, onTerminar, reject, validate, terminar, ...rest }) => (
		<Card {...rest}>
			<Card.Header className='px-3 font-weight-bold d-flex justify-content-between'>
				<span className='my-auto'># {orden.id} ({orden.status})</span>
				<div>
					<Button size='sm' className='mr-2' onClick={async () => {
						const response = await getDialogResponse({
							message: "orderContainer,cartaId,clienteId",
							textArea: true,
							textAreaCancelButton: true
						})

						if (!response) return

						const array = response.split(',')

						_update(array[0], orden.id, {
							orderContainer: array[0],
							cartaId: array[1],
							clienteId: array[2]
						})
					}}>
						<FontAwesomeIcon icon={faEdit} className='mr-1' />Editar
					</Button>
					{
						orden.status === 'aceptado' ?
							<Button size='sm' onClick={() => terminar(orden.orderContainer, orden.id)}>
								Terminar
							</Button>
							:
							<>
								<Button size='sm' variant='danger' className='mr-2' onClick={() => reject(orden.orderContainer, orden.id)}>
									<FontAwesomeIcon icon={faTimes} className='mr-1' />Rechazar
								</Button>
								<Button size='sm' variant='success' onClick={() => validate(orden)}>
									<FontAwesomeIcon icon={faCheck} className='mr-1' />Aceptar
								</Button>
							</>
					}
				</div>
			</Card.Header>

			<Card.Body className='px-2 py-3'>
				<Row>
					<Col md='6' className='px-2 d-flex flex-column'>
						<div className='h5 d-flex justify-content-between'>
							<Badge variant='dark'><FontAwesomeIcon icon={faClock} className='mr-2' />{new Date(orden.created).toLocaleString()}</Badge>
							<Badge variant={orden.isrecojo ? 'warning' : 'success'}>{orden.isrecojo ? 'Recojo en' : 'Delivery para'} {sessionStorage.locales ? JSON.parse(sessionStorage.locales).filter(l => l.id === orden.local)[0]?.nombre : 'Local'}</Badge>
							<Badge variant='dark'>S/.{orden.total}</Badge>
						</div>
						<div className='h5 d-flex'>
							<Badge variant='dark' className='mr-2'>{orden.orderContainer}</Badge>
							<Badge variant='dark'>{orden.clienteId}</Badge>
						</div>
						{
							orden.meta?.memo &&
							<div className='h5 d-flex'>
								<Badge variant='danger'>{orden.meta?.memo}</Badge>
							</div>
						}
						<div className='d-flex justify-content-between'>
							<span><FontAwesomeIcon icon={faUser} className='mr-2' />{orden.nombre}</span>
							<a href={`https://wa.me/51${orden.telefono}`} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faPhone} className='mr-2' />{orden.telefono}</a>
						</div>
						{
							orden.direccion ? <a href={`https://www.google.com/maps/search/?api=1&query=${orden?.geo?.lat},${orden?.geo?.lng}`} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />{orden.direccion}</a> : null
						}
						<h5>
							<Badge variant='dark' style={{
								backgroundColor:
									orden.formaDePago === 'Yape' ? '#6032a8' :
										orden.formaDePago === 'Tarjeta' ? '#148031' : null
							}}>{orden.formaDePago}</Badge>
						</h5>
					</Col>

					{orden?.pedido?.map((item, index) => (
						<Col md='6' key={index} className='px-2'>
							<Card>
								<Card.Header className='font-weight-bold p-2 border-bottom-0'>{item.nombre} - <span className='text-success'>S/.{item.precio?.toFixed(2)}</span></Card.Header>
								{Object.keys(item.opciones).length ? <Card.Body className='p-2'>
									{
										Object.keys(item.opciones).map((opcion, index) => (
											<div key={index}>
												<span className='font-weight-bold'>{opcion}: </span>{Array.isArray(item.opciones[opcion]) ?
													item.opciones[opcion].map(e => (
														e.nombre
													)).join(' - ')
													: item.opciones[opcion].nombre}
											</div>
										))
									}
								</Card.Body> : null}
							</Card>
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	)

	const ComandappOnlineCard = ({ orden, orderContainer, onAceptar, onRechazar, onTerminar, reject, validate, terminar, ...rest }) => (
		<Card {...rest}>
			<Card.Header className='px-3 font-weight-bold d-flex justify-content-between'>
				<span className='my-auto'># {orden.id}</span>
				<div>
					<>
						<Button size='sm' variant='danger' className='mr-2' onClick={() => reject(orderContainer, orden.id)}>
							<FontAwesomeIcon icon={faTimes} className='mr-1' />Rechazar
						</Button>
						<Button size='sm' variant='success' onClick={() => validateComandappOnline(orden, orderContainer)}>
							<FontAwesomeIcon icon={faCheck} className='mr-1' />Validar
						</Button>
					</>
				</div>
			</Card.Header>

			<Card.Body className='px-2 py-3'>
				<Row>
					<Col md='6' className='px-2 d-flex flex-column'>
						<div className='mb-2'>
							<Card bg='dark' className='p-2 w-auto text-light d-flex'>
								<div className='d-flex'>
									<img src={require('../assets/img/entregappLogo.svg')} alt="" width={100} />
									<span className='ml-2 small'>{orden.origin}</span>
								</div>
								<div className='d-flex'>
									Orden:
									<span className='ml-2 font-weight-semibold'>{orden.EntregappOrderId}</span>
									<a className='ml-2' href='/'>Ver</a>
								</div>
								<div className='d-flex align-items-center'>
									Recibido:
									<span className='mx-2 font-weight-semibold'>{new Date(orden.created).toLocaleString()}</span>
									<Badge variant='light'>{moment(orden.created).fromNow()}</Badge>
								</div>
								<div className='d-flex align-items-center'>
									Total:
									<span className='mx-2 font-weight-semibold'>S/.{orden.total}</span>
									Metodo de Pago:
									<span className='ml-2 font-weight-bold' style={{
										color:
											orden.paymentMethod === 'yape' ? '#d550fa' :
												orden.paymentMethod === 'tarjeta' ? '#148031' :
													orden.paymentMethod === 'plin' ? '#2a94cc' : null
									}}>{orden.paymentMethod}</span>
								</div>
								<h5 className='d-flex mb-0'>
									<Badge variant='success' className='mr-2'>{JSON.parse(sessionStorage.locales).find(l => l.id === orden.localId)?.nombre}</Badge>
									<Badge variant='success'>Delivery</Badge>
								</h5>
							</Card>
						</div>
						<div className='h5 d-flex'>
							<Badge variant='dark' className='mr-2'>{orden.orderContainer}</Badge>
							<Badge variant='dark'>{orden.clienteId}</Badge>
						</div>
						{
							orden.meta?.memo &&
							<div className='h5 d-flex'>
								<Badge variant='danger'>{orden.meta?.memo}</Badge>
							</div>
						}
						<div className='d-flex justify-content-between'>
							<span><FontAwesomeIcon icon={faUser} className='mr-2' />{orden.email}</span>
							<a href={`https://wa.me/51${orden.phone}`} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faPhone} className='mr-2' />{orden.phone}</a>
						</div>
						{
							orden.address?.formatted_address ? <a href={`https://www.google.com/maps/search/?api=1&query=${orden.address?.coordinates.lat},${orden.address?.coordinates.lng}`} target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faMapMarkerAlt} className='mr-2' />{orden.address?.formatted_address}</a> : null
						}
					</Col>

					{orden.cart?.items?.map((item, index) => (
						<Col md='6' key={index} className='px-2'>
							<Card className='shadow'>
								<Card.Body className='p-2'>
									<h6>{item.name} - <span className='text-success'>S/.{item.finalPrice?.toFixed(2)}</span></h6>
									{
										item.selectedOptions?.map((option, index) => <p className='m-0' key={index}>{option.optionName}: {option.amount} x {option.name}</p>)
									}
								</Card.Body>
							</Card>
						</Col>
					))}
				</Row>
			</Card.Body>
		</Card>
	)

	const OrderGroup = ({ container }) => {
		const { data } = useCollection(`webOrders/${container}/orders`, {
			where: [
				['status', 'in', ['pendiente', 'aceptado']]
			],
			listen: true,
		}, {
			onSuccess: (e) => console.log(e)
		})

		return (
			<React.Fragment>
				{
					data?.map((order, index) => order.origin ? <ComandappOnlineCard key={index} orden={order} orderContainer={container} className='mb-3' reject={reject} terminar={terminar} validate={validate} /> : <V3Card key={index} orden={order} className='mb-3' reject={reject} terminar={terminar} validate={validate} />)
				}
			</React.Fragment>
		)
	}

	const show = () => setShowModal(true)

	return (
		<WebOrdersContext.Provider value={{
			show
		}}>
			<Modal size='xl' centered show={showModal} onHide={() => { setShowModal(false) }} scrollable>
				<Modal.Header className='text-center' closeButton>
					<Modal.Title>Pedidos Web</Modal.Title>
				</Modal.Header>
				<Modal.Body className='pb-0'>
					{
						containersArray?.map(container => <OrderGroup key={container} container={container} />)
					}
				</Modal.Body>
			</Modal>
			{children}
		</WebOrdersContext.Provider>
	)
}

export function useWebOrders() {
	return React.useContext(WebOrdersContext)
}