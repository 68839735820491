import React, { createContext, useState } from 'react'

import InfoCards from '../components/Buscar/InfoCards'

const InfoCardsContext = createContext()

export default ({children}) => {
    const [ data, setData ] = useState()

    const show = obj => setData(obj)

    return (
        <InfoCardsContext.Provider value={{ show }}>
            <InfoCards data={data} onHide={() => setData(null)}/>
            {children}
        </InfoCardsContext.Provider>
    )
}

export const useInfoCards = () => React.useContext(InfoCardsContext)