import React, { useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import Axios from 'axios'
import { chunk } from 'lodash'
import useSWR from 'swr'
import { between } from '../../lib/helperFunctions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faBan, faBars, faDollarSign, faPercent, faHamburger, faPrint, faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faBluetooth } from '@fortawesome/free-brands-svg-icons'

import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'

import CustomToggle from '../../components/CustomToggle'
import ItemList from '../../components/ItemList'
import Carta from '../../components/Carta'
import CobrarButton from '../../components/CobrarModal'
import MemoButton from '../../components/MemoModal'
import DespacharButton from '../../components/DespacharButton'
import AnularButton from '../../components/AnularButton'
import PrintedBadge from '../../components/PrintedBadge'
import StatusBadge from '../../components/StatusBadge'

import { usePrinter } from '../../Hook/PrinterContext'
import { useDialog } from '../../Hook/DialogContext'
import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    let history = useHistory()
    const { cliente, local, id } = useParams()

    const [items, setItems] = useState([])
    const [showCarta, setShowCarta] = useState(false)

    const { getDialogResponse } = useDialog()
    const { print } = usePrinter()
    const { hasPermission } = useConfig()

    const locales = JSON.parse(sessionStorage.locales)
    const localDef = locales.find(localDef => localDef.id === local)

    const { data, mutate } = useSWR(`/api/orden/delivery/get?cliente=${cliente}&local=${local}&id=${id}`, {
        onErrorRetry: (err) => {
            getDialogResponse({
                title: 'Error!',
                message: err.message,
                buttons: [
                    {
                        label: 'Aceptar'
                    }
                ]
            })
        }
    })

    //Local Only
    const addItem = item => setItems([...items, {
        ...item,
        orderId: data?.id
    }])

    //Local Only
    const removeItem = index => {
        let array = [...items]
        array.splice(index, 1)
        setItems(array)
    }

    const updateOrden = async obj => {
        mutate({
            ...data,
            ...obj
        }, false)

        await Axios.post(`/api/orden/delivery/update?cliente=${cliente}&local=${local}&id=${id}`, obj)

        mutate()

        return true
    }

    const DropdownComp = () => (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='outline-light' size="lg">
                <FontAwesomeIcon icon={faBars} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <AnularButton actionToAuth='anulacion-orden' onAnular={async motivo => {
                    await updateOrden(
                        {
                            status: 'anulado',
                            status_nota: motivo
                        }
                    )
                    history.push('/salon')
                }} as={Dropdown.Item}>
                    <FontAwesomeIcon icon={faBan} className='mr-1' />Anular
                </AnularButton>

                <Dropdown.Item onClick={() => print('comandas', {
                    items: data?.items,
                    id: data?.id,
                    memo: data?.memo,
                    origen: 'delivery'
                })}>
                    <FontAwesomeIcon icon={faPrint} className='mr-1' />Imprimir Comanda
                </Dropdown.Item>

                <Dropdown.Item onClick={() => {
                    print('delivery', data)

                    updateOrden(
                        {
                            printed: '[timestamp]'
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faPrint} className='mr-1' />Imprimir Ticket de Delivery
                </Dropdown.Item>

                {/* RawBT */}
                <Dropdown.Item onClick={() => {
                    let dt = new Date(data.apertura)
                    dt.setHours( dt.getHours() + 1 );
                    let est = dt.toLocaleString()
                    let total = (data.items.reduce((acc, item) => acc + (item.precio * item.cant), 0)) + parseFloat(data.delivery);
                    
                    const esc = '\x1B'; //ESC byte in hex notation
                    const newLine = '\x0A'; //LF byte in hex notation
                    let cmds = esc + "@"; //Initializes the printer (ESC @)
                    cmds += esc + '!\x38' //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
                    cmds += esc + 'a\x01' //Align Center
                    cmds += 'PEDIDO';
                    cmds += newLine
                    cmds += `${data.id}`;
                    cmds += newLine;
                    cmds += esc + '!\x00' + esc + '\x61\x00'; //Character font A selected (ESC ! 0)
                    cmds += '================================';
                    cmds += newLine;
                    cmds += `HORA: ${new Date().toLocaleString()}`;
                    cmds += newLine;
                    cmds += `APERTURA: ${new Date(data.apertura).toLocaleString()}`;
                    cmds += newLine;
                    cmds += `EST: ${est}`;
                    cmds += newLine;
                    cmds += `CLIENTE: ${data.nombre || data?.metadata?.comandappOnlineUserName}`;
                    cmds += newLine;
                    cmds += `TELEFONO: ${data.telefono || data?.metadata?.comandappOnlineUserPhone}`;
                    cmds += newLine;
                    cmds += `DIR: ${data.direccion || data?.metadata?.comandappOnlineUserAddress}`;
                    cmds += newLine;
                    cmds += `REF: ${data.referencia || ''}`;
                    cmds += newLine;
                    cmds += '================================';
                    cmds += newLine;
                    cmds += `MEMO: ${data.memo || ''}`;
                    cmds += newLine;
                    cmds += '================================';
                    data.items.forEach(i => {
                        cmds += newLine;
                        let precio = ` S/.${(i.cant*i.precio).toFixed(2)}`
                        let cant = `${i.cant}x `
                        if ((32 - precio.length - cant.length) >= i.name.length) {
                            cmds += cant + i.name + ' '.repeat(32 - precio.length - cant.length - i.name.length) + precio;
                        } else {
                            const chunks = chunk(i.name.split(''), (32 - precio.length - cant.length))
                            cmds += cant + chunks.shift().join('') + precio;
                            while (chunks.length) {
                                cmds += newLine;
                                cmds += ' '.repeat(cant.length) + chunks.shift().join('')
                            }
                        }
                    })
                    cmds += newLine;
                    cmds += '================================';
                    cmds += newLine;
                    cmds += 'CARGO POR DELIVERY:' + ' '.repeat(32 - 'CARGO POR DELIVERY:'.length - `S/.${data.delivery}`.length) + `S/.${data.delivery}`;
                    cmds += newLine;
                    cmds += 'TOTAL A COBRAR:' + ' '.repeat(32 - 'TOTAL A COBRAR:'.length - `S/.${total.toFixed(2)}`.length) + `S/.${total.toFixed(2)}`;
                    cmds += newLine;
                    cmds += 'FORMA DE PAGO:' + ' '.repeat(32 - 'FORMA DE PAGO:'.length - (data.forma_de_pago || '').length) + (data.forma_de_pago || '');
                    
                    var S = "#Intent;scheme=rawbt;"
                    var P =  "package=ru.a402d.rawbtprinter;end;"
                    var textEncoded = encodeURI(cmds)
                    window.location.href="intent:"+textEncoded+S+P
                    
                    updateOrden(
                        {
                            printed: '[timestamp]'
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faBluetooth} className='mr-1'/>Imprimir con <b>RawBT</b>
                </Dropdown.Item>

                {/* Monto Delivery */}
                <Dropdown.Item onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Modificar Monto por Delivery',
                        message: 'Ingresa el nuevo monto.',
                        input: true,
                        buttons: [
                            {
                                label: 'Cancelar',
                                variant: 'secondary'
                            },
                            {
                                label: 'Confirmar',
                                resolveInput: true,
                                disableNoInput: true
                            }
                        ]
                    })

                    if (!response) return
                    if (isNaN(response)) return getDialogResponse({
                        title: 'Error!',
                        message: 'El monto ingresado no es valido.',
                        buttons: [
                            {
                                label: 'Aceptar'
                            }
                        ]
                    })

                    updateOrden(
                        {
                            delivery: parseFloat(response)
                        }
                    )
                }}><FontAwesomeIcon icon={faDollarSign} className='mr-1' />Modificar Monto de Delivery</Dropdown.Item>

                {/* Descuento Global */}
                <Dropdown.Item onClick={async () => {
                    const response = await getDialogResponse({
                        title: 'Modificar Descuento',
                        message: 'Ingresa el % de descuento ( 1-100 )',
                        input: true,
                        buttons: [
                            {
                                label: 'Cancelar',
                                variant: 'secondary'
                            },
                            {
                                label: 'Confirmar',
                                resolveInput: true,
                                disableNoInput: true
                            }
                        ]
                    })

                    if (!response) return

                    if (!between(parseInt(response), 1, 100)) {
                        return getDialogResponse({
                            title: 'Error!',
                            message: 'El monto ingresado no es valido.',
                            buttons: [
                                {
                                    label: 'Aceptar'
                                }
                            ]
                        })
                    }
                    updateOrden(
                        {
                            descuento: (response / 100).toFixed(2)
                        }
                    )
                }}>
                    <FontAwesomeIcon icon={faPercent} className='mr-1' />Aplicar Descuento
                </Dropdown.Item>

                <Dropdown.Item onClick={async () => {
                    const res = await getDialogResponse({
                        title: 'Agregar Nota',
                        textArea: true,
                        textAreaCancelButton: true,
                        textAreaDefaultValue: data?.metadata?.nota || ''
                    })
                    if (!res) return
                    updateOrden(
                        {
                            metadata: data.metadata ? { ...data.metadata, nota: res } : { nota: res }
                        }
                    )
                }}><FontAwesomeIcon icon={faEdit} className='mr-1' />Agregar Nota</Dropdown.Item>

                <Dropdown.Item onClick={() => getDialogResponse({
                    message: <pre className='mb-0'>{JSON.stringify(data.metadata, undefined, 4)}</pre>,
                    textNotCentered: true,
                    dismissible: true
                })}>
                    <FontAwesomeIcon icon={faInfoCircle} className='mr-1' />Ver Metadatos
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

    const badges = <React.Fragment>
        <StatusBadge id={id} status={data?.status} />

        {data?.cobrado && <Badge variant='dark'>
            <FontAwesomeIcon className='text-success mx-1' icon={faDollarSign} size='2x' />
        </Badge>}

        {data?.printed && <PrintedBadge time={data?.printed} />}
    </React.Fragment> 

    return (
        <>
            {/* MOBILE TOP NAV */}
            <div className="mb-1 d-flex d-lg-none justify-content-between">
                <Link to="/delivery"><Button size='lg' variant='outline-light'><FontAwesomeIcon icon={faArrowLeft} /></Button></Link>

                <Button size='lg' className='d-lg-none' variant={showCarta ? 'light' : 'outline-light'} onClick={() => setShowCarta(!showCarta)}><FontAwesomeIcon icon={faHamburger} /></Button>

                <DropdownComp />
            </div>
            <div className='d-flex d-lg-none justify-content-center mbt-1 mb-1'>
                {badges}
            </div>
            {/* END MOBILE TOPNAV */}

            <Row className="h-100 overflow-hidden">
                <Col className={`p-0 h-100 ${showCarta ? 'd-none' : 'd-flex'} d-lg-flex flex-column`}>

                    {/* TOP NAV */}
                    <div className="mb-1 d-none d-lg-flex justify-content-between">
                        <Link to="/delivery"><Button size='lg' variant='outline-light'><FontAwesomeIcon icon={faArrowLeft} /></Button></Link>

                        <div className='d-flex my-auto mbt-1 mb-1'>
                            {badges}
                        </div>

                        <DropdownComp />
                    </div>

                    {/* HEADER */}
                    <Card className='d-flex justify-content-around text-center mb-1 border-0 py-1'>
                        <span><b>Nombre: </b>{data?.nombre}</span>
                        <span><b>Apertura: </b>{new Date(data?.apertura).toLocaleString()}</span>
                        <span className="text-danger font-weight-bold h5 m-0 my-auto">{data?.memo}</span>
                    </Card>

                    <ItemList
                        items={items} // Items que no se han incluido aun
                        comandados={data?.items || []} // Items que ya se incluyeron en la orden
                        delivery={data?.delivery} // Monto de delivery
                        descuento={data?.descuento} // Monto de descuento ( 50% -> 0.5 )
                        onRemoveItem={removeItem}
                        onDeleteItem={itemIndex => {
                            let array = [...data?.items]
                            array.splice(itemIndex, 1)

                            updateOrden({ items: array })
                        }}
                        onEditItem={(item, index) => {
                            items[index] = item
                            setItems([...items])
                        }}
                        noDelete={!hasPermission('delivery.deleteItems')}
                    />

                    {/* BOTTOM BUTTONS */}
                    <div className="mt-1 mbt-1 d-flex justify-content-center flex-wrap">
                        <MemoButton
                            memo={data?.memo}
                            onUpdate={newMemo => updateOrden({ memo: newMemo })}
                        />

                        <Button onClick={async () => {
                            updateOrden({
                                items: data?.items ? [...data?.items, ...items] : [...items]
                            })
                            setItems([])
                            const response = await getDialogResponse({
                                title: 'Comandar',
                                message: 'Deseas comandar los items agregados a la mesa?',
                                buttons: [
                                    { variant: 'secondary', label: 'Cancelar' },
                                    { variant: 'primary', label: 'Aceptar' }
                                ]
                            })
                            if (response) print('comandas', {
                                items: data?.items,
                                id: data?.id,
                                memo: data?.memo,
                                origen: 'delivery'
                            })
                        }} size='lg' variant='warning' hidden={!items.length}>Pedir</Button>

                        <DespacharButton
                            update={updateOrden}
                            hidden={!data?.items?.length}
                            orden={data}
                        />

                        <CobrarButton
                            items={data?.items || []}
                            descuento={data?.descuento}
                            hidden={!data?.items?.length || data?.pagado}
                            updateOrden={(obj) => updateOrden({ ...obj, cobrado: '[timestamp]' })}
                            metadata={data?.metadata}
                            apiKey={localDef.apiKey}
                            apiUrl={localDef.apiUrl}
                        />

                        <Button className='border-0' size='lg' style={{ backgroundColor: '#8332AC' }} hidden={!data?.cobrado} onClick={async () => {
                            const response = await getDialogResponse({
                                title: 'Confirmacion',
                                message: 'Estas seguro que quieres terminar la orden? Esta accion es irreversible',
                                buttons: [
                                    { variant: 'secondary', label: 'Cancelar' },
                                    { variant: 'primary', label: 'Aceptar' }
                                ]
                            })

                            if (!response) return

                            await updateOrden(
                                {
                                    status: 'terminado'
                                }
                            )
                            history.push('/delivery')
                        }}>
                            Terminar
                        </Button>
                    </div>
                </Col>

                <Col className={`p-0 h-100 ${showCarta ? 'd-flex' : 'd-none'} d-lg-flex ml-lg-1`} xs={12} lg={6} xl={8}>
                    <Carta onAdd={addItem} disabled={data?.status !== 'activo'} cartaId={localDef.carta} unico={true} />
                </Col>
            </Row>
        </>
    )
}
