import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Button, Card, FormControl, InputGroup, Form } from "react-bootstrap";

import { useConfig } from "../../Hook/ConfigContext";
import { usePrinter } from "../../Hook/PrinterContext";

export default () => {
	const { config, userConfig, update } = useConfig();
	const { getDetails, getNetworkInfo, print } = usePrinter();

	return (
		<div className='flex-grow-1 overflow-y-auto'>
			<h5>IGV</h5>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Usar Porcentaje</Form.Label>
				<Form.Control type="number" onChange={e => update({
					igv: Number(e.target.value), //18
					igvPlus100: Number(e.target.value) + 100, //118
					igvDecimal:	Number(e.target.value) / 100, //0.18
					igvPlus100Decimal: (Number(e.target.value) + 100) / 100, //1.18
				})} value={config?.igv ?? 18}/>
			</Form.Group>
			<h5>Impresión</h5>

			<div className='mbt-1 mb-3'>
				<Button
					variant='secondary'
					onClick={() => {
						getDetails();
						getNetworkInfo();
					}}
				>
					Ver detalle del adaptador
				</Button>
				<Button variant='secondary' onClick={() => print("test")}>Imprimir prueba Raw</Button>
				<Button variant='secondary' onClick={() => print("testPDF")}>Imprimir prueba PDF</Button>
			</div>

			<div className='d-flex mb-3'>
				<span className='my-auto' style={{ width: '350px' }}>Impresora de comandas default</span>
				<FormControl as='select' defaultValue={config?.printConfig?.defaultComandaPrinter} style={{ width: '350px' }}>
					<option value="" hidden>Seleccionar...</option>
					{
						config?.printConfig?.printers?.map((printer, index) => <option key={index}>{printer.name}</option>)
					}
				</FormControl>
			</div>

			<div className='d-flex mb-3'>
				<span className='my-auto' style={{ width: '350px' }}>Impresora principal</span>
				<FormControl as='select' defaultValue={config?.printConfig?.defaultPrinter} style={{ width: '350px' }}>
					<option value="" hidden>Seleccionar...</option>
					{
						config?.printConfig?.printers?.map((printer, index) => <option key={index}>{printer.name}</option>)
					}
				</FormControl>
			</div>

			<div className='d-flex mb-3'>
				<span className='my-auto' style={{ width: '350px' }}>Servidor de impresion</span>
				<FormControl as='select' defaultValue={localStorage.printServer} style={{ width: '350px' }} onChange={e => localStorage.printServer = e.target.value}>
					<option value="ninguno">Ninguno</option>
					<option value="send">Enviar</option>
					<option value="recieve">Recivir</option>
				</FormControl>
			</div>

			<h6>Ticketeras Registradas</h6>
			<div className='d-flex justify-content-between'>
				<span className='my-auto' style={{ width: '20%' }}>Tipo</span>
				<span className='my-auto' style={{ width: '20%' }}>Ancho</span>
				<span className='my-auto' style={{ width: '20%' }}>Nombre</span>
				<span className='my-auto' style={{ width: '40%' }}>Definicion</span>
			</div>

			<div className='mb-3'>
				{
					config?.printConfig?.printers?.map((printer, index) => (
						<div className='d-flex' key={index}>
							<InputGroup size='sm' className='pr-2' style={{ width: '20%' }}>
								<FormControl as='select' defaultValue={printer.type}>
									<option value="" hidden>Seleccionar...</option>
									<option value='system'>Sistema</option>
									<option value='network'>Red</option>
								</FormControl>
							</InputGroup>

							<InputGroup size='sm' className='pr-2' style={{ width: '20%' }}>
								<FormControl as='select' defaultValue={printer.width}>
									<option value="" hidden>Seleccionar...</option>
									<option value={48}>80 mm - 48 caracteres</option>
									<option value={32}>54 mm</option>
								</FormControl>
							</InputGroup>

							<InputGroup size='sm' className='pr-2' style={{ width: '20%' }}>
								<FormControl defaultValue={printer.name} />
							</InputGroup>

							<div className='d-flex' style={{ width: '40%' }}>
								<FormControl className='mr-2' size='sm' defaultValue={printer.def} />
								<div className='h-100 d-flex flex-column justify-content-center'>
									<FontAwesomeIcon className='text-danger cursor-pointer' icon={faTimes} size='lg' />
								</div>
							</div>
						</div>
					))
				}
			</div>

			<Card>
				<pre>{JSON.stringify(userConfig, undefined, 4)}</pre>
			</Card>
		</div>
	);
};
