import React from 'react'
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faPrint } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default ({ pedido, localName, modulo }) => (
    <Col className='m-1 p-0'>
        <Row className='position-absolute g-0'>
            <Col className='p-0 d-flex'>
                <Badge>
                    {pedido.status.toUpperCase()}
                </Badge>
            </Col>

            <Col className='p-0 d-flex justify-content-center'>
                <Badge>
                    {pedido.id}
                </Badge>
            </Col>

            <Col className='p-0 d-flex justify-content-end'>
                {
                    pedido.metadata.comandappOnlineUserName ? 
                    <Badge>
                        <img src={require('../assets/img/entregappLogo.svg')} alt="" height='100%' />
                    </Badge> : null
                }
            </Col>
        </Row>

        <Badge className='position-absolute bottom-0'>
            {localName.toUpperCase()}
        </Badge>

        <div className='position-absolute right-0 bottom-0 d-flex  mbt-1'>
            {
                pedido.cobrado || pedido.metadata.formaDePago ?
                    <Badge variant='dark'>
                        <FontAwesomeIcon className='text-success mx-1' icon={faDollarSign} />
                    </Badge> : null
            }
            {
                pedido.printed ?
                    <Badge variant='dark'>
                        <FontAwesomeIcon className='text-primary' icon={faPrint} />
                    </Badge> : null
            }
        </div>

        <Button
            variant={
                pedido.status === 'enviar' ? 'danger' :
                    pedido.status === 'activo' ? 'primary' :
                        pedido.status === 'despacho' ? 'info' :
                            pedido.status === 'despachado' ? 'success' :
                                pedido.status === 'entregado' ? 'warning' :
                                    'dark'
            }
            className="d-flex flex-column justify-content-center"
            block
            style={{ height: '125px' }}
            as={Link}
            to={`/${modulo}/${pedido.cliente}/${pedido.local}/${pedido.id}`}
        >
            <div className='text-truncate'>
                <span>{pedido.nombre || pedido.metadata?.comandappOnlineUserName}</span><br />
                <span className="text-danger">{pedido.memo}</span>
            </div>
        </Button>
    </Col>
)