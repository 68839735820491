import React, { useState } from "react";
import axios from "axios";
import { asyncFilter } from "../../lib/helperFunctions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";

import Departamentos from "../../lib/departamentos";
import Provincias from "../../lib/provincias";
import Distritos from "../../lib/distritos";

export default ({ onBoleta, onFactura, onInterno }) => {
  const [departamento, setDepartamento] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");

  const [useCE, setUseCE] = useState(false);

  const [activeKey, setActiveKey] = useState("boleta");

  const [loading, setLoading] = useState(false);

  const onSubmitBoleta = (e) => {
    e.preventDefault();
    const form = e.target;

    onBoleta({
      nombre: form.elements.nombre.value,
      dni: form.elements.dni.value,
      ce: useCE ? form.elements.dni.value : undefined,
    });
  };

  const onSubmitFactura = (e) => {
    e.preventDefault();
    const form = e.target;

    onFactura({
      ruc: form.elements.ruc.value,
      rs: form.elements.rs.value,
      dir: form.elements.dir.value,
      ubigeo: distrito,
    });
  };

  return (
    <Tab.Container activeKey={activeKey}>
      <div className="mb-2">
        <Button
          size="sm"
          variant={activeKey === "boleta" ? "dark" : "outline-dark"}
          onClick={() => setActiveKey("boleta")}
          className="mr-1"
        >
          Boleta
        </Button>
        <Button
          size="sm"
          variant={activeKey === "factura" ? "dark" : "outline-dark"}
          onClick={() => setActiveKey("factura")}
          className="mr-1"
        >
          Factura
        </Button>
        <Button
          size="sm"
          variant={activeKey === "interno" ? "dark" : "outline-dark"}
          onClick={() => setActiveKey("interno")}
        >
          Interno
        </Button>
      </div>
      <Tab.Content>
        <Tab.Pane eventKey="boleta">
          <Form onSubmit={onSubmitBoleta}>
            <Form.Group className="mb-1">
              <Form.Check
                type="checkbox"
                label="Carnet de Extranjeria"
                onChange={() => setUseCE(!useCE)}
                checked={useCE}
              />
            </Form.Group>

            <label>{useCE ? "CE" : "DNI"}</label>
            <Form.Group className="mb-3">
              <InputGroup>
                <FormControl
                  size="sm"
                  id="boleta-dni"
                  name="dni"
                  autoComplete="off"
                  required
                />
                {!useCE && (
                  <InputGroup.Append>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={async () => {
                        setLoading(true);
                        try {
                          const res = await axios.get(
                            `/api/consulta/dni?numero=${document.getElementById("boleta-dni").value}`
                          );

                          document.getElementById("boleta-nombre").value = res.data
                        } catch (error) {
                          alert(error.message);
                        }
                        setLoading(false);
                      }}
                    >
                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faSearch} className="mr-1" />
                          Reniec
                        </div>
                      )}
                    </Button>
                  </InputGroup.Append>
                )}
              </InputGroup>
              <Form.Text className="text-muted">
                Ingresa a{" "}
                <a href="https://beta.comandapp.pe/service/dni" target="_blank">
                  beta.comandapp.pe/service/dni
                </a>{" "}
                si tienes algún inconveniente
              </Form.Text>
            </Form.Group>

            <label>Nombre</label>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="boleta-nombre"
                name="nombre"
                autoComplete="off"
                required
              />
            </InputGroup>
            <Button size="sm" variant="primary" block type="submit">
              Emitir Boleta
            </Button>
          </Form>
        </Tab.Pane>

        <Tab.Pane eventKey="factura">
          <Form onSubmit={onSubmitFactura} id="factura-form">
            <label>RUC</label>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-ruc"
                name="ruc"
                autoComplete="off"
                required
              />
              <InputGroup.Append>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={async () => {
                    setLoading(true);
                    try {
                      const res = await axios.get(
                        `/api/consulta/ruc?numero=${document.getElementById("fact-ruc").value}`
                      );

                      document.getElementById("fact-rs").value =
                        res.data.razonSocial;
                      document.getElementById("fact-dir").value =
                        res.data.direccion || "";
                      if (res.data.ubigeo_sunat !== 6) return;

                      const [_dist] = await asyncFilter(
                        Distritos,
                        (dist) => dist.id === res.data.ubigeo_sunat
                      );

                      setDepartamento(_dist.department_id);
                      document.getElementById("fact-ubigeo-1").value =
                        _dist.department_id;

                      setProvincia(_dist.province_id);
                      document.getElementById("fact-ubigeo-2").value =
                        _dist.province_id;

                      setDistrito(_dist.id);
                      document.getElementById("fact-ubigeo-3").value = _dist.id;
                    } catch (error) {
                      alert(error.message);
                    }
                    setLoading(false);
                  }}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faSearch} className="mr-1" />
                      Sunat
                    </div>
                  )}
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <label>Razon Social</label>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-rs"
                name="rs"
                autoComplete="off"
                required
              />
            </InputGroup>

            <label>Direccion</label>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-dir"
                name="dir"
                autoComplete="off"
              />
            </InputGroup>

            <label>Ubigeo</label>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-ubigeo-1"
                as="select"
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
                required
              >
                <option hidden value="">
                  Seleccionar...
                </option>
                {Departamentos.map((obj) => (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
              </FormControl>
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-ubigeo-2"
                as="select"
                value={provincia}
                required
                disabled={!departamento}
                onChange={(e) => setProvincia(e.target.value)}
              >
                <option hidden value="">
                  Seleccionar...
                </option>
                {Provincias.filter(
                  (prov) => prov.department_id === departamento
                ).map((obj) => (
                  <option key={obj.id} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
              </FormControl>
            </InputGroup>

            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                id="fact-ubigeo-3"
                as="select"
                value={distrito}
                required
                disabled={!provincia}
                onChange={(e) => setDistrito(e.target.value)}
              >
                <option hidden value="">
                  Seleccionar...
                </option>
                {Distritos.filter((dist) => dist.province_id === provincia).map(
                  (obj) => (
                    <option key={obj.id} value={obj.id}>
                      {obj.name}
                    </option>
                  )
                )}
              </FormControl>
            </InputGroup>

            <Button size="sm" block type="submit">
              Emitir Factura
            </Button>
          </Form>
        </Tab.Pane>

        <Tab.Pane eventKey="interno">
          <Form id="interno-form">
            <Button onClick={onInterno} size="sm" block>
              Emitir Interno
            </Button>
          </Form>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};
