import React, { useState } from 'react'
import { useCollection } from '@nandorojo/swr-firestore'
import { AgGridReact } from 'ag-grid-react'

export default () => {
    const [gridApi, setGridApi] = useState()
    const [gridColumnApi, setGridColumnApi] = useState()

    const { data } = useCollection(`config/${sessionStorage.local_id}/turnos`, {
        parseDates: ['created']
    })

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    let columnDefs = [
        {field: "id"},
        {field: "name", headerName: "Nombre"},
        {field: "created", headerName: "Creado", valueFormatter: (params) => {
            return new Date(params.value).toLocaleString()
        }},
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={data || []}
                    onGridReady={ params => {setGridApi(params.api); setGridColumnApi(params.columnApi)} }
                    onFirstDataRendered={() => {autoSizeAll()}}
                />         
            </div>
        </div>
    )

}