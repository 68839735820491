import React, { Fragment, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'

import Modal from "react-bootstrap/Modal"
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'

import { useConfig } from '../Hook/ConfigContext'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default ({ memo, onUpdate, ...rest }) => {
    const { config, update } = useConfig()
    const [ show, setShow ] = useState(false)
    const [ showForm, setShowForm ] = useState(false)

    const onSubmit = e => {
        e.preventDefault()
        setShow(false)
        onUpdate(e.target.elements.input.value)
    }

    const createShortcut = e => {
        e.preventDefault()

        const form = e.target

        const newShortcut = {
            content: form.elements.content.value,
            color: form.elements.color.value
        }

        update({
            memoShortcuts: config.memoShortcuts ? [...config.memoShortcuts, newShortcut] : [newShortcut]
        })
    }

    return (
        <Fragment>
            <Button variant="light" size="lg" {...rest} onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faClipboard} className='mr-1' />Memo
            </Button>

            <Modal show={show} centered onHide={e => { setShow(false) }}>
                <Card.Body>
                    <Form onSubmit={onSubmit} className='mb-2'>
                        <Card.Title><h5 className='mb-0'>Modificar Memo</h5></Card.Title>
                        <Form.Group className='mb-0'>
                            <Form.Control defaultValue={memo} name='input' id='memoInput' />
                        </Form.Group>
                        <Button variant="primary" className='float-right' hidden id='memoFormButton' type='submit'></Button>
                    </Form>

                    <Form onSubmit={createShortcut} className='mb-2'>
                        <div className='d-flex justify-content-between'>
                            <h6 className='align-middle my-auto'>Accesos Directos</h6>
                            <Button size='sm' onClick={() => setShowForm(!showForm)}>Editar</Button>
                        </div>
                        <div className={`mt-2 ${ showForm ? 'd-flex' : 'd-none'}`}>
                            <Form.Control className='mr-2' size='sm' name='content'/>

                            <Form.Control className='mr-2' size='sm' as='select' name='color'>
                                <option value="info">Info</option>
                                <option value="primary">Primary</option>
                                <option value="success">Success</option>
                                <option value="warning">Warning</option>
                                <option value="danger">Danger</option>
                                <option value="light">Light</option>
                                <option value="dark">Dark</option>
                            </Form.Control>

                            <Button size='sm' type='submit'>Agregar</Button>
                        </div>
                    </Form>

                    {
                        config?.memoShortcuts?.map((shortcut, i) => (
                            <Badge
                                className='mr-2 mb-1'
                                key={i}
                                style={{ lineHeight: '24px', fontSize: '18px' }}
                                onClick={() => document.getElementById('memoInput').value += ` ${shortcut.content}`} variant={shortcut.color}
                            >
                                {shortcut.content}
                                <FontAwesomeIcon hidden={!showForm} icon={faTimes} className='ml-2 cursor-pointer' onClick={() => {
                                    const newMemoShortcuts = [...config.memoShortcuts]
                                    newMemoShortcuts.splice(i, 1)
                                    update({
                                        memoShortcuts: newMemoShortcuts
                                    })
                                }}/>
                            </Badge>
                        ))
                    }
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" className='float-right' onClick={() => document.getElementById('memoFormButton').click()}>
                        Guardar Cambios
                    </Button>
                </Card.Footer>
            </Modal>
        </Fragment>
    )
}