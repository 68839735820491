import React, { useEffect } from 'react'
import Axios from 'axios'
import { useHistory } from "react-router-dom"
import naturalSortByProp from '../../lib/naturalSortByProp'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Card from 'react-bootstrap/Card'

import { useDialog } from '../../Hook/DialogContext'
import { useConfig } from '../../Hook/ConfigContext'
import { useOrders } from '../../Hook/OrdersContext'

import Nav from '../../components/Nav'
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

export default () => {
    let history = useHistory()

    const { getDialogResponse } = useDialog()
    const { config } = useConfig()
    const { salonData, mutateSalon } = useOrders()

    useEffect(mutateSalon, [])

    const onMesa = async mesa => {
        try {
            if (mesa.status) {
                history.push(`/salon/${mesa.id}`)
                return
            }
            const response = await getDialogResponse({
                title: 'Confirmacion',
                message: `Abrir mesa ${mesa.id}`,
                input: {
                    label: 'Mesero'
                },
                buttons: [
                    {
                        label: 'Cancelar',
                        variant: 'secondary'
                    },
                    {
                        label: 'Confirmar',
                        resolveInput: true,
                        variant: 'primary'
                    }
                ]
            })
            if (response) {
                let res
                if (typeof response === 'string') {
                    res = await Axios.post(`/api/orden/salon/open?id=${mesa.id}`, {
                        metaData: {
                            mesero: response
                        }
                    })
                } else {
                    res = await Axios.post(`/api/orden/salon/open?id=${mesa.id}`, {})
                }

                if (res.data.error) {
                    throw new Error(res.data.error)
                }
                history.push(`/salon/${mesa.id}`)
            }
        } catch (error) {
            alert(error.message)
        }
    }

    let buttons = salonData ? naturalSortByProp(salonData, 'id').map((mesa, index) => (
        <Col key={index} className="my-1 px-1">
            <Badge
                variant='primary'
                style={{
                    position: 'absolute',
                    top: '0'
                }}
            >
                {mesa?.status?.toUpperCase()}
            </Badge>
            <Button
                className="font-weight-bold text-truncate"
                block
                style={{ height: '110px' }}
                onClick={e => { onMesa(mesa) }} variant={
                    mesa.status === 'activo' ? 'primary' : 'dark'
                }
            >
                {mesa.metaData?.mesero && <span className="text-warning"><FontAwesomeIcon icon={faUser} className='mr-2' />{mesa.metaData?.mesero}<br /></span>}
                <span>{mesa.id}</span><br />
                <span className="text-danger">{mesa.memo}</span>
            </Button>
        </Col>
    )) : null

    return (
        <>
            <Nav />
            <Card className="flex-grow-1">
                {
                    config?.caja?.active &&
                        <Alert className='mb-1 rounded-0 border-0' variant='info'>Turno: {config?.caja?.activeShiftName || config?.caja?.active}</Alert>
                }
                <Row xs={2} md={3} lg={4} xl={6}>
                    {buttons}
                </Row>
            </Card>
        </>
    )
}