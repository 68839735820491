import React from 'react'
import Badge from 'react-bootstrap/Badge'

export default ({id, status, small, ...rest}) => (
    <Badge
        variant={
            status === 'enviar' ? 'danger' :
            status === 'activo' ? 'primary' :
            status === 'despacho' ? 'info' :
            status === 'despachado' ? 'success' :
            status === 'entregado' ? 'warning' :
            'dark'
        }
        {...rest}
    >   
        <span style={!small ? { lineHeight: '24px' } : {}}>№ {id || '-'} {status?.toUpperCase()}</span>
    </Badge>
)