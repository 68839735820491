import React, { Fragment, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

import Modal from "react-bootstrap/Modal"
import Button from 'react-bootstrap/Button'

export default function CobrarButton ({metodo: _metodo, onUpdate}) {
    const [show, setShow] = useState()

    const metodos = [
        'tarjeta',
        'efectivo',
        'transferencia',
        'cupon',
        'cortesia'
    ]

    return (
        <Fragment>
            <Button 
                variant="success"
                size='lg'
                onClick={() => {
                    setShow(true)
                }}
            >
                <FontAwesomeIcon icon={faDollarSign} className='mr-1'/>Cobrar
            </Button>

            <Modal show={show} centered onHide={() => {setShow(false)}} dialogClassName='modal-adjustToContent'>
                <Modal.Body className='mbt-1'>
                    {
                        metodos.map((metodo, i) => (
                            <Button key={i} size='lg' variant={metodo === _metodo ? 'success' : 'outline-dark'} onClick={() => {
                                onUpdate(metodo)
                                setShow(false)
                            }}>
                                {metodo.charAt(0).toUpperCase() + metodo.slice(1)}
                            </Button>
                        ))
                    }
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}