import React, { useEffect, useState } from 'react'
import Axios from 'axios'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger } from '@fortawesome/free-solid-svg-icons'

import CobrarButton from '../../components/CobrarModal'
import ItemList from '../../components/ItemList'
import Carta from '../../components/Carta'

import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    const { localDef } = useConfig()
    const [ showCarta, setShowCarta ] = useState(false)
    const [ items, setItems ] = useState([])
    const [ data, setData ] = useState({})

    useEffect(() => {
        if (!sessionStorage.lastPosOrder) return
        
        Axios.get(`/api/orden/delivery/get?cliente=${sessionStorage.cliente_id}&local=${sessionStorage.local_id}&id=${sessionStorage.lastPosOrder}`).then(res => {
            setData(res.data)
            setItems(res.data.items)
        })
    }, [])

    //Local Only
    const addItem = item => setItems([...items, item])

    //Local Only
    const removeItem = index => {
        let array = [...items]
        array.splice(index, 1)
        setItems(array)
    }

    const onEditItem = (item, index) => {
        let newItems = [...items]
        newItems[index] = item
        setItems([...newItems])
    }

    const newOrden = async (obj) => {
        const res = await Axios.post(`/api/orden/delivery/create?local=${localDef.id}`, {
            status: 'terminado',
            origen: 'pos',
            metaData: obj.metaData,
            items: items
        })
        sessionStorage.lastPosOrder = res.data
        setData({ metaData: obj.metaData })
    }

    return (
        <>
            {/* MOBILE TOP NAV */}
            <div className="mb-1 d-flex d-lg-none justify-content-center">
                <Button size='lg' variant={showCarta ? 'light' : 'outline-light'} onClick={() => setShowCarta(!showCarta)}><FontAwesomeIcon icon={faHamburger} /></Button>
            </div>

            <Row className='h-100 overflow-hidden'>
                <Col className={`p-0 h-100 ${showCarta ? 'd-none' : 'd-flex'} d-lg-flex flex-column`} >
                    <ItemList
                        items={items}
                        cartaId={localDef.carta}
                        onRemoveItem={removeItem}
                        onEditItem={onEditItem}
                        disabled={data?.metaData}
                    />

                    <div className="mt-1">
                        <CobrarButton
                            block
                            items={items}
                            descuento={0}
                            apiKey={localDef?.apiKey}
                            apiUrl={localDef?.apiUrl}
                            updateOrden={newOrden}
                            disabled={!items.length}
                            pos
                            autoPrint
                            metaData={data.metaData}
                            newPosOrder={() => {
                                sessionStorage.removeItem('lastPosOrder')
                                setData({})
                                setItems([])
                            }}
                        />
                    </div>
                </Col>
                <Col className={`p-0 h-100 ${showCarta ? 'd-flex' : 'd-none'} d-lg-flex ml-lg-1`} xs={12} lg={6} xl={8}>
                    <Carta disabled={data?.metaData} onAdd={addItem} cartaId={localDef?.carta} unico={true} />
                </Col>
            </Row>
        </>
    )
}