import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import { fromPairs } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

import { useOrders } from '../../Hook/OrdersContext' 

export default function NewOrderButton({ modulo, ...rest }) {
    let history = useHistory()

    const { pause, resume } = useOrders()

    const [ show, setShow ] = useState(false)

    async function nuevoDelivery(clienteId, local, cliente, metaData) {
        try {
            const res = await Axios.post(`/api/orden/delivery/create?clienteId=${clienteId}&local=${local}`, {
                status: modulo === 'delivery' ? 'activo' : 'enviar',
                origen: modulo === 'delivery' ? 'local' : 'callcenter',
                cliente,
                metaData
            })
            resume()
            history.push(`/${modulo}/${clienteId}/${local}/${res.data}`)
        } catch (error) {
            alert(error.message)
        }
    }

    const OrdenSimple = () => {
        const [open, setOpen] = useState(true)

        const onSubmit = e => {
            e.preventDefault()

            const [clienteId, localId] = e.target.elements.local.value.split(',')

            nuevoDelivery(clienteId, localId)
        }

        return (
            <Card className='mb-3'>
                <Card.Header className='text-center h5 cursor-pointer h4' onClick={() => setOpen(!open)}>
                    Orden Simple
                </Card.Header>
                <Card.Body hidden={!open}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Label>Local</Form.Label>
                            <Form.Control name='local' size='sm' as='select' required>
                                <option value={''}>Seleccionar...</option>
                                {
                                    JSON.parse(sessionStorage.locales).map((local, index) => (
                                        <option key={index} value={[local.cliente_id, local.id]}>{local.nombre}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <div className='text-center'>
                            <Button size='sm' type='submit'>Crear Orden Simple</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    const OrdenConMetadatos = () => {
        const [open, setOpen] = useState(false)
        const [campos, setCampos] = useState([])

        const onSubmit = e => {
            e.preventDefault()

            const [clienteId, localId] = e.target.elements.local.value.split(',')

            console.log(campos)
            console.log(fromPairs(campos))

            nuevoDelivery(clienteId, localId, undefined, fromPairs(campos))
        }

        return (
            <Card>
                <Card.Header className='text-center h5 cursor-pointer h4' onClick={() => setOpen(!open)}>
                    Orden Personalizada
                </Card.Header>
                <Card.Body hidden={!open}>
                    <Form onSubmit={onSubmit}>
                        <Form.Group>
                            <Form.Label>Local</Form.Label>
                            <Form.Control name='local' size='sm' as='select' required>
                                <option value={''}>Seleccionar...</option>
                                {
                                    JSON.parse(sessionStorage.locales).map((local, index) => (
                                        <option key={index} value={[local.cliente_id, local.id]}>{local.nombre}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Pago Combinado</Form.Label>
                            {
                                campos.map((campo, index) => (
                                    <div className='d-flex mb-2' key={index}>
                                        <Form.Control size='sm' as='select' required value={campo[0]} onChange={e => {
                                            const newArray = [...campos]
                                            newArray[index][0] = e.target.value
                                            setCampos(newArray)
                                        }}>
                                            <option value={''} hidden>Selccionar...</option>
                                            <option value='nombre'>Nombre</option>
                                            <option value='telefono'>Telefono</option>
                                            <option value='dirección'>Dirección</option>
                                            <option value='referencia'>Referencia</option>
                                        </Form.Control>

                                        <Form.Control size='sm' className='mx-2' required value={campo[1]} onChange={e => {
                                            const newArray = [...campos]
                                            newArray[index][1] = e.target.value
                                            setCampos(newArray)
                                        }}/>

                                        <Button size='sm' variant='danger' onClick={() => {
                                            const newArray = [...campos]
                                            newArray.splice(index, 1)
                                            setCampos(newArray)
                                        }}><FontAwesomeIcon icon={faTrash} /></Button>
                                    </div>
                                ))
                            }
                            <div className='mb-3 d-flex justify-content-between'>
                                <Button size='sm' variant='outline-primary' onClick={() => setCampos([...campos, [undefined, '']])}>Agregar Campo</Button>
                            </div>
                            <div className='text-center' >
                                <Button size='sm' type='submit'>Crear Orden Simple</Button>
                            </div>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            <Button {...rest} size="sm" variant="dark" onClick={e => {
                setShow(true)
                pause()
            }}><FontAwesomeIcon icon={faPlus} className='mr-2' />Nueva Orden</Button>

            <Modal show={show} centered onHide={() => {
                setShow(false)
                resume()
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva Orden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrdenSimple />
                    <OrdenConMetadatos />
                </Modal.Body>
            </Modal>
        </>
    )
}