import React, { memo, useEffect, useState } from 'react'
import { omit } from 'lodash'
import { useCollection } from '@nandorojo/swr-firestore'
import { Typeahead } from 'react-bootstrap-typeahead'
import moment from 'moment'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPen, faMinus, faPrint } from '@fortawesome/free-solid-svg-icons'

import Loader from './Loader'

import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'

import { useDialog } from '../Hook/DialogContext'
import { useConfig } from '../Hook/ConfigContext'

function ItemList({
    //DATA
    comandados, items, delivery, descuento, cartaId,
    //FUNCTIONS
    onRemoveItem, onDeleteItem, onEditItem,
    //UI
    disabled
}) {
    const { config } = useConfig()

    const total =
        (comandados.reduce((acc, item) => acc + item.cant * item.precio * ((100 - (item.desc || 0)) / 100).toFixed(2), 0) || 0) +
        (items.reduce((acc, item) => acc + item.cant * item.precio * ((100 - (item.desc || 0)) / 100).toFixed(2), 0) || 0)

    const totalDescontado = total * (1 - parseFloat(descuento || 0))

    const [editedItem, setEditedItem] = useState(null)

    const { data: carta } = useCollection(`cartas/${cartaId}/items`, { ignoreFirestoreDocumentSnapshotField: false })

    useEffect(() => {
        if (!items) return
        const itemIndex = items.findIndex(item => item.tipo === 'menu' && item.menuOptions?.length > (item.menuSelections?.length || 0))
        if (itemIndex < 0) return

        editItem(itemIndex)
    }, [items])

    const editItem = index => {
        const itemToEdit = items[index]
        itemToEdit.index = index

        setEditedItem(itemToEdit)
    }

    const onSubmitEditedItem = e => {
        e.preventDefault()

        onEditItem(omit({
            ...editedItem,
            cant: parseInt(editedItem.cant),
            precio: parseFloat(editedItem.precio),
            desc: parseInt(editedItem.desc)
        }, 'index'), editedItem.index)

        setEditedItem(null)
    }

    const ItemName = ({ item }) => (
        <div className='d-flex flex-column'>
            {item.name}
            {
                <span className='mbt-1'>
                    <Badge variant='secondary'>{moment(item.time).format('hh:mm a')}</Badge>
                    <Badge variant='secondary'><FontAwesomeIcon icon={faPrint} className='mr-1' />{JSON.stringify(item.cocina)}</Badge>
                    {
                        item.noCont && <Badge variant='info'>NO CONT.</Badge>
                    }
                    {
                        !!item.desc && <Badge variant='warning'>- {item.desc}%</Badge>
                    }
                </span>
            }
            {
                item.nota ? <span className="text-success">Nota: {item.nota}</span> : null
            }
            {
                item.menuSelections?.length ? item.menuSelections.map((selection, index) => (
                    <span key={index} className='text-info'> <FontAwesomeIcon icon={faMinus} className="mr-1" />{item.menuOptions[index]?.name}: {selection?.name} </span>
                )) : null
            }
        </div>
    )

    return (
        <Card className="overflow-y-auto flex-grow-1 border-0">

            <Loader loading={disabled} displayLoader={false} />

            {/* EDIT ITEM MODAL */}
            <Modal show={!!editedItem} onHide={() => {
                if (editedItem.tipo === 'menu' && items[editedItem.index].menuOptions?.length > (items[editedItem.index].menuSelections?.length || 0)) {
                    // DO NOTHING IF MENU SELECTIONS NOT COMPLETED
                } else {
                    setEditedItem(null)
                }
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Item <span className='text-info'>{editedItem?.nombre}</span></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={onSubmitEditedItem}>
                        <Form.Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>Nota</Form.Label>
                                    <Form.Control value={editedItem?.nota || ''} onChange={e => setEditedItem({
                                        ...editedItem,
                                        nota: e.target.value
                                    })} />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <hr hidden={editedItem?.tipo !== 'menu'} />
                        <Form.Row>
                            {editedItem?.menuOptions?.map((option, index) => (
                                <Col key={index} xs={12}>
                                    <Form.Group>
                                        <Form.Label>{option.name}</Form.Label>
                                        <Typeahead
                                            id={'menuSelectionTypehead-' + index}
                                            inputProps={{
                                                required: true
                                            }}
                                            defaultInputValue={editedItem?.menuSelections?.[index]?.name}
                                            onChange={([input]) => {
                                                if (input) {
                                                    const newMenuSelections = editedItem.menuSelections ? [...editedItem.menuSelections] : []
                                                    newMenuSelections[index] = input.__snapshot.data()
                                                    setEditedItem({
                                                        ...editedItem,
                                                        menuSelections: newMenuSelections
                                                    })
                                                }
                                            }}
                                            labelKey={item => `${item.name}`}
                                            options={carta?.filter(item => item.path === option.path && item.tipo === 'simple') || []}
                                        >
                                        </Typeahead>
                                    </Form.Group>
                                </Col>
                            ))}
                        </Form.Row>
                        <hr hidden={editedItem?.tipo !== 'menu'} />

                        <Form.Row>
                            <Col xs={6} lg={3}>
                                <Form.Group>
                                    <Form.Label>Cantidad</Form.Label>
                                    <Form.Control type='number' value={editedItem?.cant} onChange={e => setEditedItem({
                                        ...editedItem,
                                        cant: e.target.value
                                    })} />
                                </Form.Group>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Form.Group>
                                    <Form.Label>Precio</Form.Label>
                                    <Form.Control type='number' value={editedItem?.precio} onChange={e => setEditedItem({
                                        ...editedItem,
                                        precio: e.target.value
                                    })} />
                                </Form.Group>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Form.Group>
                                    <Form.Label>Descuento</Form.Label>
                                    <Form.Control type='number' value={editedItem?.desc} onChange={e => setEditedItem({
                                        ...editedItem,
                                        desc: e.target.value
                                    })} />
                                </Form.Group>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Form.Group>
                                    <Form.Label>Total de Linea</Form.Label>
                                    <Form.Control readOnly value={`S/. ${(editedItem?.cant * editedItem?.precio * ((100 - (editedItem?.desc || 0)) / 100).toFixed(2)).toFixed(2)}`} />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group>
                            <Form.Check type="checkbox" label="No Contabilizar" checked={!!editedItem?.noCont} onChange={e => {
                                setEditedItem({
                                    ...editedItem,
                                    noCont: e.target.checked
                                })
                            }} />
                        </Form.Group>

                        <div className='float-right'>
                            <Button className='mr-2' variant='danger' onClick={() => {
                                onRemoveItem(editedItem.index)
                                setEditedItem(null)
                            }}>
                                Eliminar Item
                            </Button>
                            <Button type="submit" id='editItemFormButton'>
                                Guardar Cambios
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
            </Modal>

            {/* Items */}
            <Table size='sm' striped borderless>
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th className="w-1">Cant.</th>
                        <th className="text-right w-1">Precio</th>
                        <th className="text-right w-1">Importe</th>
                    </tr>
                </thead>

                <tbody>
                    {/* COMANDADOS */}
                    {comandados?.map((item, index) => (
                        <tr key={index}>
                            <td className='d-flex'>
                                <span>
                                    <FontAwesomeIcon className="cursor-pointer text-danger mr-2" icon={faTrash} onClick={() => onDeleteItem(index)} />
                                </span>
                                <ItemName item={item} />
                            </td>
                            <td className="text-center">{item.cant}</td>
                            <td className="text-right">{item?.precio?.toFixed(2)}</td>
                            <td className={`text-right${item?.desc ? ' text-warning' : ''}`}>{(item?.cant * item?.precio * ((100 - (item?.desc || 0)) / 100).toFixed(2)).toFixed(2)}</td>
                        </tr>
                    ))}

                    {/* ITEMS */}
                    {items?.map((item, index) => (
                        <tr key={index}>
                            <td className='d-flex'>
                                <span>
                                    <FontAwesomeIcon className="cursor-pointer text-warning mr-2" icon={faTrash} onClick={() => { onRemoveItem(index) }} />
                                    <FontAwesomeIcon className="cursor-pointer text-success mr-2" icon={faPen} onClick={() => { editItem(index) }} />
                                </span>
                                <ItemName item={item} />
                            </td>
                            <td>{item.cant}</td>
                            <td className="text-right">{item.precio.toFixed(2)}</td>
                            <td className="text-right">{(item.cant * item.precio).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>

                <tbody className='table-unstriped'>
                    <tr>
                        <td colSpan="3" className="text-right"><b>Subtotal S/</b></td>
                        <td className="text-right w-1">{(total / (config?.igvPlus100Decimal ?? 1.18)).toFixed(2)}</td>
                    </tr>
                    {
                        descuento ?
                            <tr>
                                <td colSpan="3" className="text-right"><b>Descuento ({parseFloat(descuento) * 100}%) S/</b></td>
                                <td className="text-right w-1">-{(total / (config?.igvPlus100Decimal ?? 1.18) * parseFloat(descuento)).toFixed(2)}</td>
                            </tr> : null
                    }
                    <tr>
                        <td colSpan="3" className="text-right"><b>IGV ({(config?.igv ?? 18)}%) S/</b></td>
                        <td className="text-right w-1">{(totalDescontado * (config?.igv ?? 18) / (config?.igvPlus100 ?? 118)).toFixed(2)}</td>
                    </tr>
                    {
                        delivery ?
                            <tr>
                                <td colSpan="3" className="text-right"><b>Cargo por Delivery S/</b></td>
                                <td className="text-right w-1">{delivery?.toFixed(2)}</td>
                            </tr> : null
                    }
                    <tr>
                        <td colSpan="3" className="text-right"><b>Total a Cobrar S/</b></td>
                        <td className="text-right w-1">
                            <b>
                                {
                                    (totalDescontado + delivery).toFixed(2)
                                }
                            </b>
                        </td>
                    </tr>
                </tbody>
            </Table>

        </Card>
    )
}

export default memo(ItemList)

ItemList.propTypes = {
    comandados: PropTypes.array,
    items: PropTypes.array,
    delivery: PropTypes.number,
    descuento: PropTypes.number
}

ItemList.defaultProps = {
    comandados: [],
    items: [],
    delivery: 0,
    descuento: 0
}