import React, { useState } from 'react'
import { useCollection, useDocument } from '@nandorojo/swr-firestore'
import { AgGridReact } from 'ag-grid-react'

import Button from 'react-bootstrap/Button'
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import { useConfig } from '../../Hook/ConfigContext'

export default () => {
    const [ selected, setSelected ] = useState()

    const { userConfig } = useConfig()

    const ContainerButton = ({ orderContainer }) => {
        const { data } = useDocument(`webOrders/${orderContainer}`)
        return (
            <Col key={orderContainer} className="my-1 px-1">
                <Button
                    onClick={() => setSelected( orderContainer )}
                    className="d-flex justify-content-center align-items-center"
                    block
                    style = {{ height: '110px' }}
                >
                    <div className='font-weight-semibold text-truncate'>
                        <span>{ data?.name }</span><br />
                        <span>{ orderContainer }</span>
                    </div>
                </Button>
            </Col>
        )
    }
    
    if ( !selected ) {
        return (
            <Card className='flex-grow-1'>
                <Row xs={2} md={3} lg={4} xl={6}>
                    {
                        userConfig?.orderContainer?.map(orderContainerId => (
                            <ContainerButton orderContainer={orderContainerId}/>
                        ))
                    }
                </Row>
            </Card>
        )
    }

    return (
        <Content orderContainer={selected} setSelected={setSelected}/>
    )
}

const Content = ({ orderContainer, nombre, setSelected }) => {
    const [gridApi, setGridApi] = useState()
    const [gridColumnApi, setGridColumnApi] = useState()

    const { data } = useCollection(orderContainer ? `webOrders/${orderContainer}/orders` : null)

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    let columnDefs = [
        { headerName: "Id", field: "id" },
        { headerName: "Restaurante", field: "meta.restaurante" },
        { headerName: "Local", valueGetter: params => params.data.local || params.data.localId },
        { headerName: "Recibido", field: "created", filter: 'agDateColumnFilter', sort: 'desc',
            valueFormatter: function (params) {
                return new Date(params.data.created).toLocaleString()
            },
        },
        { headerName: "Status", field: "status" },
        { headerName: "Nombre", valueGetter: params => params.data.nombre || params.data.name },
        { headerName: "Telefono", valueGetter: params => params.data.telefono || params.data.phone },
        { headerName: "Direccion", valueGetter: params => params.data.direccion || params.data.address.formatted_address},
        { headerName: "Lat", field: "geo.lat" },
        { headerName: "Lng", field: "geo.lng" },
        { headerName: "Forma de Pago", field: "formaDePago", valueGetter: params => params.data.formaDePago ||  params.data.paymentMethod},
        { headerName: "Pedido", field: "pedido",
            cellRendererFramework: function(params) {
                return <span className='cursor-pointer text-primary' onClick={(e) => {alert(JSON.stringify(params.value))}}>Ver</span>
            },
        },
        { headerName: "Total", field: "total" },
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <div className='mb-1 mbt-1'>
                    <Button size='sm' variant='outline-dark' onClick={() => setSelected(null)}><FontAwesomeIcon icon={faArrowLeft}/></Button>
                    <div className='btn btn-light btn-sm'>{nombre}sdsdsd</div>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!data}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1'/>Exportar
                    </Button>
                </div>

            <div className="ag-theme-alpine flex-grow-1">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={data}
                    onGridReady={ params => {
                        setGridApi(params.api)
                        setGridColumnApi(params.columnApi)
                    }}
                    onFirstDataRendered={ () => autoSizeAll() }
                />
            </div>
        </div>
    )

}