import React, { useEffect } from 'react'

import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

import Nav from '../../components/Nav'
import NewOrderButton from '../../components/NewOrder'
import DeliveryOrderButton from '../../components/DeliveryOrderButton'

import { useOrders } from '../../Hook/OrdersContext'

export default () => {
    const { data, isValidating, mutate } = useOrders()

    useEffect(mutate, [])

    return (
        <>
        <Nav/>
        <Card className="flex-grow-1 overflow-y-auto">
            <div className='mb-2 pl-1 pt-1 mbt-1'>
                <NewOrderButton modulo='delivery'/>
            </div>
            
            <Row xl={6} lg={5} md={4} sm={3} xs={2}>
                { !isValidating && !data?.length &&
                    <h3>No hay pedidos para mostrar</h3>
                }
                {
                    data?.map(( pedido, index ) => (
                        <div key={index}>
                            <DeliveryOrderButton pedido={pedido} localName={JSON.parse(sessionStorage.locales).find(localInStorage => localInStorage.id === pedido.local)?.nombre} modulo='delivery'/>
                        </div>
                    ))
                }
            </Row>
        </Card>
        </>
    )
}