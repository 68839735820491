import React from 'react'
import Badge from 'react-bootstrap/Badge'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

export default ({time, className, ...rest}) => (
    <Badge variant='dark' className={`d-flex ${className}`} {...rest}>   
        <FontAwesomeIcon className='text-primary mr-1' icon={faPrint} size='2x'/>
        <div className='m-auto'>{new Date(time).toLocaleTimeString()}</div>
    </Badge>
)