import React, { useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import { db } from '../../lib/fuego'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faDownload, faEye, faPrint } from '@fortawesome/free-solid-svg-icons'

import { useInfoCards } from '../../Hook/InfoCardsContext'
import { usePrinter } from '../../Hook/PrinterContext'

export default () => {

    const [gridApi, setGridApi] = useState()
    const [rowData, setRowData] = useState([])
    const [gridColumnApi, setGridColumnApi] = useState()

    const { show } = useInfoCards()
    const { print } = usePrinter()

    const fetchData = async e => {
        e.preventDefault()
        const form = e.target
        
        gridApi.showLoadingOverlay()
        const startDate = moment(form.elements.start.value).toDate()
        const endDate = moment(form.elements.end.value).endOf('day').toDate()

        const collectionRef = db.collection(`printOrders/${sessionStorage.local_id}/printOrders`).where('created', '>', startDate).where('created', '<', endDate)
        const data = await collectionRef.get()
        setRowData(data.docs.map(doc => {
            return {...doc.data(), id: doc.id, created: doc.data().created.toDate()}
        }))
    }

    const autoSizeAll = skipHeader => {
        var allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function(column) {
          allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    }

    let columnDefs = [
        { headerName: "Id", field: "id"},
        { headerName: "Creacion", valueGetter: params => {
            console.log(params)
            return moment(new Date(params.data.created)).format('YYYY-MM-DD h:mm:ss a')
        }},
        { headerName: "Usuario", field: "username"},
        { headerName: "Tipo", field: "type"},
        { headerName: "Modulo", valueGetter: params => {
            if (params.data.type === 'detalleDeTurno') return 'caja' 
            return 'salon'
        }},
        { headerName: "Mesa", field: "mesa"},
        { headerName: "Reimpresion", field: "data.reprint"},
    ]

    return (
        <div className='flex-grow-1 d-flex flex-column'>
            <Form onSubmit={fetchData} className='d-flex flex-wrap'>

                <div className='mb-1 mbt-1 d-flex flex-row'>
                    <Form.Group className='mb-0' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='start' required/>
                    </Form.Group>

                    <Form.Group className='mb-0' style={{ width: '36%' }}>
                        <Form.Control size='sm' type='date' name='end' required/>
                    </Form.Group>

                    <Button block size='sm' variant='outline-dark' type='submit'>
                        <FontAwesomeIcon icon={faDownload} className='mr-1'/>Reporte
                    </Button>

                    <Button className='text-nowrap' size='sm' variant='outline-dark' onClick={() => {
                        gridApi.exportDataAsCsv({
                            suppressQuotes: true,
                            customHeader: 'SEP=|',
                            columnSeparator: '|',
                            fileName: 'comandapp-reporte.csv'
                        });
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faFileExport} flip='horizontal' className='mr-1'/>Exportar
                    </Button>
                </div>

                <div className='mb-1 mbt-1 ml-auto'>
                    <Button size='sm' variant='outline-dark' onClick={() => {
                        const selectedNodes = gridApi.getSelectedNodes()
                        const selectedData = selectedNodes.map(node => node.data)
                        if (!selectedData[0]) return
                        const item = selectedData[0]
                        
                        show({...item, objectType: 'escp'})
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faEye} flip='horizontal' className='mr-1' />Ver
                    </Button>

                    <Button size='sm' variant='outline-dark' onClick={() => {
                        const selectedNodes = gridApi.getSelectedNodes()
                        const selectedData = selectedNodes.map(node => node.data)
                        if (!selectedData[0]) return
                        const item = selectedData[0]

                        print(item.type, { ...item.data, reprint: item.id })
                    }} disabled={!rowData}>
                        <FontAwesomeIcon icon={faPrint} flip='horizontal' className='mr-1' />Reimprimir
                    </Button>
                </div>
                
            </Form>

            <div className="ag-theme-alpine" style={ {height: '100%', width: '100%'} }>
                <AgGridReact
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true
                    }}
                    rowData={rowData || []}
                    onGridReady={ params => {setGridApi(params.api); setGridColumnApi(params.columnApi)} }
                    onFirstDataRendered={() => {autoSizeAll()}}
                />         
            </div>
        </div>
    )

}