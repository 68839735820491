import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faShower } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import { useConfig } from '../Hook/ConfigContext'

export default function AnularButton({ children, onAnular, as, actionToAuth }) {
    const [show, setShow] = useState()

    const { authorize } = useConfig()

    const CustomTag = as

    const onSubmit = e => {
        e.preventDefault()
        const form = e.target
        onAnular(form.elements.motivo.value)
    }

    const run = async () => {
        const auth = await authorize(actionToAuth)
        if ( auth.error ) return
        setShow(true)
    }

    return (
        <>
            {!as ?
                <Button
                    variant='danger'
                    size='lg'
                    onClick={() => run()}
                >
                    <FontAwesomeIcon icon={faBan} />
                </Button>
                :
                <CustomTag onClick={() => run()}>{children}</CustomTag>
            }

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body className='p-1'>
                    <Modal.Title className='text-center mb-2'>Ingresa un Motivo</Modal.Title>
                    <Form onSubmit={onSubmit}>
                        <Form.Control type="text" name='motivo' className='mb-2' required />
                        <Button variant="danger" type="submit" className='float-right'>
                            Anular
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}