import React, { useState, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import Axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock, faEye } from '@fortawesome/free-solid-svg-icons'

import Loader from '../components/Loader'

import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Col from 'react-bootstrap/Col'
import Card from "react-bootstrap/Card"

const Login = () => {
    let history = useHistory()

    const [loading, setLoading] = useState()
    const [error, setError] = useState()

    useEffect(() => sessionStorage.clear(), [])

    const handleSubmit = async e => {
        e.preventDefault()
        setLoading(true)

        const form = e.target

        try {
            const res = await Axios.post('/api/auth/login', {
                user: form.elements.user.value,
                pass: form.elements.pass.value
            })
            if (res.data.error) throw new Error(res.data.error)

            Object.keys(res.data.data).forEach(prop => {
                let value = res.data.data[prop]
                if (typeof value === 'object') value = JSON.stringify(value)
                sessionStorage[prop] = value
            })
            history.push('/')
        } catch (error) {
            setLoading(false)
            setError(error.message)
            console.error(error)
        }

    }

    const LoginForm = () => {
        const [showPass, setShowPass] = useState(false)

        return (
            <Form onSubmit={handleSubmit} className="text-center" style={{ maxWidth: '400px' }}>
                {/* Comandapp Logo */}
                <img src={require('../assets/img/comandappIcon.png')} alt="logo" className='mb-4 w-50' />

                <h4 className='mb-4'>Bienvenido! <br />
                    <small>Ingresa a tu cuenta</small>
                </h4>

                {/* Error Alert */}
                {error && <div className="alert alert-danger text-center p-2">{error}</div>}

                <div className='text-left'>
                    <Form.Group>
                        <Form.Label className='small mb-1'>Usuario</Form.Label>
                        <InputGroup>
                            <Form.Control
                                name='user'
                                placeholder='Usuario'
                            />

                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faUser} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className='small mb-1'>Contraseña</Form.Label>
                        <InputGroup>
                            <Form.Control
                                name='pass'
                                placeholder='Contraseña'
                                type={showPass ? 'text' : 'password'}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faLock} />
                                </InputGroup.Text>
                            </InputGroup.Append>

                            <div className='d-flex flex-column justify-content-center h-100' style={{
                                position: 'absolute',
                                right: '50px'
                            }}>
                                <FontAwesomeIcon icon={faEye} className='form-control-feedback cursor-pointer text-secondary' onClick={() => setShowPass(!showPass)} />
                            </div>
                        </InputGroup>
                    </Form.Group>
                </div>

                <Button type="submit" block className='mb-4 pt-2 pb-2 border-0' style={{ background: '#00D747' }}>Iniciar sesión</Button>

                <span className="small">v. 2.40</span>
            </Form>
        )
    }

    if (loading) return <Loader loading transparency={false} />

    return (
        <>
            <Container fluid className='h-100 p-0'>
                <Row className='h-100'>
                    <Col xs={8} className='h-100 p-5 d-none d-lg-block' style={{ background: 'linear-gradient(180deg, #0094FF 0%, #00308E 100%)' }}>
                        <Card className='w-100 h-100' style={{ display: 'grid', placeContent: 'center' }}>
                            <img
                                src={localStorage.restaurantLogoUrl || require('../assets/img/comandappLogo.png')}
                                alt='logo'
                                style={{ maxWidth: '500px' }}
                            />
                        </Card>
                    </Col>
                    <Col className='h-100 d-flex justify-content-center align-items-center p-4'>
                        <LoginForm />
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Login
