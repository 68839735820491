import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Delivery from './Delivery'
import Pedido from './Pedido'

export default () => (
    <Switch>
        <Route exact path='/delivery'
            render={props => <Delivery {...props}/>}
        />
        <Route exact path='/delivery/:cliente/:local/:id'
            render={props => <Pedido {...props}/>}
        />
        <Redirect to='/'/>
    </Switch>
)